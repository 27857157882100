import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Slider,
  Stack,
  styled,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { classes } from '../../Components/Modals/styles';
import {
  ApplicationAction,
  ApplicationState,
  IApplication,
  IAttachment,
  IVideoInterview
} from '../types';
import { AttachmentType } from '../config';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import TimestampTable from './Components/TimestampTable';
import ImagePreview from '../OverviewTab/Attachments/ImagePreview';
import VideoPreview from '../OverviewTab/Attachments/VideoPreview';
import AudioPreview from '../OverviewTab/Attachments/AudioPreview';
import TextPreview from '../OverviewTab/Attachments/TextPreview';
import PdfPreview from '../OverviewTab/Attachments/PdfPreview';
import WordPreview from '../OverviewTab/Attachments/WordPreview';
import { getAttachmentType } from '../../utils/attachment-type';
import { VIDEO_INTERVIEW_DISPLAY_NAME, VIDEO_INTERVIEW_SOURCE_INDEX } from '../config';
import { IUserPermissions } from '../../Components/sharedTypes';
import { ZoomInSVG, ZoomOutSVG } from '../OverviewTab/Icons';
import { theme } from '../../../../components/ThemeContext/ThemeObject';
import { useGesture } from '@use-gesture/react';
import { MAX_SCALE, ZOOM_STEP } from '../constants';
import { useThrottle } from '../../hooks';

dayjs.extend(duration);

const CustomSlider = styled(Slider)(() => ({
  ...classes.zoomSlider
}));

export default function AttachmentPreviewModal({
  previewOpen,
  dispatch,
  selectedAttachment,
  ApplicationState,
  permissions
}: {
  previewOpen: boolean;
  dispatch: React.Dispatch<ApplicationAction>;
  selectedAttachment: IAttachment | IVideoInterview;
  ApplicationState: ApplicationState;
  permissions: IUserPermissions | undefined;
}) {
  const BASE_URL = window.location.origin;
  const videoRef = React.useRef<HTMLVideoElement>(null);
  const [isLoadingPreview, setIsLoadingPreview] = useState<boolean>(true);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const isVideoInterview = 'watch_interview_link' in selectedAttachment;
  const attachmentType = getAttachmentType(selectedAttachment as IAttachment);
  const isVideoType = attachmentType === AttachmentType.Video || isVideoInterview;
  const [zoom, setZoom] = useState<number>(0);
  const zoomFactor = 1 + zoom / MAX_SCALE;

  const modalTitle = isVideoInterview
    ? VIDEO_INTERVIEW_DISPLAY_NAME
    : selectedAttachment.attached_file_name;

  const handleClose = () => {
    dispatch({
      type: 'SET_SELECTED_ATTACHMENT_ACTION',
      payload: { delete: null, preview: null }
    });
    dispatch({ type: 'RESET_TIMESTAMP_STATE' });
    setIsLoadingPreview(true);
  };

  const throttledUpdateZoom = useThrottle((delta: number) => {
    setZoom((prevZoom) => {
      const zoomChange = Math.max(2, Math.round(2 * Math.abs(delta * 0.05)));
      const newZoom = delta > 0 ? prevZoom + zoomChange : prevZoom - zoomChange;

      return Math.min(MAX_SCALE, Math.max(0, newZoom));
    });
  }, 500);

  const onPinchHandler = useGesture({
    onPinch: ({ direction: [pinchDirection] }) => {
      const zoomDirection = pinchDirection < 0 ? -1 : 1;

      throttledUpdateZoom(zoomDirection);
    }
  });

  const { data: previewData } = useQuery({
    queryKey: ['preview_data', currentIndex],
    queryFn: async () => {
      if (selectedAttachment.id && application && !isVideoInterview) {
        const { res } = await Api.previewAttachment(
          application.job.id,
          application.id,
          selectedAttachment.id,
          currentIndex
        );
        return res;
      }
    },
    onError: (error: { message: string }) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting preview data, ${error}`,
          state: 'error'
        }
      }),
    onSettled: () => setIsLoadingPreview(false),
    enabled:
      !!selectedAttachment &&
      (attachmentType === AttachmentType.Video || attachmentType === AttachmentType.Word) &&
      !isVideoInterview
  });

  const hasNoPreview = (attachmentType: AttachmentType | undefined): boolean => {
    if (!attachmentType) return true;
    if (attachmentType === AttachmentType.Word) return !previewData?.data;
    return false;
  };

  const handleDisplayContent = () => {
    const shouldDisablePreview = !isLoadingPreview && hasNoPreview(attachmentType);

    if (shouldDisablePreview) {
      return <Box sx={classes.noPreview}>No preview available for this file type.</Box>;
    }

    const videoInterviewSrc = isVideoInterview
      ? selectedAttachment.sources[VIDEO_INTERVIEW_SOURCE_INDEX]?.url
      : '';

    const sharedProps = {
      onPinchHandler,
      zoom: zoomFactor,
      selectedAttachment: selectedAttachment as IAttachment
    };

    const components: Record<Exclude<AttachmentType, AttachmentType.Video>, JSX.Element | null> = {
      [AttachmentType.Image]: (
        <ImagePreview
          {...sharedProps}
          isLoadingPreview={isLoadingPreview}
          setIsLoadingPreview={setIsLoadingPreview}
        />
      ),
      [AttachmentType.Audio]: (
        <AudioPreview
          selectedAttachment={selectedAttachment as IAttachment}
          setIsLoadingPreview={setIsLoadingPreview}
        />
      ),
      [AttachmentType.Text]: (
        <TextPreview
          selectedAttachment={selectedAttachment as IAttachment}
          dispatch={dispatch}
          setIsLoadingPreview={setIsLoadingPreview}
        />
      ),
      [AttachmentType.Pdf]: (
        <PdfPreview
          onPinchHandler={onPinchHandler}
          selectedAttachment={selectedAttachment as IAttachment}
          setIsLoadingPreview={setIsLoadingPreview}
          zoom={zoomFactor}
        />
      ),
      [AttachmentType.Word]: previewData ? (
        <WordPreview {...sharedProps} previewData={previewData} setCurrentIndex={setCurrentIndex} />
      ) : null
    };

    if (!attachmentType) return null;

    if (attachmentType === AttachmentType.Video || isVideoInterview) {
      return (
        <VideoPreview
          selectedAttachment={selectedAttachment}
          videoRef={videoRef}
          source={videoInterviewSrc}
          isLoadingPreview={isLoadingPreview}
          setIsLoadingPreview={setIsLoadingPreview}
        />
      );
    }

    return components[attachmentType];
  };

  const handleZoomDecrement = () => {
    setZoom((prevZoom) => Math.max(0, prevZoom - ZOOM_STEP));
  };

  const handleZoomIncrement = () => {
    setZoom((prevZoom) => Math.min(MAX_SCALE, prevZoom + ZOOM_STEP));
  };

  const showZoomSlider =
    (attachmentType === AttachmentType.Pdf ||
      (attachmentType === AttachmentType.Word && previewData && 'data' in previewData) ||
      attachmentType === AttachmentType.Image) &&
    !isLoadingPreview;

  useEffect(() => {
    const handleWheelEvent = (event: WheelEvent) => {
      if (event.ctrlKey) {
        event.preventDefault();
      }
    };
    document.addEventListener('wheel', handleWheelEvent, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleWheelEvent);
    };
  }, []);

  return (
    <Modal open={previewOpen} onClose={handleClose} aria-labelledby="file-preview-modal">
      <Stack
        sx={{
          ...classes.attachmentPreviewModal,
          height: 'auto',
          maxHeight: '80vh',
          minHeight: '40vh'
        }}
        justifyContent="space-between"
      >
        <CloseIcon
          id="close-attachment-preview-button"
          onClick={handleClose}
          sx={classes.closeIcon}
          className="close-button"
        />
        <Box id="file-preview-modal-title" sx={classes.attachmentPreviewTitle}>
          {modalTitle}
        </Box>
        <Box sx={classes.attachmentPreviewContent}>
          {handleDisplayContent()}
          {isLoadingPreview && (
            <Box sx={{ ...classes.previewLoader, ...classes.absoluteLoader }}>
              <CircularProgress sx={{ color: '#084D6D' }} />
              Loading Preview
            </Box>
          )}
        </Box>
        {showZoomSlider && (
          <Stack direction="row" spacing={1} sx={classes.zoomSliderContainer}>
            <Typography
              id="zoom-slider"
              variant="subtitle1"
              component="div"
              sx={{ marginRight: `${theme.spacing(0.5)} !important` }}
            >
              {zoom}%
            </Typography>

            <CustomSlider
              value={zoom}
              min={0}
              max={MAX_SCALE}
              step={ZOOM_STEP}
              onChange={(_e, value) => setZoom(value as number)}
              aria-labelledby="zoom-slider"
              size="small"
              sx={{ width: '150px' }}
            />
            <Button onClick={handleZoomDecrement} sx={classes.zoomIcons}>
              <ZoomOutSVG />
            </Button>
            <Button onClick={handleZoomIncrement} sx={classes.zoomIcons}>
              <ZoomInSVG />
            </Button>
          </Stack>
        )}
        {permissions?.Applications?.['Download Attachments'] &&
          !isVideoType &&
          !isLoadingPreview && (
            <Box sx={classes.attachmentPreviewActions} marginTop={'auto'}>
              <a
                id="download-file-on-preview-button"
                href={`${BASE_URL}/admin/assets/${selectedAttachment.id}`}
              >
                <Button id="download-attachment-button" sx={classes.downloadButton}>
                  Download
                </Button>
              </a>
            </Box>
          )}
        {isVideoType && !isLoadingPreview && (
          <Box width={'100%'} sx={{ paddingY: '20px' }} marginTop={'auto'}>
            <TimestampTable
              selectedAttachment={selectedAttachment}
              dispatch={dispatch}
              videoRef={videoRef}
              ApplicationState={ApplicationState}
            />
          </Box>
        )}
      </Stack>
    </Modal>
  );
}
