"use strict";
import { theme } from "../../../ThemeContext/ThemeObject";
const MAIN_COLOR = "#15C7C0";
const ALTERNATE_COLOR = "rgba(21, 198, 193, 0.07)";
const BAD_COLOR = "#FF2121";
const ALTERNATE_BAD_COLOR = "rgba(252, 151, 151, 0.07)";
const TEAL_LIGHT = "#EDF2F5";
export const classes = {
  contentHeader: {
    color: "#004665",
    marginBottom: "1.25rem"
  },
  modalContent: {
    display: "flex",
    margin: "auto",
    "& .DayPicker-Weekday abbr": {
      fontSize: "0.85rem",
      color: "#939393",
      textDecoration: "none"
    },
    "& .DayPicker-Caption": {
      color: "#004665",
      fontWeight: "600",
      marginBottom: "15px",
      fontSize: "17px"
    },
    "& .DayPicker-Day": {
      borderRadius: 100,
      padding: "0",
      border: "0",
      width: "40px",
      height: "40px"
    },
    "& .DayPicker-Day--event": {
      backgroundColor: "#EEFBFB"
    },
    "& .DayPicker-Day--highlighted": {
      backgroundColor: "#EEFBFB",
      color: "#15C7C0"
    },
    "& .DayPicker-Day--today": {
      color: "black"
    },
    "& .DayPicker-Day--selected": {
      backgroundColor: "#15C7C0 !important",
      color: "white !important"
    },
    "& .DayPicker-wrapper": {
      paddingTop: "0.5rem",
      fontSize: "0.85rem",
      paddingBottom: "0",
      "& .DayPicker-NavButton": {
        width: "1rem",
        height: "1rem"
      },
      "& .DayPicker-Day--disabled": {
        backgroundColor: "white",
        pointerEvents: "none"
      }
    }
  },
  paper: {
    display: "inline-grid",
    padding: "6px 12px",
    width: "100%",
    boxShadow: "none",
    "& p": {
      fontSize: "0.85rem",
      color: "#6c757d"
    },
    "& .MuiNativeSelect-select:focus": {
      borderRadius: "6px"
    }
  },
  progressHeader: {
    fontSize: "24px",
    color: "#666666",
    paddingBottom: 4,
    fontWeight: "bold"
  },
  contentWrapper: {
    position: "relative",
    fontSize: "0.8rem",
    padding: "4px 10px 4px 14px"
  },
  gridWrapper: {
    flexGrow: 1,
    maxWidth: "60rem"
  },
  datePickerHeaderWrapper: {
    display: "flex",
    alignItems: "center",
    "& div": {
      fontSize: "0.8rem"
    },
    "& .MuiFormControlLabel-root": {
      alignItems: "baseline",
      maxWidth: "initial",
      minWidth: "inherit",
      "& .MuiFormControlLabel-label": {
        marginBottom: "auto",
        paddingTop: "9px"
      }
    }
  },
  datePicker: {
    fontFamily: "Source Sans Pro",
    width: "100%",
    color: "#666666",
    borderRadius: "6px",
    paddingRight: "20px",
    "& input": {
      padding: "3px 16px 9px 16px",
      fontFamily: "Source Sans Pro"
    },
    "& fieldset": {
      borderColor: "#DDDDDD",
      "& legend": {
        display: "none"
      }
    },
    "& button": {
      padding: "0px",
      transform: "translate(-2px, -1.5px)",
      "&:hover": {
        background: "transparent",
        "& svg": {
          color: "#888888"
        }
      }
    },
    "& .MuiInputAdornment-root": {
      marginLeft: "0px"
    },
    "& svg": {
      color: "#AAAAAA"
    }
  },
  buttonBackSuccess: {
    backgroundColor: ALTERNATE_COLOR,
    color: MAIN_COLOR,
    borderColor: ALTERNATE_COLOR,
    fontSize: 16,
    marginRight: "1rem",
    padding: "14px 22px",
    fontWeight: "700",
    borderRadius: 6,
    userSelect: "none",
    "&:hover": {
      backgroundColor: ALTERNATE_COLOR,
      borderColor: ALTERNATE_COLOR,
      cursor: "pointer"
    },
    "&:focus": {
      borderColor: MAIN_COLOR
    }
  },
  buttonBackFail: {
    backgroundColor: ALTERNATE_BAD_COLOR,
    color: BAD_COLOR,
    borderColor: ALTERNATE_BAD_COLOR,
    fontSize: "0.8rem",
    marginRight: "1rem",
    padding: "8px 12px",
    fontWeight: "700",
    "&:hover": {
      backgroundColor: ALTERNATE_BAD_COLOR,
      borderColor: ALTERNATE_BAD_COLOR
    },
    "&:focus": {
      borderColor: BAD_COLOR
    }
  },
  repeatDayPopoverWrapper: {
    "& .MuiPopover-paper": {
      boxShadow: "0px 2px 14px rgba(48, 90, 111, 0.16)",
      padding: "20px 24px",
      maxHeight: 380,
      overflowY: "scroll",
      minWidth: 230
    }
  },
  checkBoxGroupWrapper: {
    "& span": {
      fontSize: "0.8rem"
    },
    "& .MuiSvgIcon-root": {
      fontSize: "1.25rem"
    },
    minWidth: "14rem",
    maxWidth: "14rem"
  },
  checkboxLabel: {
    "& .MuiTypography-root": {
      fontFamily: "Source Sans Pro"
    },
    paddingLeft: "4px",
    marginTop: 2
  },
  popoverWrapper: {
    "& .MuiPopover-paper": {
      boxShadow: "none",
      border: "#e1e1e1 1.7px solid",
      padding: "10px"
    }
  },
  inputFullWidth: {
    "& > div": { width: "100%" }
  },
  jobCheckBox: {
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    "& > span": {
      padding: 7
    },
    "& > p": {
      marginTop: 0
    }
  },
  availabilitySummary: {
    height: "9rem",
    border: "#e1e1e1 1.7px solid",
    borderRadius: "6px",
    backgroundColor: "#FAFAFA",
    padding: "20px 15px",
    "& h1": {
      color: "black",
      marginTop: "0.15rem"
    }
  },
  conflictsContentWrapper: {
    "& contentSubheader": {
      color: "#6c757d",
      fontSize: "0.8rem",
      margin: "0.5rem 0 1.5rem 0"
    },
    "& p": {
      color: "#6c757d",
      fontSize: "0.8rem",
      marginBottom: "0.25rem"
    },
    "& h2": {
      marginBottom: "0rem",
      marginTop: "0.2rem"
    },
    "& h1": {
      marginBottom: "0rem"
    }
  },
  eventSummaryGrid: {
    padding: "2px 10px !important",
    marginTop: "1rem",
    "& .MuiPaper-root": {
      height: "2rem",
      padding: "0 9px"
    }
  },
  eventSummaryGridWrapper: {
    margin: "2px 10px !important",
    border: `${MAIN_COLOR} 2px solid`,
    borderRadius: "6px",
    backgroundColor: "#FAFAFA",
    padding: "1rem",
    "& p": {
      fontSize: "0.85rem"
    },
    "& h2": {
      color: MAIN_COLOR
    }
  },
  loadingStepsWrapper: {
    width: "30rem",
    justifyContent: "center",
    margin: "3rem"
  },
  autocompleteListboxStyle: {
    fontFamily: "Source Sans Pro",
    color: "#333333",
    maxHeight: "200px"
  },
  errorMessage: {
    fontFamily: "inherit",
    color: theme.palette.error.main,
    marginLeft: "6px"
  },
  avatar: {
    color: "#084D6D",
    fontSize: "12px",
    fontWeight: "bold",
    width: 30,
    height: 30,
    background: TEAL_LIGHT
  },
  editIcon: { cursor: "pointer", color: "#666666" },
  autocompleteTextfield: {
    paddingRight: 1,
    "& .MuiFormHelperText-root": {
      margin: "0px 8px",
      fontSize: "12px",
      fontFamily: "inherit",
      color: theme.palette.error.main
    }
  },
  multipleDatesIndicator: {
    marginLeft: 2,
    padding: "0px 8px",
    backgroundColor: TEAL_LIGHT,
    borderRadius: "16px",
    color: "#084D6D",
    fontWeight: "bold",
    cursor: "pointer"
  }
};
