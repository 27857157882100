import React, { useState, useRef, Dispatch } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import StyledModal from '../../../Components/GenericModal/StyledModal';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../API';
import { ApplicationAction, ApplicationState, IApplication } from '../../types';
import { ModalType } from '../../config';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';
import {
  FormTextField,
  FormNumberField,
  MultilineFormTextField
} from '../../../Components/CustomUIElements/FormTextField';
import { TCandidate } from '../../../Candidates/types';
import { scrollToElement } from '../../../utils/scroll-to-element';
import { validateEmail } from '../../../Candidates/EditCandidate';

export default function Referoo({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen, userEmail } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [candidate, setCandidate] = useState<TCandidate | null>(application?.candidate || null);
  const [jobTitle, setJobTitle] = useState<string>(application?.job?.title || '');
  const [company, setCompany] = useState<string>(application?.job?.entity || '');
  const [numberOfReferences, setNumberOfReferences] = useState<string>('');
  const [notes, setNotes] = useState<string>('');
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [country, setCountry] = useState<[string, string] | null>(null);
  const [questionnaire, setQuestionnaire] = useState<[string, string] | null>(null);
  const [referooPackage, setReferooPackage] = useState<[string, string] | null>(null);

  const fieldRefs = {
    firstName: useRef<HTMLInputElement>(null),
    lastName: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    questionnaire: useRef<HTMLInputElement>(null),
    references: useRef<HTMLInputElement>(null),
    country: useRef<HTMLInputElement>(null),
    phone: useRef<HTMLInputElement>(null)
  };

  const { data, isLoading } = useQuery({
    queryKey: ['referoo data'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getReferooData(application.id);
        return res;
      }
    },
    onSuccess: (res) => {
      const candidateCountry = res?.countries.find((c) => c[1] === res.country);
      candidateCountry && setCountry(candidateCountry);
    },
    onError: (error: { error: string }) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting referoo data, ${error.error}`,
          state: 'error'
        }
      })
  });

  const { mutate: referooExport, isLoading: loadingReferooExport } = useMutation({
    mutationFn: async () => {
      if (application && candidate) {
        const { res } = await Api.referooExport(application.id, {
          first_name: candidate.firstname,
          last_name: candidate.lastname,
          email: candidate.email,
          phone: candidate.phone1,
          phone_country: country?.[1] || '',
          job: jobTitle,
          company: company,
          questionnairesNum: questionnaire ? questionnaire[1] : undefined,
          packagesNum: referooPackage ? referooPackage[1] : undefined,
          number_of_references_required: numberOfReferences,
          notes: notes,
          recruiter_email: userEmail
        });
        return res;
      }
    },
    onSuccess: (res: { success: string }) => {
      queryClient.invalidateQueries(['integrations'], { exact: true });
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.success, state: 'success' }
      });
      handleClose();
    },
    onError: (error: { error: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in sending request to referoo, ${error.error}`,
          state: 'error'
        }
      });
    }
  });

  const validateInputs = () => {
    if (!candidate) return false;
    setErrors({});
    const inputErrors: Record<string, string> = {};
    if (!candidate.firstname.trim()) inputErrors.firstName = 'First name can not be empty';
    if (!candidate.lastname.trim()) inputErrors.lastName = 'Last name can not be empty';
    if (!validateEmail(candidate.email)) inputErrors.email = 'Please enter a proper email address';
    if (!country) inputErrors.country = 'Please select a country';
    if (candidate.phone1.trim().includes('+')) inputErrors.phone = 'Please do not use country code';
    if (!questionnaire && !referooPackage)
      inputErrors.questionnaire = 'Please select at least one questionnaire or one package';
    if (Number(numberOfReferences) < 1 && !referooPackage)
      inputErrors.references = 'Please enter a valid number of references';
    setErrors(inputErrors);
    if (inputErrors.firstName) {
      scrollToElement(fieldRefs.firstName);
    } else if (inputErrors.lastName) {
      scrollToElement(fieldRefs.lastName);
    } else if (inputErrors.email) {
      scrollToElement(fieldRefs.email);
    } else if (inputErrors.country) {
      scrollToElement(fieldRefs.country);
    } else if (inputErrors.phone) {
      scrollToElement(fieldRefs.phone);
    } else if (inputErrors.questionnaire) {
      scrollToElement(fieldRefs.questionnaire);
    } else if (inputErrors.references) {
      scrollToElement(fieldRefs.references);
    }
    return !Object.keys(inputErrors).length;
  };

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  if (!candidate) return null;

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.REFEROO}
      label="Referoo export modal"
      handleClose={handleClose}
      styleOverrides={{ ...styles.modalStyleOverrides, width: '800px' }}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Export to Referoo</Stack>
        <Stack sx={{ rowGap: 2, overflow: 'auto' }}>
          <Stack sx={styles.modalDoubleColumn}>
            <FormTextField
              label="First name"
              value={candidate.firstname || ''}
              required
              fullWidth
              onChange={(e) => setCandidate({ ...candidate, firstname: e.target.value })}
              error={errors.firstName}
              innerRef={fieldRefs.firstName}
            />
            <FormTextField
              label="Last name"
              value={candidate.lastname || ''}
              required
              fullWidth
              onChange={(e) => setCandidate({ ...candidate, lastname: e.target.value })}
              error={errors.lastName}
              innerRef={fieldRefs.lastName}
            />
          </Stack>
          <FormTextField
            label="Email"
            value={candidate.email}
            styles={{ width: '48%' }}
            required
            onChange={(e) => setCandidate({ ...candidate, email: e.target.value })}
            error={errors.email}
            innerRef={fieldRefs.email}
          />
          <Stack sx={styles.modalDoubleColumn}>
            <Autocomplete
              disablePortal
              options={data?.countries || []}
              getOptionLabel={(option) => option[0]}
              value={country}
              sx={{
                ...sharedClasses.formAutocomplete,
                width: '100%',
                marginTop: '18px',
                '& .MuiInputLabel-root': { top: '-10px' }
              }}
              loading={isLoading}
              loadingText="Loading countries..."
              ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Phone country"
                  placeholder="Please select"
                  InputLabelProps={{ shrink: true }}
                  sx={styles.autoCompleteTextfield}
                  required
                  helperText={errors.country}
                  ref={fieldRefs.country}
                />
              )}
              onChange={(event, value) => setCountry(value)}
            />
            <FormTextField
              label={'Phone'}
              value={candidate.phone1}
              onChange={(e) => {
                if (
                  Number(e.target.value) === 0 ||
                  (!e.target.value.includes('.') && !!Number(e.target.value))
                ) {
                  setCandidate({ ...candidate, phone1: e.target.value.trim() });
                }
              }}
              required
              fullWidth
              helperText="Do not use the country code here"
              error={errors.phone}
              innerRef={fieldRefs.phone}
            />
          </Stack>
          <Stack sx={styles.modalDoubleColumn}>
            <FormTextField
              label="Job title"
              value={jobTitle}
              fullWidth
              onChange={(e) => setJobTitle(e.target.value)}
            />
            <FormTextField
              label="Company"
              value={company}
              fullWidth
              onChange={(e) => setCompany(e.target.value)}
            />
          </Stack>
          <Stack sx={{ ...styles.modalDoubleColumn, marginRight: '3px', paddingTop: 1 }}>
            <Autocomplete
              disablePortal
              options={data?.questionnaires || []}
              getOptionLabel={(option) => option[0]}
              value={questionnaire}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: 2 }}
              loading={isLoading}
              loadingText="Loading questionnaires..."
              ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Questionnaires"
                  placeholder="Please select"
                  InputLabelProps={{ shrink: true }}
                  sx={styles.autoCompleteTextfield}
                  required
                  helperText={errors.questionnaire}
                  ref={fieldRefs.questionnaire}
                />
              )}
              onChange={(event, value) => setQuestionnaire(value)}
              disabled={!!referooPackage}
            />
            <Autocomplete
              disablePortal
              options={data?.packages || []}
              getOptionLabel={(option) => option[0]}
              value={referooPackage}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: 2 }}
              loading={isLoading}
              loadingText="Loading packages..."
              ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Packages"
                  placeholder="Please select"
                  InputLabelProps={{ shrink: true }}
                  sx={styles.autoCompleteTextfield}
                />
              )}
              onChange={(event, value) => {
                setQuestionnaire(null);
                setNumberOfReferences('');
                setReferooPackage(value);
              }}
            />
          </Stack>
          <FormNumberField
            label={'Number of references required'}
            value={numberOfReferences}
            onChange={(e) => setNumberOfReferences(e.target.value)}
            required
            styles={{
              width: '48%',
              marginLeft: '3px',
              '& .MuiInputBase-input': {
                '&.Mui-disabled': { backgroundColor: 'rgba(217, 217, 217, 0.23)' }
              }
            }}
            error={errors.references}
            innerRef={fieldRefs.references}
            disabled={!!referooPackage}
          />
          <MultilineFormTextField
            label="Notes"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            fullWidth
            rows={6}
          />
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Export"
          primaryButtonCallback={() => validateInputs() && referooExport()}
          isLoading={loadingReferooExport}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          primaryButtonMinWidth="95px"
        />
      </Stack>
    </StyledModal>
  );
}
