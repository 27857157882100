import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';
import { Snackbar, Button, Box, Slide, SlideProps } from '@mui/material';
import {
  Cancel as CancelIcon,
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon
} from '@mui/icons-material';
import { sharedClasses } from './sharedClasses';
import { Portal } from '@mui/base';
import { theme } from '../../../../components/ThemeContext/ThemeObject';

export type SnackbarState = 'success' | 'warning' | 'error';

export interface ISnackbarInput {
  message: string;
  state: SnackbarState;
}

export interface StyledSnackbarProps {
  message: string;
  state: SnackbarState;
  setSnackbarState:
    | Dispatch<SetStateAction<ISnackbarInput>>
    | (({ message, state }: ISnackbarInput) => void);
}

function TransitionDown(props: SlideProps) {
  return <Slide direction="down" {...props} />;
}

function StyledSnackbar({ message, state, setSnackbarState }: StyledSnackbarProps) {
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const clearSnack = useCallback(() => {
    setIsVisible(false);
    setSnackbarState({ message: '', state: 'success' });
  }, [setSnackbarState]);

  const handleClear = () => {
    if (timeout.current) {
      clearTimeout(timeout.current);
    }
    clearSnack();
  };

  useEffect(() => {
    if (message) {
      setIsVisible(true);
      if (timeout.current) {
        clearTimeout(timeout.current);
      }
      timeout.current = setTimeout(clearSnack, 1000);
    }
  }, [clearSnack, message, setSnackbarState, state]);

  if (!message) return null;

  return (
    <Portal>
      <Snackbar
        open={isVisible}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClear}
        message={message}
        TransitionComponent={TransitionDown}
        ClickAwayListenerProps={{ mouseEvent: false, touchEvent: false }}
      >
        <Box sx={getSnackbarStyle(state)}>
          <Box sx={sharedClasses.snackbarMessageIconContainer}>
            {getSnackbarIcon(state)}
            <Box sx={sharedClasses.snackbarMessage}>{message}</Box>
          </Box>
          <Button sx={sharedClasses.snackbarButton} onClick={handleClear}>
            DISMISS
          </Button>
        </Box>
      </Snackbar>
    </Portal>
  );
}

function getSnackbarIcon(state: SnackbarState) {
  switch (state) {
    case 'success':
      return <CheckCircleIcon style={{ color: '#00C853', fontSize: '28px' }} />;
    case 'warning':
      return <WarningIcon style={{ color: theme.palette.secondary.yellow, fontSize: '28px' }} />;
    case 'error':
      return <CancelIcon style={{ color: theme.palette.error.main, fontSize: '28px' }} />;
    default:
      return <WarningIcon style={{ color: theme.palette.secondary.yellow, fontSize: '28px' }} />;
  }
}

function getSnackbarStyle(state: SnackbarState) {
  switch (state) {
    case 'success':
      return { ...sharedClasses.snackbar, ...sharedClasses.snackbarSuccess };
    case 'warning':
      return { ...sharedClasses.snackbar, ...sharedClasses.snackbarWarning };
    case 'error':
      return { ...sharedClasses.snackbar, ...sharedClasses.snackbarError };
    default:
      return { ...sharedClasses.snackbar, ...sharedClasses.snackbarWarning };
  }
}

export default memo(StyledSnackbar);
