import React, { Dispatch, SetStateAction, useCallback, useEffect, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  InputLabel,
  Modal,
  TextField
} from '@mui/material';
import queryString from 'query-string';
import Api from '../API';
import { Virtualize } from '../../Components/CustomUIElements/VirtualizedAutocomplete';
import { DebouncedFormTextField } from '../../Components/CustomUIElements/FormTextField';
import FormRadioButtonGroup from '../../Components/CustomUIElements/FormRadioButtonGroup';
import FormCheckboxGroup from '../../Components/CustomUIElements/FormCheckboxGroup';
import { SingleDateTimePicker } from '../../Components/CustomUIElements/SingleDatePicker';
import CloseIcon from '@mui/icons-material/Close';
import { IJob } from '../../Job/types';
import Attachments from './Attachment/Attachments';
import QuestionFieldViewer from '../../Components/Utilities/QuestionFieldViewer';
import { createJobDefault, emojiRegex, newJobsConfig, resourceDefault } from '../config';
import { classes } from './styles';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import GenericDialog from '../../Components/Modals/GenericDialog';
import ConfirmationDialog from '../../Components/Modals/ConfirmationDialog';
import AIStudioNewJob, {
  IGeneratedContent
} from '../../../AIStudio/NewJobIntegration/AIStudioNewJob';
import moment from 'moment';
import { QuestionsPreview } from '../../../AIStudio/NewJobIntegration/QuestionsPreview';
import DisplayHTMLCollapsible from '../../../AIStudio/SharedComponents/DisplayHTMLCollapsible';
import { IFilters, INewJobDefaults, INewJobParam } from '../types';
import { StyledSnackbarProps } from '../../Components/CustomUIElements/StyledSnackbar';

export default function NewJob({
  apiKey,
  jobDefaults,
  setFilters,
  filters,
  isDialogOpen,
  setIsDialogOpen,
  fromApproval,
  approvalFormId,
  aiStudioEnabled,
  showEntitySection,
  setSnackbar,
  userPermissions,
  userTimezone
}: {
  apiKey: string;
  jobDefaults?: Partial<Record<keyof INewJobDefaults, INewJobParam['value']>>;
  setFilters?: (filter: IFilters) => void;
  filters?: IFilters;
  isDialogOpen: boolean;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
  fromApproval?: boolean;
  approvalFormId?: number;
  aiStudioEnabled: boolean;
  showEntitySection: boolean;
  setSnackbar: StyledSnackbarProps['setSnackbarState'];
  userPermissions: Record<string, Record<string, boolean>>;
  userTimezone: string;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [confirmCancelIsOpen, setConfirmCancelIsOpen] = useState(false);
  const [confirmCreatedJobOpen, setConfirmCreatedJobOpen] = useState(false);
  const [confirmFailJobOpen, setConfirmFailJobOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [createType, setCreateType] = useState('Create new');
  const [resourses, setResourses] = useState(resourceDefault);
  const [jobOptions, setJobOptions] = useState([]);
  const [jobDefaultOptions, setJobDefaultOptions] = useState([]);
  const [prefixReference, setPrefixReference] = useState(null);
  const [clonedJob, setClonedJob] = useState<IJob | null>(null);
  const [copiedJob, setCopiedJob] = useState<IJob | null>(null);
  const [error, setError] = useState(false);
  const [newJobData, setNewJobsData] = useState(createJobDefault);
  const [editJobUrl, setEditJobUrl] = useState<string | null>(null);
  const [generatedContent, setGeneratedContent] = useState<IGeneratedContent>({
    talentLandingPage: '',
    screeningQuestions: []
  });
  const [tlpPreviewCollapsed, setTlpPreviewCollapsed] = useState(true);
  const [entities, setEntities] = useState<{ id: number; name: string }[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<{ id: number; name: string } | null>(null);
  const [loadingEntities, setLoadingEntities] = useState<boolean>(false);

  useEffect(() => {
    if (!jobDefaults) return;
    setNewJobsData((prev) => ({
      ...prev,
      ...Object.entries(jobDefaults).reduce((acc, [key, value]) => {
        acc[key] = {
          ...prev[key],
          value
        };
        return acc;
      }, {})
    }));
  }, [jobDefaults]);

  const fieldRefs = {
    reference: useRef<HTMLInputElement>(null),
    title: useRef<HTMLInputElement>(null),
    location: useRef<HTMLInputElement>(null),
    expires_at: useRef<HTMLInputElement>(null),
    cloneJob: useRef<HTMLInputElement>(null)
  };

  const getEntities = async () => {
    setLoadingEntities(true);
    try {
      const { res } = await Api.getEntities();
      setEntities(res.entities);
      setSelectedEntity(res.current_entity);
    } catch (error) {
      setSnackbar({
        message: `There was an error fetching entities, ${error}`,
        state: 'error'
      });
    } finally {
      setLoadingEntities(false);
    }
  };

  const checkReference = async (isValid: boolean) => {
    setIsLoading(true);
    try {
      const response = await Api.referenceValidation(
        { 'job[reference]': newJobData.reference.value }
      );
      if (response.res.valid) {
        isValid && setCurrentStep(currentStep + 1);
      } else {
        if (response.res.correct_format === false) {
          newJobData?.reference?.value &&
            setNewJobsData((prev) => ({
              ...prev,
              reference: {
                ...prev.reference,
                error: 'Incorrect reference format, prefix letters before digits'
              }
            }));
        } else {
          newJobData?.reference?.value &&
            setNewJobsData((prev) => ({
              ...prev,
              reference: { ...prev.reference, error: 'Reference already in use' }
            }));
        }
        fieldRefs['reference']?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const cloneJob = async () => {
    setIsLoading(true);
    try {
      const data = await Api.importResources(
        copiedJob?.id,
        {
          job: {
            reference: newJobData.reference.value,
            title: newJobData.title.value,
            expires_at: newJobData.expires_at.value,
            location: newJobData.location.value,
            requisition_ids: fromApproval ? [approvalFormId] : undefined
          }
        },
        `?${queryString.stringify(
          { ...resourses, 'resources[modal]': true },
          { arrayFormat: 'bracket', encode: false }
        )}`
      );
      setConfirmCreatedJobOpen(true);
      setEditJobUrl(`${window.location.origin}${data?.res?.job_link}/edit`);
    } catch (error) {
      setConfirmFailJobOpen(true);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const createJob = async () => {
    setIsLoading(true);
    const questions =
      generatedContent.screeningQuestions.length > 0
        ? generatedContent.screeningQuestions.map((question) => ({
            ...question,
            field_type: 'text_area_field'
          }))
        : null;
    try {
      const data = await Api.createJob(
        {
          job: {
            ...jobDefaults,
            reference: newJobData.reference.value,
            title: newJobData.title.value,
            expires_at: newJobData.expires_at.value,
            location: newJobData.location.value,
            ...(generatedContent.talentLandingPage && {
              description: generatedContent.talentLandingPage
            }),
            entity_id: selectedEntity ? selectedEntity.id : undefined,
            requisition_ids: fromApproval ? [approvalFormId] : undefined
          },
          fields: questions
        }
      );
      setConfirmCreatedJobOpen(true);
      setEditJobUrl(`${window.location.origin}${data?.res?.job_link}/edit`);
    } catch (error) {
      setConfirmFailJobOpen(true);
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getJobs = useCallback(async () => {
    setIsLoading(true);
    try {
      const [clonedOptions, prefix] = await Promise.all([
        Api.cloneJobsOptions({}),
        Api.referencePrefix({})
      ]);
      setJobDefaultOptions(clonedOptions?.res?.jobs);
      setPrefixReference(prefix?.res);
      setNewJobsData((prev) => ({
        ...prev,
        reference: {
          ...prev.reference,
          value: prefix?.res?.auto_job_reference_prefix || ''
        }
      }));
      setJobOptions(clonedOptions?.res?.jobs.map((a) => `${a.reference} - ${a.title}`));
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  async function getJob(jobId: string) {
    setIsLoading(true);
    try {
      const data = await Api.getJob(jobId);
      setCopiedJob(data?.res);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  const handleValidateFields = () => {
    let isValid = true;
    let firstError: keyof typeof fieldRefs | undefined = undefined;

    // Validate applicant detial fields
    Object.entries(newJobData).forEach((field) => {
      let tempErrorMessage = '';
      if (field[1].required && !field[1].value) {
        if (!firstError) {
          firstError = field[0] as keyof typeof fieldRefs;
        }
        isValid = false;
        tempErrorMessage = 'This field is required';
      }
      if (field[0] === 'title' || field[0] === 'location') {
        if (field[1].value.match(emojiRegex)) {
          isValid = false;
          tempErrorMessage = "This field can't have emojis";
        }
      }
      if (field[0] === 'expires_at') {
        if (!moment(newJobData.expires_at.value).isValid()) {
          isValid = false;
          tempErrorMessage = 'This field must be a valid date';
        } else {
          if (!moment(newJobData.expires_at.value).isAfter(moment())) {
            isValid = false;
            tempErrorMessage = 'Date and Time must be today or in the future';
          }
        }
      }
      setNewJobsData((prev) => ({
        ...prev,
        [field[0]]: { ...prev[field[0] as keyof typeof prev], error: tempErrorMessage }
      }));
    });

    // Scroll to first invalid field
    if (firstError) {
      fieldRefs[firstError]?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    checkReference(isValid);
    return isValid;
  };

  const clearData = () => {
    setNewJobsData(createJobDefault);
    setIsDialogOpen(false);
    setError(false);
    setCurrentStep(1);
    setCreateType('Create new');
    setResourses(resourceDefault);
    setClonedJob(null);
    setNewJobsData((prev) => ({
      ...prev,
      location: { ...prev.location, value: '' },
      reference: { ...prev.reference, value: '' },
      title: { ...prev.title, value: '' },
      expires_at: { ...prev.expires_at, value: '' }
    }));
    setGeneratedContent({
      talentLandingPage: '',
      screeningQuestions: []
    });
    setSelectedEntity(entities[0]);
  };

  useEffect(() => {
    getJobs();
    getEntities();
  }, [getJobs]);

  return (
    <Modal
      open={isDialogOpen}
      onClose={() => setConfirmCancelIsOpen(true)}
      aria-labelledby="new-job-modal"
    >
      <Box sx={classes.actionsModal}>
        {currentStep !== 3 ? (
          <Box sx={{ width: '25%', padding: '60px 0', borderRight: '#DDDDDD 1px solid' }}>
            <Box id="new-job-modal-title" sx={classes.modalHeader}>
              {fromApproval ? 'Create new job from approval' : 'Create new job'}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', marginTop: '10px' }}>
              <Box sx={{ display: 'flex', padding: '30px 0' }}>
                <Box>
                  <Box sx={classes.verticleLineWhite} />
                  <Box sx={{ display: 'flex' }}>
                    <Box sx={classes.stepNumberGreen}>1</Box>
                    <Box sx={{ paddingLeft: '0.5rem' }}>
                      <Box sx={{ fontWeight: '700', color: '#666666' }}>
                        New Job{' '}
                        {currentStep > 1 ? (
                          <DoneIcon sx={{ fontSize: '18px', color: '#76D18F' }} />
                        ) : (
                          <span />
                        )}
                      </Box>
                      {currentStep !== 1 && currentStep > 1 ? (
                        <Box
                          sx={classes.editStep}
                          onClick={() => {
                            setCurrentStep(1);
                            setError(false);
                          }}
                        >
                          Edit <EditIcon sx={{ fontSize: '12px', marginBottom: '3px' }} />
                        </Box>
                      ) : (
                        <span />
                      )}
                    </Box>
                  </Box>
                  <Box sx={classes.verticleLine} />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', padding: '30px 0' }}>
                <Box>
                  <Box sx={classes.verticleLine} />
                  <Box sx={{ display: 'flex', opacity: currentStep > 1 ? 1 : 0.4 }}>
                    <Box sx={currentStep > 1 ? classes.stepNumberGreen : classes.stepNumberGrey}>
                      2
                    </Box>
                    <Box sx={{ paddingLeft: '0.5rem' }}>
                      <Box
                        sx={{ fontWeight: currentStep > 1 ? '700' : 'inherit', color: '#666666' }}
                      >
                        Job Details{' '}
                        {currentStep > 2 ? (
                          <DoneIcon sx={{ fontSize: '18px', color: '#76D18F' }} />
                        ) : (
                          <span />
                        )}
                      </Box>
                      {currentStep !== 2 && currentStep > 1 ? (
                        <Box sx={classes.editStep} onClick={() => setCurrentStep(2)}>
                          Edit <EditIcon sx={{ fontSize: '12px', marginBottom: '3px' }} />
                        </Box>
                      ) : (
                        <span />
                      )}
                    </Box>
                  </Box>
                  <Box sx={classes.verticleLine} />
                </Box>
              </Box>
              <Box sx={{ display: 'flex', padding: '30px 0' }}>
                <Box>
                  <Box sx={classes.verticleLine} />
                  <Box sx={{ display: 'flex', opacity: currentStep > 2 ? 1 : 0.4 }}>
                    <Box sx={currentStep > 2 ? classes.stepNumberGreen : classes.stepNumberGrey}>
                      3
                    </Box>
                    <Box
                      sx={{
                        paddingLeft: '0.5rem',
                        fontWeight: currentStep > 2 ? '700' : 'inherit',
                        color: '#666666'
                      }}
                    >
                      Review
                    </Box>
                  </Box>
                  <Box sx={classes.verticleLineWhite} />
                </Box>
              </Box>
            </Box>
          </Box>
        ) : (
          <span />
        )}
        <Box
          sx={{
            width: currentStep !== 3 ? '75%' : '100%',
            paddingLeft: currentStep !== 3 ? '3.5rem' : '0',
            paddingTop: '60px',
            paddingBottom: '60px'
          }}
        >
          <CloseIcon onClick={() => setConfirmCancelIsOpen(true)} sx={classes.closeIcon} />
          {currentStep === 1 && (
            <Box
              sx={classes.modalContent}
              id="new-job-modal-content"
              aria-label="New job modal content"
            >
              <Box id="new-job-modal-title" sx={classes.modalHeaderContent}>
                Would you like to clone from an existing job or create a new one?
              </Box>
              <Box role="form" id="new-job-form" aria-label="New job form">
                <Box sx={classes.fullWidthFieldContainer}>
                  <Box sx={classes.cloneJobInput}>
                    <FormRadioButtonGroup
                      options={[
                        { label: 'Clone existing job', value: 'Clone existing job' },
                        { label: 'Create new', value: 'Create new' }
                      ]}
                      onChange={(value: string) => setCreateType(value)}
                      directionRow={true}
                      color={'#838383'}
                      defaultValue={createType}
                      groupLabel={''}
                    />
                  </Box>
                  {createType === 'Clone existing job' ? (
                    <Box>
                      <Box sx={{ margin: '2rem 0', position: 'relative' }}>
                        <Virtualize
                          value={clonedJob}
                          passOptions={jobOptions}
                          setValue={async (event: string, value: string) => {
                            const index = Number(event);
                            if (typeof index === 'number') {
                              const reference = value.split(' -')[0];
                              const i = jobDefaultOptions.findIndex(
                                (option) => option?.reference === reference
                              );
                              setClonedJob(value);
                              getJob(jobDefaultOptions[i >= 0 ? i : index]?.id);
                            }
                          }}
                          hasIdAndRef={true}
                          passedStyles={{ width: '99.5%', ...classes.formAutocomplete }}
                          errorStyles={{ ...sharedClasses.errorBox, top: '-24px', right: '0px' }}
                          label="Choose job"
                        />
                        {error && <Box sx={classes.errorBox}>This field is required</Box>}
                      </Box>
                      <Box sx={classes.fullWidthFieldContainer}>
                        <FormCheckboxGroup
                          options={newJobsConfig.resource_field_choices}
                          directionRow={true}
                          defaultValues={resourses}
                          onChange={(value: string) => setResourses(value)}
                          groupLabel={'Choose resources to import'}
                          color={'#838383'}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box />
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {currentStep === 2 && (
            <Box
              sx={classes.modalContent}
              id="new-job-modal-content"
              aria-label="New job modal content"
            >
              <Box id="new-job-modal-title" sx={classes.modalHeaderContent}>
                Tell us the details of your job
              </Box>
              <Box role="form" id="new-job-form" aria-label="New job form">
                <Box sx={classes.modalFormLine}>
                  <DebouncedFormTextField
                    onDebouncedChange={(value: string) =>
                      setNewJobsData((prev) => ({
                        ...prev,
                        reference: { ...prev.reference, value }
                      }))
                    }
                    id="reference"
                    dataTestId="reference"
                    label="Reference"
                    defaultValue={newJobData?.reference?.value}
                    required={newJobData?.reference?.required}
                    error={newJobData?.reference?.error}
                    innerRef={fieldRefs.reference}
                    styles={{ width: '250px' }}
                  />
                  <DebouncedFormTextField
                    onDebouncedChange={(value: string) =>
                      setNewJobsData((prev) => ({
                        ...prev,
                        title: { ...prev.title, value }
                      }))
                    }
                    id="title"
                    dataTestId="title"
                    label="Title"
                    defaultValue={newJobData?.title?.value}
                    required={newJobData?.title?.required}
                    error={newJobData?.title?.error}
                    innerRef={fieldRefs.title}
                    styles={{ width: '250px' }}
                  />
                </Box>
                <Box sx={classes.modalFormLine}>
                  <DebouncedFormTextField
                    onDebouncedChange={(value: string) =>
                      setNewJobsData((prev) => ({
                        ...prev,
                        location: { ...prev.location, value }
                      }))
                    }
                    id="location"
                    dataTestId="location"
                    label="City/Town"
                    error={newJobData?.location?.error}
                    defaultValue={newJobData?.location?.value}
                    required={newJobData?.location?.required}
                    innerRef={fieldRefs.location}
                    styles={{ width: '250px' }}
                  />
                  {showEntitySection && !clonedJob && (
                    <Autocomplete
                      disablePortal
                      autoHighlight
                      options={entities}
                      loading={loadingEntities}
                      getOptionLabel={(option) => option.name}
                      noOptionsText="No entities"
                      value={entities.find((e) => e.id === selectedEntity?.id)}
                      onChange={(_event, newValue) =>
                        newValue ? setSelectedEntity(newValue) : setSelectedEntity(entities[0])
                      }
                      sx={{ ...sharedClasses.formAutocomplete, width: '250px', marginTop: '24px' }}
                      ListboxProps={{ style: { fontFamily: 'Source Sans Pro' } }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{ input: { height: '20px' } }}
                          label="Entity"
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <React.Fragment>
                                {loadingEntities ? (
                                  <CircularProgress color="inherit" size={20} />
                                ) : null}
                                {params.InputProps.endAdornment}
                              </React.Fragment>
                            )
                          }}
                        />
                      )}
                    />
                  )}
                </Box>
                <Box
                  sx={{ ...classes.modalFormLine, columnGap: '25px', position: 'relative' }}
                  ref={fieldRefs.expires_at}
                >
                  <Box id="expiry-date" data-testid="expiry-date">
                    <SingleDateTimePicker
                      label={
                        <InputLabel htmlFor="text-input" sx={sharedClasses.inputLabel}>
                          Expiry date and time<span>*</span>
                        </InputLabel>
                      }
                      inputStyles={classes.datePicker}
                      onChange={(value) => {
                        setNewJobsData((prev: any) => ({
                          ...prev,
                          expires_at: {
                            ...prev.expires_at,
                            value: moment(value).toISOString(),
                            error: ''
                          }
                        }));
                      }}
                      timezone={userTimezone ?? moment.tz.guess()}
                      value={newJobData.expires_at.value}
                      disablePast={true}
                    />
                  </Box>
                  {newJobData.expires_at.error && (
                    <Box sx={{ ...classes.errorBox, bottom: '-35px' }}>
                      {newJobData.expires_at.error}
                    </Box>
                  )}
                </Box>
              </Box>
              {aiStudioEnabled && createType !== 'Clone existing job' && (
                <AIStudioNewJob
                  generatedContent={generatedContent}
                  setGeneratedContent={setGeneratedContent}
                  prefillData={{
                    title: newJobData?.title?.value,
                    country: newJobData?.location?.value
                  }}
                />
              )}
            </Box>
          )}
          {currentStep === 3 && (
            <Box
              sx={classes.modalContentStep3}
              id="new-job-modal-content"
              aria-label="New job modal content"
            >
              <Box
                id="new-job-modal-title"
                data-testid="new-job-modal-title-step-3"
                sx={classes.modalHeaderContent}
              >
                Final step! Is this correct?
              </Box>
              <Box role="form" id="new-job-form" aria-label="New job form">
                <Box sx={{ ...classes.modalFormLine, display: 'contents' }}>
                  <Box sx={classes.reviewSubheaders}>Reference</Box>
                  <Box sx={classes.reviewContent}>{newJobData.reference.value}</Box>
                  <Box sx={classes.reviewSubheaders}>Job Title</Box>
                  <Box sx={classes.reviewContent}>{newJobData.title.value}</Box>
                  {showEntitySection && !clonedJob && (
                    <>
                      <Box sx={classes.reviewSubheaders}>Entity</Box>
                      <Box sx={classes.reviewContent}>{selectedEntity?.name}</Box>
                    </>
                  )}
                </Box>
                <Box sx={classes.modalFormLine}>
                  <Box sx={classes.reviewBoxes}>
                    <Box>City/town</Box>
                    <Box sx={{ fontWeight: '800' }}>{newJobData.location.value}</Box>
                  </Box>
                  <Box sx={classes.reviewBoxes}>
                    <Box>Expiry</Box>
                    <Box sx={{ fontWeight: '800' }}>
                      {moment(newJobData.expires_at.value)
                        .tz(userTimezone ?? moment.tz.guess())
                        .format('D MMM YYYY, hh:mm a')}
                    </Box>
                  </Box>
                </Box>
                {aiStudioEnabled &&
                  createType !== 'Clone existing job' &&
                  generatedContent.talentLandingPage && (
                    <Box sx={classes.previewContainer}>
                      <h3>Talent landing page preview</h3>
                      <DisplayHTMLCollapsible
                        content={generatedContent.talentLandingPage}
                        collapsed={tlpPreviewCollapsed}
                        setCollapsed={setTlpPreviewCollapsed}
                      />
                    </Box>
                  )}
                {aiStudioEnabled &&
                  createType !== 'Clone existing job' &&
                  generatedContent.screeningQuestions.length > 0 && (
                    <QuestionsPreview
                      questions={generatedContent.screeningQuestions}
                      previewOnly={true}
                    />
                  )}
                <Box>
                  {createType === 'Clone existing job' && copiedJob ? (
                    <Box>
                      {resourses['resources[description]'] ? (
                        <Box sx={{ ...classes.modalFormLine, display: 'contents' }}>
                          <Box sx={classes.reviewSubheaders}>Description</Box>
                          {copiedJob?.description ? (
                            <Box sx={classes.reviewContent}>
                              <div dangerouslySetInnerHTML={{ __html: copiedJob?.description }} />
                            </Box>
                          ) : (
                            <Box>No Description</Box>
                          )}
                        </Box>
                      ) : (
                        <Box />
                      )}
                      {resourses['resources[questions]'] ? (
                        <Box
                          sx={{
                            ...classes.modalFormLine,
                            display: 'contents',
                            marginTop: '1.5rem'
                          }}
                        >
                          <Box sx={{ ...classes.reviewSubheaders, marginBottom: '0.5rem' }}>
                            Questions
                          </Box>
                          {copiedJob?.questions && copiedJob?.questions.length > 0 ? (
                            <Box>
                              {copiedJob?.questions &&
                                copiedJob.questions.map((question) => (
                                  <Box key={question.id}>
                                    {question.type === 'AvailabilityField' ||
                                    question.type === 'CsvDataLookupField' ||
                                    question.type === 'JobReferenceLookupField' ? (
                                      <span />
                                    ) : (
                                      <Box sx={classes.questionsWrapper}>
                                        <Box sx={{ marginRight: 'auto', ...classes.questionInput }}>
                                          <Box sx={classes.questionsTitle}>Title</Box>
                                          <Box
                                            sx={{
                                              ...classes.modalFormLine,
                                              position: 'relative'
                                            }}
                                          >
                                            <QuestionFieldViewer question={question} />
                                          </Box>
                                        </Box>
                                        <Box sx={{ padding: '0 6px' }}>
                                          <Box sx={classes.questionsTitle}>Enabled</Box>
                                          {question.enabled ? (
                                            <CheckCircleIcon sx={classes.iconGreen} />
                                          ) : (
                                            <CancelIcon sx={classes.iconRed} />
                                          )}
                                        </Box>
                                        <Box sx={{ padding: '0 6px' }}>
                                          <Box sx={classes.questionsTitle}>Required</Box>
                                          {question.required ? (
                                            <CheckCircleIcon sx={classes.iconGreen} />
                                          ) : (
                                            <CancelIcon sx={classes.iconRed} />
                                          )}
                                        </Box>
                                        <Box sx={{ padding: '0 6px' }}>
                                          <Box sx={classes.questionsTitle}>Rateable</Box>
                                          {question.rateable ? (
                                            <CheckCircleIcon sx={classes.iconGreen} />
                                          ) : (
                                            <CancelIcon sx={classes.iconRed} />
                                          )}
                                        </Box>
                                        <Box sx={{ padding: '0 6px' }}>
                                          <Box sx={classes.questionsTitle}>Confidential</Box>
                                          {question.confidential ? (
                                            <CheckCircleIcon sx={classes.iconGreen} />
                                          ) : (
                                            <CancelIcon sx={classes.iconRed} />
                                          )}
                                        </Box>
                                        <Box sx={{ paddingLeft: '6px' }}>
                                          <Box sx={classes.questionsTitle}>Weight</Box>
                                          <Box sx={classes.weightValue}>{question.weight}</Box>
                                        </Box>
                                      </Box>
                                    )}
                                  </Box>
                                ))}
                            </Box>
                          ) : (
                            <Box sx={{ paddingLeft: '4px' }}>No Questions</Box>
                          )}
                        </Box>
                      ) : (
                        <Box />
                      )}
                      {resourses['resources[attachments]'] ? (
                        <Box
                          sx={{
                            ...classes.modalFormLine,
                            display: 'contents',
                            marginTop: '1.5rem'
                          }}
                        >
                          <Box sx={classes.reviewSubheaders}>Attachments</Box>
                          <Attachments
                            apiKey={apiKey}
                            jobId={copiedJob?.id}
                            permissions={{
                              delete: false
                            }}
                          />
                        </Box>
                      ) : (
                        <Box />
                      )}
                      {resourses['resources[sources]'] ? (
                        <Box
                          sx={{
                            ...classes.modalFormLine,
                            display: 'contents',
                            marginTop: '2rem'
                          }}
                        >
                          <Box sx={classes.reviewSubheaders}>Sources</Box>
                          <Box
                            sx={{
                              ...classes.reviewContent,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            Imported <CheckCircleIcon sx={classes.iconGreenResources} />
                          </Box>
                        </Box>
                      ) : (
                        <Box />
                      )}
                      {resourses['resources[auto_actions]'] ? (
                        <Box
                          sx={{
                            ...classes.modalFormLine,
                            display: 'contents',
                            marginTop: '2rem'
                          }}
                        >
                          <Box sx={classes.reviewSubheaders}>Auto-Actions</Box>
                          <Box
                            sx={{
                              ...classes.reviewContent,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            Imported <CheckCircleIcon sx={classes.iconGreenResources} />
                          </Box>
                        </Box>
                      ) : (
                        <Box />
                      )}
                      {resourses['resources[options]'] ? (
                        <Box
                          sx={{
                            ...classes.modalFormLine,
                            display: 'contents',
                            marginTop: '2rem'
                          }}
                        >
                          <Box sx={classes.reviewSubheaders}>Options</Box>
                          <Box
                            sx={{
                              ...classes.reviewContent,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            Imported <CheckCircleIcon sx={classes.iconGreenResources} />
                          </Box>
                        </Box>
                      ) : (
                        <Box />
                      )}
                      {resourses['resources[visibility]'] ? (
                        <Box
                          sx={{
                            ...classes.modalFormLine,
                            display: 'contents',
                            marginTop: '2rem'
                          }}
                        >
                          <Box sx={classes.reviewSubheaders}>Visibility</Box>
                          <Box
                            sx={{
                              ...classes.reviewContent,
                              display: 'flex',
                              alignItems: 'center'
                            }}
                          >
                            Imported <CheckCircleIcon sx={classes.iconGreenResources} />
                          </Box>
                        </Box>
                      ) : (
                        <Box />
                      )}
                    </Box>
                  ) : (
                    <span />
                  )}
                </Box>
              </Box>
            </Box>
          )}
          <Box sx={classes.modalFooter}>
            {currentStep === 3 ? (
              <Button
                id="edit-new-job-button"
                type="submit"
                sx={classes.modalEditButton}
                onClick={() => setCurrentStep(currentStep - 1)}
              >
                Edit
              </Button>
            ) : (
              <span />
            )}
            <Button
              id={currentStep === 3 ? 'create-new-job-button' : 'next-new-job-button'}
              data-testid={currentStep === 3 ? 'create-new-job-button' : 'next-new-job-button'}
              type="submit"
              sx={classes.modalConfirmationButton}
              onClick={() => {
                if (isLoading) return;
                if (currentStep === 1) {
                  if (createType === 'Clone existing job') {
                    if (!clonedJob) {
                      setError(true);
                      fieldRefs['cloneJob']?.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center'
                      });
                    } else {
                      setNewJobsData((prev) => ({
                        ...prev,
                        location: { ...prev.location, value: copiedJob?.location },
                        title: { ...prev.title, value: copiedJob?.title },
                        expires_at: { ...prev.expires_at, value: '' }
                      }));
                      const jobEntity = entities.find((e) => e.id === copiedJob?.entity_id);
                      jobEntity ? setSelectedEntity(jobEntity) : setSelectedEntity(entities[0]);
                      setCurrentStep(currentStep + 1);
                    }
                  } else setCurrentStep(currentStep + 1);
                }
                currentStep === 2 && handleValidateFields();
                currentStep === 3 && createType === 'Clone existing job' && cloneJob();
                currentStep === 3 && createType === 'Create new' && createJob();
              }}
            >
              {currentStep === 3 || currentStep === 1 ? (
                isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  'Confirm'
                )
              ) : (
                'Next'
              )}
            </Button>
          </Box>
          <GenericDialog
            isDialogOpen={confirmCancelIsOpen}
            setDialogOpen={setConfirmCancelIsOpen}
            title="Close without submitting?"
            description="Are you sure you want to cancel your job? You will lose any progress you have made."
            buttonCallback={() => {
              setConfirmCancelIsOpen(false);
              clearData();
            }}
            callbackLoading={false}
            buttonText="Close without saving"
            url=""
          />
        </Box>
        <ConfirmationDialog
          isDialogOpen={confirmCreatedJobOpen}
          setDialogOpen={setConfirmCreatedJobOpen}
          title="Complete"
          description="Your job has now been created."
          moreContent="To add more information or edit click below."
          buttonCallback={() => {
            setConfirmCreatedJobOpen(false);
            setFilters && setFilters({ ...filters });
            clearData();
          }}
          callbackLoading={false}
          buttonText="Continue editing"
          url={editJobUrl ? editJobUrl : ''}
        />
        <ConfirmationDialog
          isDialogOpen={confirmFailJobOpen}
          setDialogOpen={setConfirmFailJobOpen}
          title="Failed"
          description="Your job failed to be created."
          moreContent=""
          buttonCallback={() => {
            setConfirmFailJobOpen(false);
            clearData();
          }}
          callbackLoading={false}
          buttonText="Confirm"
          url={''}
        />
      </Box>
    </Modal>
  );
}
