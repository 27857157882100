/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import CandidateTags from '../../../Candidate/CandidateTags';
import AISummary from '../AISummary';
import Responses from '../Responses';
import Actions from '../Actions';
import Questionnaire from '../Questionnaire';
import AttachmentsTab from '../Attachments';
import Notepad from '../../../Candidate/Notepad';
import { IApplication } from '../../types';
import { OverviewTabProps, SectionProps } from './types';
import { useQueryClient } from '@tanstack/react-query';
import { IUserPermissions } from '../../../Components/sharedTypes';
import { SectionName } from '../../config';

const sectionComponents: Record<SectionName, FC<OverviewTabProps>> = {
  [SectionName.CandidateTags]: ({ ApplicationState, application, dispatch }) => (
    <CandidateTags
      focused={ApplicationState.focused}
      candidate={application.candidate}
      dispatch={dispatch}
      inputId="candidate-tags-field"
    />
  ),
  [SectionName.FelixAISummary]: ({ dispatch, aiSummariesEnabled }) => (
    <AISummary dispatch={dispatch} aiSummariesEnabled={aiSummariesEnabled} />
  ),
  [SectionName.Responses]: ({ dispatch }) => <Responses dispatch={dispatch} />,
  Notepad: ({
    ApplicationState,
    updateApplicationNotepad,
    updatingApplicationNotepad,
    dispatch,
    permissions
  }) => (
    <>
      {permissions?.Applications?.['Add Suitability Comment'] && (
        <Notepad
          note={ApplicationState.overviewTab.note}
          updateNote={updateApplicationNotepad}
          updatingNote={updatingApplicationNotepad}
          inputId="application-notepad-field"
          buttonId="save-application-notepad-button"
          onChangeCallback={(value) =>
            dispatch({
              type: 'SET_OVERVIEW_TAB',
              payload: { note: value, hasUnsavedChanges: true }
            })
          }
          primaryButtonDisabled={!ApplicationState.overviewTab.hasUnsavedChanges}
        />
      )}
    </>
  ),
  [SectionName.Actions]: ({ dispatch }) => <Actions dispatch={dispatch} />,
  [SectionName.ApplicationQuestionnaire]: ({ hrQuestion, dispatch }) => (
    <Questionnaire hrQuestion={hrQuestion} dispatch={dispatch} />
  ),
  [SectionName.Attachments]: ({ ApplicationState, dispatch }) => (
    <AttachmentsTab ApplicationState={ApplicationState} dispatch={dispatch} />
  )
};

export const Section = ({ section, ...props }: SectionProps) => {
  const SectionComponent = sectionComponents[section];

  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);

  if (!application || !SectionComponent) return null;
  return <SectionComponent {...props} application={application} permissions={permissions} />;
};
