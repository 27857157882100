"use strict";
export const useDispatch = (dispatch) => {
  const displayError = (message) => dispatch({
    type: "SET_SNACKBAR",
    payload: { message, state: "error" }
  });
  const displaySuccess = (message) => dispatch({
    type: "SET_SNACKBAR",
    payload: { message, state: "success" }
  });
  return { displayError, displaySuccess };
};
