import React, { useEffect, useRef, useState } from "react";
import { Box } from "@material-ui/core";
import useStyles from "../../../../../IndeedIntegration/AdEditor/useStyles";
import { jobAdPropTypes } from "../../../../../NewUI/Components/Multiposter/types";
function Step3({ answers, setAnswers, jobAdParams, requiredRefs, activeError, previewUrl }) {
  const classes = useStyles();
  const bypassPreviewUrl = `${previewUrl}?bypassPayRedirect=true`;
  const [iframeKey, setIframeKey] = useState(0);
  const iframeRef = useRef(null);
  useEffect(() => {
    const handlePostMessage = (event) => {
      if (!event.origin.includes("viewjobs.com.au")) {
        console.warn("Message from unauthorized origin:", event.origin);
        return;
      }
      const { sessionUrl } = event.data;
      if (sessionUrl) {
        console.log("Received Stripe Checkout URL:", sessionUrl);
        window.open(sessionUrl, "_blank", "noopener,noreferrer");
      } else {
        console.log("Unknown message received:", event.data);
      }
    };
    window.addEventListener("message", handlePostMessage);
    return () => {
      window.removeEventListener("message", handlePostMessage);
    };
  }, [previewUrl]);
  return /* @__PURE__ */ React.createElement(Box, { className: classes.stepContainer }, /* @__PURE__ */ React.createElement("h4", null, "Preview job"), /* @__PURE__ */ React.createElement("a", { href: bypassPreviewUrl, target: "_blank", rel: "noopener noreferrer" }, "View Job Preview"), /* @__PURE__ */ React.createElement(
    "iframe",
    {
      ref: iframeRef,
      key: iframeKey,
      src: bypassPreviewUrl,
      title: "Job Preview",
      width: "100%",
      height: "1600px"
    }
  ));
}
Step3.propTypes = jobAdPropTypes;
export default Step3;
