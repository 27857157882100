import React, { useState, Dispatch } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import StyledModal from '../../../Components/GenericModal/StyledModal';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../API';
import { ApplicationAction, ApplicationState, IApplication } from '../../types';
import { ModalType } from '../../config';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';

export default function Testgrid({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [testgridPackage, setTestgridPackage] = useState<string | null>(null);
  const [error, setError] = useState<string>('');

  const { data: packages, isLoading: loadingPackages } = useQuery({
    queryKey: ['testgrid packages'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getTestGridPackages(application.id);
        return res;
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting testgrid data, ${error}`,
          state: 'error'
        }
      })
  });

  const validateInput = () => {
    setError('');
    if (!testgridPackage) {
      setError('Please select a package');
      return false;
    }
    return true;
  };

  const { mutate: testGridExport, isLoading: loadingTestGridExport } = useMutation({
    mutationFn: async () => {
      if (application && packages && testgridPackage) {
        const { res } = await Api.testGridExport(application.id, packages[testgridPackage]);
        return res;
      }
    },
    onSuccess: (res: { success: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.success, state: 'success' }
      });
      handleClose();
    },
    onError: (error: { message: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in sending request to testgrid, ${error.message}`,
          state: 'error'
        }
      });
    }
  });

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.TESTGRID}
      label="Testgrid export modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Testgrid request test</Stack>
        <Autocomplete
          disablePortal
          options={Object.keys(packages || {})}
          value={testgridPackage}
          sx={{ ...sharedClasses.formAutocomplete, width: '48%', marginTop: 3, flexGrow: 1 }}
          loading={loadingPackages}
          loadingText="Loading packages..."
          ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Package"
              placeholder="Please select"
              InputLabelProps={{ shrink: true }}
              sx={styles.autoCompleteTextfield}
              required
              helperText={error}
            />
          )}
          onChange={(event, value) => setTestgridPackage(value)}
        />
        <ModalFooterButtons
          primaryButtonText="Send"
          primaryButtonCallback={() => validateInput() && testGridExport()}
          isLoading={loadingTestGridExport}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          primaryButtonMinWidth="95px"
        />
      </Stack>
    </StyledModal>
  );
}
