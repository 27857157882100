import React, { Dispatch } from 'react';
import { CircularProgress, IconButton, Tooltip } from '@mui/material';
import { Refresh as RefreshIcon } from '@mui/icons-material';
import { useGetIntegrationsData } from '../helper';
import { useQueryClient } from '@tanstack/react-query';
import { ApplicationAction, IApplication } from '../types';

export default function UpdateStatusIcon({
  callback,
  loading,
  dispatch
}: {
  callback?: () => void;
  loading?: boolean;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const { refetchIntegrationsData, refetchingIntegrationsData } = useGetIntegrationsData({
    applicationId: application?.id,
    dispatch: dispatch
  });
  const buttonLoading = loading ? loading : refetchingIntegrationsData;
  return (
    <Tooltip title="Update status" placement="top" arrow>
      <IconButton
        sx={{
          padding: 0.5,
          '&:focus': {
            outline: 'none'
          }
        }}
        onClick={() => (callback ? callback() : refetchIntegrationsData())}
      >
        {buttonLoading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <RefreshIcon fontSize="small" sx={{ color: '#666666' }} />
        )}
      </IconButton>
    </Tooltip>
  );
}
