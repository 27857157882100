"use strict";
import RecruitApiClient from "../RecruitApiClient";
const BASE_URL = "/api/v4";
class Api {
  constructor() {
    this.getEventOptions = () => RecruitApiClient.get(`${BASE_URL}/calendar/event_types`);
    this.getEventRecruiters = () => RecruitApiClient.get(`${BASE_URL}/calendar/users`);
    this.getEvents = (date, typeId) => RecruitApiClient.get(`${BASE_URL}/calendar/events`, {
      date,
      event_type_id: typeId || void 0
    });
    this.deleteEvent = (id) => RecruitApiClient.delete(`${BASE_URL}/calendar/events/${id}`);
    this.createEventsBatches = (params) => RecruitApiClient.post(`${BASE_URL}/calendar/event_batches`, {
      event_batch: params
    });
    this.removeAttendees = (eventId, params) => RecruitApiClient.post(`${BASE_URL}/calendar/events/${eventId}/remove_attendees`, {
      attendee_ids: params
    });
    this.updateEvent = (id, params) => RecruitApiClient.put(`${BASE_URL}/calendar/events/${id}`, params);
    this.updateAttendeeInvitation = (eventId, attendeeId, state) => RecruitApiClient.put(`${BASE_URL}/calendar/events/${eventId}/attendees/${attendeeId}`, {
      attendee: { state }
    });
    this.getEmailTemplates = () => RecruitApiClient.get(`${BASE_URL}/email_templates?group_templates=true`);
    this.getEmailTemplate = (templateId) => RecruitApiClient.get(`${BASE_URL}/email_templates/${templateId}`);
    this.getSmsTemplates = () => RecruitApiClient.get(`${BASE_URL}/sms_templates`);
    this.saveSmsTemplate = (smsTemplateName, smsMessage) => RecruitApiClient.post(`${BASE_URL}/sms_templates`, {
      sms_template: {
        title: smsTemplateName,
        body: smsMessage
      }
    });
    this.smsCandidates = (eventId, applicationIds, content) => RecruitApiClient.post(`calendar/events/${eventId}/sms_candidates`, {
      applications_ids: applicationIds,
      content
    });
    this.getEventApplicationStatuses = (eventId) => RecruitApiClient.get(
      `${BASE_URL}/calendar/events/${eventId}/event_application_statuses`
    );
    this.changeApplicationStatuses = (eventId, statusId, applicationIds) => RecruitApiClient.post(
      `${BASE_URL}/calendar/events/${eventId}/change_status`,
      {
        application_ids: applicationIds,
        change_status: statusId
      }
    );
    this.sendEmailBatchesToCandidates = (eventId, data) => RecruitApiClient.post(`${BASE_URL}/calendar/events/${eventId}/email_candidates`, data);
  }
  /**
   * @deprecated remove later
   */
  // inviteToTimeslot = (eventTypeId, jobId, userId, invitation: {}) =>
  //   this.post('invitations', {
  //     user_id: userId,
  //     event_type_id: eventTypeId,
  //     job_id: jobId,
  //     invitation
  //   });
  // addToEvent = (eventId, jobId, applicationId) =>
  //   this.post(`events/${eventId}/attendees`, {
  //     job_id: jobId,
  //     application_id: applicationId
  //   });
  // getJobApplications = (id: number) => this.get(`jobs/${id}/applications`);
  // getRecrutRecruitersById = (id: number) => this.custom_get(`users?event_batch_id=${id}`);
  // getEventBatchesById = (id: string) => this.get(`event_batches/${id}`);
  // getEvent = (id: string) => this.get(`events/${id}`);
  // deleteEventBatch = (id: number) => this.delete(`event_batches/${id}`);
  // updateEventsBatches = (event, id) => this.put(`event_batches/${id}`, event);
  // getApplications = (id: string, body: {}) => this.get(`event_batches/${id}/applications`, body);
  // getStatuses = (id: string) => this.get(`event_batches/${id}/statuses`);
  // getAvailabilities = () => this.get('availabilities');
  // saveAvailabilities = (availabilities) => this.put('availabilities', availabilities);
  // createInvitations = (id: string, invitation: {}) =>
  //   this.post(`event_batches/${id}/invitations`, invitation);
}
export default new Api();
