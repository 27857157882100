import React, { useState, useEffect, useRef, useReducer } from 'react';
import { Box, Stack } from '@mui/material';
import { widgetsContainerPadding, widgetsbarWidths } from './config';
import { classes } from './styles';
import ShowRightMenu from '../Header/Components/ShowRightMenu';
import ActivityFeed from './ActivityFeed/ActivityFeed';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Popover from '@mui/material/Popover';
import RecentlyViewed from './RecentlyViewed/RecentlyViewed';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { InitialScoutCalendarState, ScoutCalendarReducer } from '../../../ScoutCalendar/reducer';
import EventCreated from '../../../ScoutCalendar/EventCreated';
import CreateEvent from '../../../ScoutCalendar/CreateEvent/CreateEvent';
import { EventActionType, ScoutCalendarAction } from '../../../ScoutCalendar/types';
import { useEvents } from '../../hooks';

const BASE_URL = window.location.origin;
const queryClient = new QueryClient();
const ENGAGE_URL = window.location.pathname.includes('/admin/search/advanced');

const Widgetsbar = ({
  userPermissions,
  calendlyEnabled,
  apiKey,
  userTimezone
}: {
  userPermissions: string;
  calendlyEnabled: boolean;
  apiKey: string;
  userTimezone: string;
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);
  const [widgetsbarState, setWidgetsbarState] = useState<keyof typeof widgetsbarWidths>(
    (localStorage.getItem('widgetsbarState') as keyof typeof widgetsbarWidths) || 'expanded'
  );
  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(dayjs());
  const [month, setMonth] = useState(dayjs().format('MMM'));
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);

  const [ScoutCalendarState, dispatch] = useReducer(
    ScoutCalendarReducer,
    InitialScoutCalendarState
  );
  const { weekStart, showCreateEventModal, createMultipleEvents } = ScoutCalendarState;
  const [eventCreated, setEventCreated] = useState<boolean>(false);

  const { data: events } = useEvents({
    weekStart,
    onError: (error: string) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `There was an error getting events, ${error}`, state: 'error' }
      }),
    apiKey
  });

  const handleDateClick = (date: Dayjs | null) => {
    if (calendlyEnabled) {
      return;
    }
    window.location.href = `${BASE_URL}/admin/calendar/show/${date?.format('YYYY-MM-DD')}`;
  };

  const handleAddEvent = (type: EventActionType) => {
    if (calendlyEnabled) {
      window.location.href = `${BASE_URL}/admin/calendly`;
      return;
    }
    const actions: Record<EventActionType, ScoutCalendarAction> = {
      single: { type: 'SET_SHOW_CREATE_EVENT_MODAL', payload: true },
      multiple: { type: 'CREATE_MULTIPLE_EVENTS', payload: true }
    };

    dispatch(actions[type]);
  };

  useEffect(() => {
    const calendarRegex = /\/admin\/calendar\/show\/.*/;
    if (calendarRegex.test(window.location.pathname)) {
      const url = window.location.pathname.split('/');
      const date = dayjs(url[url.length - 1]);
      setSelectedDate(date);
      setMonth(date.format('MMM'));
    }

    const handleResize = () => {
      if (window.innerWidth < 866) {
        setWidgetsbarState('collapsed');
        localStorage.setItem('widgetsbarState', 'collapsed');
        const event = new Event('onWidgetBarChange');
        window.dispatchEvent(event);
      }
    };

    !localStorage.getItem('widgetsbarState') && handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    dispatch({ type: 'SET_APIKEY', payload: apiKey });
  }, [apiKey]);

  return (
    <QueryClientProvider client={queryClient}>
      <>
        <Stack
          id="react-widgetsbar"
          ref={scrollRef}
          spacing={3}
          alignItems="center"
          paddingInline={widgetsContainerPadding[widgetsbarState]}
          sx={{
            ...classes.widgetsbarContainer,
            width: `${widgetsbarWidths[widgetsbarState]}px`,
            '&::-webkit-scrollbar': {
              display: 'none'
            },
            display: ENGAGE_URL ? 'none' : 'flex'
          }}
        >
          <Box
            sx={{
              ...classes.hideButtonContainer,
              padding: widgetsbarState === 'collapsed' ? '0 !important' : '0 12px 30px'
            }}
          >
            <ShowRightMenu
              widgetsbarState={widgetsbarState}
              setWidgetsbarState={setWidgetsbarState}
            />
          </Box>
          {widgetsbarState === 'expanded' && (
            <>
              <Box sx={classes.datePicker}>
                <Stack alignItems="center" sx={classes.addButton}>
                  <IconButton
                    aria-label="Add new action button"
                    onClick={(e) => {
                      setActionsAnchorEl(e.currentTarget);
                    }}
                    sx={{ padding: 0 }}
                    disableRipple
                  >
                    <AddCircleIcon sx={{ color: '#666666', fontSize: '20px !important' }} />
                  </IconButton>
                </Stack>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <StaticDatePicker
                    displayStaticWrapperAs="desktop"
                    value={selectedDate}
                    onChange={(newValue) => {
                      setSelectedDate(newValue);
                      handleDateClick(newValue);
                    }}
                    onMonthChange={(month) => setMonth(month.format('MMM'))}
                    slotProps={{
                      layout: { sx: { minWidth: '260px', height: '300px' } },
                      day: { sx: { width: '30px' } },
                      calendarHeader: {
                        ref(instance) {
                          if (!instance) return;
                          const year = selectedDate?.format('YYYY');
                          instance.childNodes[0].textContent = `${month} ${year}`;
                        }
                      }
                    }}
                  />
                </LocalizationProvider>
              </Box>
              <Popover
                id={actionsAnchorEl ? 'actions-menu-popover' : undefined}
                sx={classes.actionsMenu}
                open={Boolean(actionsAnchorEl)}
                anchorEl={actionsAnchorEl}
                onClose={() => {
                  setActionsAnchorEl(null);
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <span
                  onClick={() => {
                    setActionsAnchorEl(null);
                    handleAddEvent('single');
                  }}
                  className="add-event-button"
                >
                  Add event
                </span>
                <span
                  onClick={() => {
                    setActionsAnchorEl(null);
                    handleAddEvent('multiple');
                  }}
                  className="add-multiple-event-button"
                >
                  Add multiple events
                </span>
              </Popover>
            </>
          )}
          {widgetsbarState === 'expanded' && <RecentlyViewed />}
          {userPermissions.includes('View activity feed') && widgetsbarState === 'expanded' && (
            <ActivityFeed parentRef={scrollRef} />
          )}
        </Stack>

        {(showCreateEventModal || createMultipleEvents) && events && (
          <CreateEvent
            events={events}
            ScoutCalendarState={ScoutCalendarState}
            dispatch={dispatch}
            setEventCreated={setEventCreated}
            userTimezone={userTimezone}
          />
        )}
        <EventCreated
          open={eventCreated}
          onClose={() => {
            setEventCreated(false);
            dispatch({ type: 'RESET_STATE' });
          }}
          ScoutCalendarState={ScoutCalendarState}
          userTimezone={userTimezone}
        />
      </>
    </QueryClientProvider>
  );
};

export default Widgetsbar;
