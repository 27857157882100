import React, { Dispatch, useState } from 'react';
import { Stack, Avatar, Divider } from '@mui/material';
import { Edit as EditIcon, Circle as CircleIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { classes } from './styles';
import { IAttendee, IEvent, ScoutCalendarAction, ScoutCalendarState } from './types';
import dayjs from 'dayjs';
import GroupedAvatars from './CreateEvent/EventProgress/GroupedAvatars';
import GenericDialog from '../NewUI/Components/Modals/GenericDialog';
import { useDeleteEvent } from './helper';

export default function ListView({
  events,
  handleSelectEvent,
  ScoutCalendarState,
  dispatch
}: {
  events: IEvent[];
  handleSelectEvent: (event: IEvent, withoutModal?: boolean) => void;
  ScoutCalendarState: ScoutCalendarState;
  dispatch: Dispatch<ScoutCalendarAction>;
}) {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const { createEventObject } = ScoutCalendarState;

  const onClose = () => {
    dispatch({ type: 'RESET_STATE' });
    setOpenConfirmationDialog(false);
  };

  const { deleteEvent, deletingEvent } = useDeleteEvent({
    events,
    ScoutCalendarState,
    dispatch,
    onClose
  });

  return (
    <Stack sx={{ paddingTop: 2, rowGap: 4 }}>
      {events.map((event, index) => {
        const recruiters = event.attendees.filter((a) => a.type === 'User');
        const candidates = event.attendees.filter((a) => a.type === 'Candidate');
        const nextEventIsOnSameDate =
          index + 1 !== events.length &&
          dayjs(event.start).format('L') === dayjs(events[index + 1].start).format('L');
        const previousEventIsOnDifferentDate =
          index === 0 ||
          dayjs(event.start).format('L') !== dayjs(events[index - 1].start).format('L');

        const getAttendees = (attendees: IAttendee[]) =>
          attendees.map((r) => ({ id: r.id, name: r.name, state: r.state }));

        return (
          <Stack key={event.id}>
            {previousEventIsOnDifferentDate && (
              <Stack sx={{ color: '#333333', paddingBottom: 3, fontWeight: 600, fontSize: '16px' }}>
                {dayjs(event.start).format('ddd, D MMM YYYY')}
              </Stack>
            )}
            <Stack flexDirection="row" columnGap={2}>
              <Stack>
                <Avatar sx={{ background: '#EDF2F5', width: 32, height: 32 }}>
                  <CircleIcon sx={classes.listViewCircleIcon} />
                </Avatar>
                {nextEventIsOnSameDate && (
                  <Divider
                    orientation="vertical"
                    sx={{ width: '48%', borderRight: '2px solid #E3E3E3' }}
                  />
                )}
              </Stack>
              <Stack sx={{ rowGap: 1, flexGrow: 1, paddingTop: 0.5 }}>
                <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Stack
                    id="event-time-link"
                    sx={classes.listViewEventTime}
                    onClick={() => handleSelectEvent(event)}
                  >
                    {dayjs(event.start).format('LT')} - {dayjs(event.end).format('LT')}
                  </Stack>

                  <Stack sx={{ flexDirection: 'row', columnGap: 1 }}>
                    <EditIcon
                      id="edit-event-list-view-button"
                      fontSize="small"
                      onClick={() => handleSelectEvent(event)}
                      sx={{ color: '#666666', cursor: 'pointer' }}
                    />
                    <DeleteIcon
                      id="delete-event-list-button"
                      fontSize="small"
                      sx={{ color: '#E37D7A', cursor: 'pointer' }}
                      onClick={() => {
                        handleSelectEvent(event, true);
                        setOpenConfirmationDialog(true);
                      }}
                    />
                  </Stack>
                </Stack>
                <Stack flexDirection="row">
                  <Stack
                    id="event-name-link"
                    sx={classes.listViewEventTitle}
                    onClick={() => handleSelectEvent(event)}
                  >
                    {event.subject}
                  </Stack>
                </Stack>
                <Stack sx={{ flexDirection: 'row', columnGap: 2 }}>
                  <Stack sx={classes.listViewInfoTag}>
                    <Stack sx={{ fontWeight: 'bold' }}>Created: </Stack>
                    <Stack sx={{ fontWeight: 600 }}>
                      {dayjs(event.created_at).format('D MMM YYYY, LT')}
                    </Stack>
                  </Stack>
                  <Stack sx={classes.listViewInfoTag}>
                    <Stack sx={{ fontWeight: 'bold' }}>Duration: </Stack>
                    <Stack sx={{ fontWeight: 600 }}>{event.duration}</Stack>
                  </Stack>
                </Stack>
                <Stack sx={{ flexDirection: 'row', padding: '16px 0px' }}>
                  <Stack sx={{ width: '25%' }}>
                    <Stack sx={classes.listViewSectionTitle}>Recruiters</Stack>
                    <GroupedAvatars users={getAttendees(recruiters)} />
                  </Stack>
                  <Stack>
                    <Stack sx={classes.listViewSectionTitle}>Candidates</Stack>
                    {candidates.length ? (
                      <GroupedAvatars users={getAttendees(candidates)} />
                    ) : (
                      <Stack sx={classes.listViewNoCandidatesAssigned}>
                        No Candidates Assigned
                      </Stack>
                    )}
                  </Stack>
                </Stack>
                {event.note && (
                  <Stack sx={{ overflowWrap: 'anywhere' }}>
                    <Stack sx={classes.listViewSectionTitle}>Description</Stack>
                    {event.note}
                  </Stack>
                )}
              </Stack>
            </Stack>
          </Stack>
        );
      })}
      <GenericDialog
        isDialogOpen={openConfirmationDialog}
        setDialogOpen={setOpenConfirmationDialog}
        title="Delete this event?"
        description={`Are you sure you want to delete the event "${createEventObject.eventName}"?`}
        buttonCallback={() => deleteEvent()}
        callbackLoading={deletingEvent}
        buttonText="Delete"
        url=""
        primaryButtonId="confirm-delete-event-list-view-button"
        secondaryButtonId="cancel-delete-event-list-view-button"
      />
    </Stack>
  );
}
