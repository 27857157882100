import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Widgetsbar from './Widgetsbar';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export interface WidgetsbarWrapperProps {
  userPermissions: string;
  calendlyEnabled: boolean;
  apiKey: string;
  userTimezone: string;
}

export default function WidgetsbarWrapper({
  userPermissions,
  calendlyEnabled,
  apiKey,
  userTimezone
}: WidgetsbarWrapperProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <Widgetsbar
        apiKey={apiKey}
        userPermissions={userPermissions}
        calendlyEnabled={calendlyEnabled}
        userTimezone={userTimezone}
      />
    </QueryClientProvider>
  );
}
