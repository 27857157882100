import React, { PropsWithChildren, ReactNode, useState } from 'react';
import {
  AvatarGroup,
  Avatar,
  Popover,
  Stack,
  Badge,
  Typography,
  SxProps,
  Tooltip
} from '@mui/material';
import { classes } from './styles';
import { AcceptedIcon, DeclinedIcon } from '../../../../components/NewUI/Components/Icons';
import { IAttendee } from '../../types';
import { theme } from '../../../ThemeContext/ThemeObject';

const MAX_VISIBLE_AVATARS = 4;

type AvatarProps = Pick<IAttendee, 'id' | 'name' | 'state'>;

const getInitial = (name: string) => {
  if (name.toLowerCase() === 'me') return name;
  return name
    .trim()
    .split(' ')
    .map((x) => x[0])
    .reduce((acc, curr) => acc + curr)
    .slice(0, 2);
};

const statusIconMap: Record<string, JSX.Element> = {
  accepted: <AcceptedIcon />,
  declined: <DeclinedIcon />
};

const getIcon = (state?: string): JSX.Element | null => {
  if (!state) return null;
  return statusIconMap[state] || null;
};

const CustomBadge = ({
  badgeContent,
  sx,
  children
}: PropsWithChildren<{
  badgeContent: ReactNode;
  sx: SxProps;
}>) => (
  <Badge
    overlap="circular"
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    badgeContent={badgeContent}
    sx={sx}
  >
    {children}
  </Badge>
);

const renderAvatar = (user: { id: number; name: string; state?: string }) => {
  return (
    <CustomBadge
      badgeContent={getIcon(user.state)}
      sx={{ '& .MuiBadge-badge': { bottom: '7% !important' } }}
    >
      <Tooltip key={user.id} title={user.name} placement="top" arrow>
        <Avatar sx={{ ...classes.avatar }}>{getInitial(user.name).toUpperCase()}</Avatar>
      </Tooltip>
    </CustomBadge>
  );
};
export default function GroupedAvatars({ users }: { users: AvatarProps[] }) {
  const [avatarAnchorEl, setAvatarAnchorEl] = useState<HTMLDivElement | null>(null);

  return (
    <>
      <AvatarGroup sx={{ justifyContent: 'start', marginLeft: '8px' }}>
        {users.length <= MAX_VISIBLE_AVATARS ? (
          users.slice(0, MAX_VISIBLE_AVATARS).map((r) => renderAvatar(r))
        ) : (
          <Stack flexDirection="row-reverse">
            <Avatar
              sx={{
                ...classes.avatar,
                background: theme.palette.common.white,
                border: '2px solid #084D6D !important',
                cursor: 'pointer'
              }}
              onClick={(e) => setAvatarAnchorEl(e.currentTarget)}
            >{`+${users.length - 3}`}</Avatar>
            {users
              .slice(0, 3)
              .reverse()
              .map((r) => renderAvatar(r))}
          </Stack>
        )}
      </AvatarGroup>
      <Popover
        open={Boolean(avatarAnchorEl)}
        anchorEl={avatarAnchorEl}
        onClose={() => setAvatarAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        sx={{ '& .MuiPaper-root': { marginTop: 1 } }}
      >
        <Stack sx={{ padding: '8px 16px', rowGap: '4px' }}>
          {users.slice(3).map((r) => (
            <Stack key={r.id} sx={{ flexDirection: 'row', columnGap: 2, alignItems: 'center' }}>
              <Typography>{r.name}</Typography>
              {r.state && r.state !== 'invited' && (
                <CustomBadge
                  badgeContent={getIcon(r.state)}
                  sx={{ '& .MuiBadge-badge': { bottom: '-2px !important' } }}
                />
              )}
            </Stack>
          ))}
        </Stack>
      </Popover>
    </>
  );
}
