import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Pagination,
  MenuItem,
  FormControl,
  Select
} from '@mui/material';
import TableSkeleton from '../TableSkeleton';
import GenericDialog from '../GenericDialog';
import AdTable from './AdTable';
import { classes } from '../styles';
import { IJobAdIndeed } from '../types';
import FilterButton from '../FilterButton';
import AdTypePopover from '../AdTypePopover';

export default function IndeedAds({ apiKey, jobId }: { apiKey: string; jobId: string | null }) {
  const [ads, setAds] = useState(null);
  const [page, setPage] = useState(1);
  const [maxAds, setMaxAds] = useState(0);
  const [maxPages, setMaxPages] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedAdType, setSelectedAdType] = useState('active');
  const [loading, setLoading] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [filterAnchorEl, setFilterAnchorEl] = useState<HTMLElement | null>(null);
  const [errorModalOpen, setErrorModalOpen] = useState(false);

  const getInitialAds = useCallback(async () => {
    setLoading(true);
    const url = jobId
      ? `/api/v4/indeed/jobs/${jobId}/job_ads?limit=100`
      : '/api/v4/indeed/job_ads/active';
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      }).then(async (response) => {
        if (response.ok) {
          return {
            data: await response.json(),
            maxAds: Number(response.headers.get('X-Total-Count')),
            maxPages: Number(response.headers.get('X-Total-Pages'))
          };
        }
        return response.json().then((error) => {
          error.code = response.status;
          throw error;
        });
      });
      if (jobId !== null) {
        setAds(response.data.job_ads);
      } else {
        setAds(response.data);
        setMaxAds(response.maxAds);
        setMaxPages(response.maxPages);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [apiKey, jobId]);

  const getAds = async (currentType: string, currentPage: number, currentRows: number) => {
    if (jobId !== null) return;
    setLoading(true);
    const url = `/api/v4/indeed/job_ads/${currentType}?page=${currentPage}&limit=${currentRows}`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'X-api-authenticate': apiKey
        }
      }).then(async (response) => {
        if (response.ok) {
          return {
            data: await response.json(),
            maxAds: Number(response.headers.get('X-Total-Count')),
            maxPages: Number(response.headers.get('X-Total-Pages'))
          };
        }
        return response.json().then((error) => {
          error.code = response.status;
          throw error;
        });
      });
      setAds(response.data);
      setMaxAds(response.maxAds);
      setMaxPages(response.maxPages);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const createNewJob = useCallback(async () => {
    setLoadingCreate(true);
    try {
      const response = await fetch(`/api/v4/indeed/jobs/${jobId}/job_ads/init_indeed_ad`, {
        method: 'POST',
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      if (!response.ok) {
        setErrorModalOpen(true);
      } else {
        const data = await response.json();
        window.location.href = data.url;
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingCreate(false);
    }
  }, [apiKey, jobId]);

  const handleAdTypeChange = (adType: string) => {
    setFilterAnchorEl(null);
    if (adType === selectedAdType) return;
    setSelectedAdType(adType);
    setPage(1);
    getAds(adType, 1, rowsPerPage);
  };

  const handlePageChange = (event: React.ChangeEvent, newPage: number) => {
    if (newPage === page) return;
    setPage(newPage);
    getAds(selectedAdType, newPage, rowsPerPage);
  };

  const handleRowsPerPageChange = (selection: number) => {
    if (selection === rowsPerPage) return;
    setRowsPerPage(selection);
    getAds(selectedAdType, page, selection);
  };

  useEffect(() => {
    getInitialAds();
  }, [getInitialAds]);

  return (
    <Box sx={classes.containerOuter}>
      <Box sx={classes.container}>
        <Box sx={classes.banner}>
          <FilterButton
            selectedAdType={selectedAdType}
            setFilterAnchorEl={setFilterAnchorEl}
            classes={classes}
          />
          {jobId && (
            <Button
              disableElevation
              sx={{ ...classes.buttonSecondary, ...classes.newAdButton }}
              id="IndeedNewAdMultiposterBtn"
              onClick={createNewJob}
            >
              {loadingCreate ? <CircularProgress size={20} color="inherit" /> : 'New Ad'}
            </Button>
          )}
        </Box>
        {loading ? (
          <TableSkeleton />
        ) : (
          <AdTable
            jobAds={ads}
            ads={ads[selectedAdType as keyof IJobAdIndeed]}
            setAds={setAds}
            adsStatus={selectedAdType}
            isSingleJob={jobId !== null}
            apiKey={apiKey}
          />
        )}
        {!jobId && (
          <Box sx={classes.paginationParent}>
            <Box sx={classes.rowSelectContainer}>
              <span>Rows per page: </span>
              <FormControl>
                <Select
                  id="rows-per-page"
                  sx={classes.rowSelect}
                  value={rowsPerPage}
                  onChange={(e) => handleRowsPerPageChange(Number(e.target.value))}
                  displayEmpty
                  MenuProps={{ sx: classes.paginationMenuItems }}
                >
                  <MenuItem id="first-rpp-item" value={10}>
                    10
                  </MenuItem>
                  <MenuItem id="second-rpp-item" value={20}>
                    20
                  </MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={classes.paginationContainer}>
              <Pagination
                id="pagination-menu"
                count={maxPages}
                page={page}
                defaultPage={Math.ceil(maxPages / 2)}
                siblingCount={0}
                onChange={handlePageChange}
                sx={classes.pagination}
              />
              <span id="total-jobs">{maxAds} Total</span>
            </Box>
          </Box>
        )}
      </Box>
      <AdTypePopover
        filterAnchorEl={filterAnchorEl}
        setFilterAnchorEl={setFilterAnchorEl}
        selectedAdType={selectedAdType}
        handleAdTypeChange={handleAdTypeChange}
        classes={classes}
        adType="indeed"
      />
      <GenericDialog
        url=""
        callbackLoading={false}
        dialogAdID={0}
        setDialogOpen={setErrorModalOpen}
        isDialogOpen={errorModalOpen}
        buttonCallback={() => setErrorModalOpen(false)}
        title="Unable to create ad"
        description="Ads can only be created on jobs with a published state"
        buttonText="Ok"
      />
    </Box>
  );
}
