import React, { Dispatch, useRef, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  FormControlLabel,
  Stack,
  Switch,
  TextField
} from '@mui/material';
import { NavigateBefore as NavigateBeforeIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { ControlledStaticDatePicker } from '../../Components/CustomUIElements/SingleDatePicker';
import { sharedClasses } from '../../Components/CustomUIElements/sharedClasses';
import { classes } from '../../Job/Applications/styles';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import API from '../API';
import dayjs from 'dayjs';
import { ApplicationAction, ApplicationState, IApplication } from '../types';
import { checkMissingPlaceholders, INVITE_TO_TIMESLOT_PLACEHOLDER, ModalType } from '../config';
import StyledModal from '../../Components/GenericModal/StyledModal';
import { styles } from './styles';
import SmsTemplateComponent from '../../Components/CustomUIElements/SmsTemplateComponent';
import { useGetEmailTemplate, useGetEvents, useGetSmsTemplatePlaceholders } from '../helper';
import EmailsDropdown from './Components/EmailsDropdown';
import EmailBodyComponent from './Components/EmailBodyComponent';
import NotificationOptionSwitch from './Components/NotificationOptionsSwitch';
import { IEventType, IRecruiter } from '../../../ScoutCalendar/types';
import { scrollToElement } from '../../utils/scroll-to-element';
import { MissingPlaceholdersConfirmationModal } from './MissingPlaceholdersConfirmationModal';
import { ISMSTemplate } from '../../Job/types';
import { useDispatch } from '../../hooks/useDispatch';

export default function InviteToTimeslot({
  ApplicationState,
  dispatch,
  smsTemplatePermissions
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
  smsTemplatePermissions: boolean;
}) {
  const [recruiter, setRecruiter] = useState<IRecruiter | null>(null);
  const [eventType, setEventType] = useState<IEventType | null>(null);
  const [showTimeslots, setShowTimeslots] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<Date | null>(new Date());
  const [step, setStep] = useState<number>(1);
  const [notification, setNotification] = useState<string>('email');
  const [smsMessage, setSmsMessage] = useState<string>('');
  const [smsTemplate, setSmsTemplate] = useState<ISMSTemplate | null>(null);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<string | null>(null);
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [missingPlaceholderConfirmation, setMissingPlaceholderConfirmation] =
    useState<boolean>(false);
  const fieldRefs = {
    emailTemplate: useRef<HTMLInputElement>(null),
    body: useRef<HTMLDivElement>(null)
  };
  const { modalsOpen, apiKey } = ApplicationState;

  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  useGetEmailTemplate({ apiKey, selectedEmailTemplate, setBody, dispatch, setSubject });
  const { events, loadingEvents } = useGetEvents({
    apiKey,
    dispatch,
    eventTypeId: eventType?.id,
    enabled: !!eventType
  });
  const { placeholders } = useGetSmsTemplatePlaceholders({ kind: 'invite', dispatch });

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });
  const { displayError, displaySuccess } = useDispatch(dispatch);

  const { data: recruiters, isLoading: loadingRecruiters } = useQuery({
    queryKey: ['recruiters'],
    queryFn: async () => {
      const { res } = await API.getRecruiters(apiKey);
      return res.users;
    },
    onError: () => displayError('Failed to fetch recruiters data.')
  });

  const { data: eventTypes, isLoading: loadingEventTypes } = useQuery({
    queryKey: ['event types'],
    queryFn: async () => {
      const { res } = await API.getEventTypes();
      return res.event_types;
    },
    onError: () => displayError('Failed to fetch event types data.')
  });

  const { mutate: sendEmail, isLoading: sendingEmail } = useMutation({
    mutationFn: async () => {
      if (!recruiter || !eventType || !application || !selectedEmailTemplate) return;
      const res = await API.inviteByEmail(apiKey, {
        user_id: recruiter.id,
        event_type_id: eventType.id,
        job_id: application.job.id,
        invitation: {
          application_ids: [application.id],
          subject: subject,
          body: body,
          email_template_id: selectedEmailTemplate.split(' -')[0]
        }
      });
      return res;
    },
    onSuccess: () => {
      displaySuccess('Invite to timeslot email notification has been sent successfully');
      handleClose();
    },
    onError: () => displayError('Failed to send invite to timeslot email')
  });

  const { mutate: sendSms, isLoading: sendingSms } = useMutation({
    mutationFn: async () => {
      if (!recruiter || !eventType || !application || !smsMessage) return;

      const res = await API.sendInviteSms(
        application.job.id,
        eventType.id,
        [application.id],
        smsMessage,
        smsTemplate?.id
      );
      if (res.success !== 0) {
        displaySuccess('Invite to timeslot SMS notification has been sent successfully');
        handleClose();
      } else {
        displayError('There is an error in sending SMS notification');
      }
      return res;
    }
  });

  const selectedDayEvents = events?.filter(
    (e) => e.date === dayjs(selectedDay).format('YYYY-MM-DD')
  );

  const shouldDisableDate = (date: Date) => {
    if (!events) return true;
    return !events
      .map((e) => dayjs(e.begins_at).format('YYYY-MM-DD'))
      .includes(dayjs(date).format('YYYY-MM-DD'));
  };

  const buttonName = step === 1 ? 'Next' : 'Send';

  const validateInput = () => {
    const fieldsErrors: Record<string, string> = {};
    if (step === 1) {
      !recruiter && (fieldsErrors.recruiter = 'Must select a recruiter');
      !eventType && (fieldsErrors.eventType = 'Must select an event type');
    } else {
      if (notification === 'email') {
        !selectedEmailTemplate && (fieldsErrors.template = 'Must select a template');
        !body.trim() && (fieldsErrors.body = 'Must add email body');
      } else {
        !smsMessage.trim() && (fieldsErrors.sms = 'Must add SMS content');
      }
    }
    setErrors(fieldsErrors);
    if (fieldsErrors.template) {
      scrollToElement(fieldRefs.emailTemplate);
    } else if (fieldsErrors.body) {
      scrollToElement(fieldRefs.body);
    }
    return !Object.keys(fieldsErrors).length;
  };

  const handleEmailSubmission = () => {
    const missingPlaceholders = checkMissingPlaceholders(
      [INVITE_TO_TIMESLOT_PLACEHOLDER],
      body.trim()
    );
    validateInput() &&
      (missingPlaceholders ? setMissingPlaceholderConfirmation(true) : sendEmail());
  };
  const handleSubmit = () => {
    if (step === 1) {
      validateInput() && setStep(2);
    } else {
      notification === 'email' ? handleEmailSubmission() : validateInput() && sendSms();
    }
  };

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.INVITE_TO_TIMESLOT}
      label="Invite to timeslot modal"
      handleClose={handleClose}
      styleOverrides={{ ...styles.modalStyleOverrides, width: '900px' }}
      disableEnforceFocus
    >
      <Stack sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
        <Stack
          id="invite-to-timeslot-title"
          sx={{ ...classes.actionModalHeader, paddingBottom: 3 }}
        >
          Invite to timeslot
        </Stack>
        {step === 1 ? (
          <Stack sx={{ padding: 1, flexGrow: 1, rowGap: 3, overflow: 'auto' }}>
            <Autocomplete
              disablePortal
              options={recruiters || []}
              getOptionLabel={(option) => option.name}
              value={recruiter}
              sx={{ ...sharedClasses.formAutocomplete, width: '40%', marginTop: 3 }}
              loading={loadingRecruiters}
              loadingText="Loading recruiters list..."
              ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Recruiter"
                  placeholder="Please select"
                  InputLabelProps={{ shrink: true }}
                  required
                  sx={sharedClasses.autoCompleteTextfield}
                  helperText={errors.recruiter}
                />
              )}
              onChange={(event, value) => setRecruiter(value)}
            />
            <Autocomplete
              disablePortal
              options={eventTypes || []}
              getOptionLabel={(option) => option.name}
              value={eventType}
              sx={{ ...sharedClasses.formAutocomplete, width: '40%', marginTop: 3 }}
              loading={loadingEventTypes}
              loadingText="Loading event types list..."
              ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Event type"
                  placeholder="Please select"
                  InputLabelProps={{ shrink: true }}
                  required
                  sx={sharedClasses.autoCompleteTextfield}
                  helperText={errors.eventType}
                />
              )}
              onChange={(event, value) => setEventType(value)}
            />
            <FormControlLabel
              sx={classes.formSwitchLabels}
              control={
                <Switch
                  sx={showTimeslots ? classes.switchActive : classes.switch}
                  checked={showTimeslots}
                  onChange={() => setShowTimeslots(!showTimeslots)}
                />
              }
              label="Show timeslots"
              labelPlacement="start"
            />
            <Collapse in={showTimeslots}>
              <Stack
                sx={{
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <Box sx={classes.calendar}>
                  <ControlledStaticDatePicker
                    displayStaticWrapperAs="desktop"
                    views={['day']}
                    value={selectedDay}
                    disablePast
                    onChange={(value) => setSelectedDay(value)}
                    shouldDisableDate={shouldDisableDate}
                  />
                </Box>
                <Box sx={classes.dailyTimeslots}>
                  <Box sx={{ ...classes.dailyTimeslotsHeader, marginTop: 2, marginBottom: '20px' }}>
                    Timeslots
                  </Box>
                  {eventType ? (
                    <>
                      {loadingEvents ? (
                        <Box sx={classes.noTimeslots}>
                          <CircularProgress size={32} />
                        </Box>
                      ) : (
                        <Stack sx={styles.timeslotsContainer}>
                          {selectedDayEvents?.length ? (
                            selectedDayEvents?.map((e) => {
                              const candidates = e.attendees.filter((a) => a.type === 'Candidate');
                              const fullyBooked = candidates.length === e.max_candidates;
                              return (
                                <Stack
                                  key={e.id}
                                  sx={{
                                    ...styles.timeslot,
                                    color: `${fullyBooked ? '#D9D9D9' : '#666666'}`,
                                    background: `${fullyBooked ? '#F2F2F2' : 'inherit'}`
                                  }}
                                >
                                  <Stack sx={{ fontSize: '14px' }}>
                                    {dayjs(e.begins_at).format('LT')}
                                  </Stack>
                                  <Box sx={styles.timeslotSubject}>{e.subject}</Box>
                                </Stack>
                              );
                            })
                          ) : (
                            <Stack sx={styles.noTimeslotMessage}>
                              No timeslots on selected date
                            </Stack>
                          )}
                        </Stack>
                      )}
                    </>
                  ) : (
                    <Stack sx={styles.noTimeslotMessage}>Event type must be selected</Stack>
                  )}
                </Box>
              </Stack>
            </Collapse>
          </Stack>
        ) : (
          <Stack sx={classes.addToEventSecondStepContainer}>
            <NotificationOptionSwitch
              setNotification={setNotification}
              notification={notification}
            />
            {notification === 'email' ? (
              <Stack sx={{ paddingTop: 4, rowGap: 3 }}>
                <EmailsDropdown
                  apiKey={apiKey}
                  selectedEmailTemplate={selectedEmailTemplate}
                  setSelectedEmailTemplate={setSelectedEmailTemplate}
                  setBody={setBody}
                  dispatch={dispatch}
                  required
                  error={errors.template}
                  innerRef={fieldRefs.emailTemplate}
                />
                <EmailBodyComponent
                  body={body}
                  setBody={setBody}
                  error={errors.body}
                  innerRef={fieldRefs.body}
                />
              </Stack>
            ) : (
              <SmsTemplateComponent
                smsMessageError={errors.sms}
                {...{
                  smsTemplatePermissions,
                  smsMessage,
                  setSmsMessage,
                  smsTemplate,
                  setSmsTemplate,
                  dispatch,
                  placeholders
                }}
              />
            )}
          </Stack>
        )}
        <Box sx={{ ...classes.modalFooter, columnGap: 1, paddingTop: 2 }}>
          <Button
            id="close-invite-to-timeslot-modal-button"
            sx={classes.modalCancelButton}
            onClick={handleClose}
          >
            Cancel
          </Button>
          {step !== 1 && (
            <Button
              onClick={() => {
                setStep(1);
                setErrors({});
              }}
              sx={{ ...sharedClasses.genericButtonSecondary, minWidth: 'unset', padding: 1 }}
            >
              <NavigateBeforeIcon />
            </Button>
          )}
          <Button
            id="invite-to-timeslot-submit-button"
            type="submit"
            sx={{ ...sharedClasses.genericButton, minWidth: '75px' }}
            onClick={handleSubmit}
          >
            {sendingSms || sendingEmail ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              buttonName
            )}
          </Button>
        </Box>
      </Stack>
      {missingPlaceholderConfirmation && (
        <MissingPlaceholdersConfirmationModal
          missingPlaceholderList={INVITE_TO_TIMESLOT_PLACEHOLDER}
          overrideFunction={sendEmail}
          missingPlaceholderConfirmation={missingPlaceholderConfirmation}
          setMissingPlaceholderConfirmation={setMissingPlaceholderConfirmation}
          overridingLoading={sendingEmail}
        />
      )}
    </StyledModal>
  );
}
