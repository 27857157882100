var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
import React from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  FormControl,
  FormLabel,
  FormHelperText,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormGroup,
  Checkbox,
  Grid,
  InputAdornment
} from "@material-ui/core";
import sharedStyles from "../../../../../IndeedIntegration/AdEditor/useStyles";
import usestyles from "../useStyles";
import { jobAdPropTypes } from "../../../../../NewUI/Components/Multiposter/types";
import ErrorIcon from "@material-ui/icons/Error";
import AddressInput from "../../../../../NewUI/Components/CustomUIElements/AddressInput";
import { parseSalary } from "../../salaryHelper";
function Step1({ answers, setAnswers, jobAdParams, requiredRefs, activeError }) {
  const [value, setValue] = React.useState({
    description: [answers.address].filter((cur) => cur).join(", ")
  });
  const sharedClasses = sharedStyles();
  const classes = usestyles();
  function handleMultiCheck(value2, state, key) {
    state.includes(value2) ? setAnswers(__spreadProps(__spreadValues({}, answers), { [key]: state.filter((id) => id !== value2) })) : setAnswers(__spreadProps(__spreadValues({}, answers), { [key]: [...answers[key], value2] }));
  }
  return /* @__PURE__ */ React.createElement("div", { className: sharedClasses.stepContainer }, /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.title,
      fullWidth: true,
      className: `${sharedClasses.formControl} ${sharedClasses.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: sharedClasses.label }, "Job Title"),
    /* @__PURE__ */ React.createElement(
      TextField,
      {
        error: activeError === requiredRefs.title,
        className: sharedClasses.textField,
        variant: "outlined",
        value: answers.title,
        onChange: (e) => {
          const newValue = e.target.value;
          if (newValue.length <= 52 && newValue !== answers.title) {
            setAnswers(__spreadProps(__spreadValues({}, answers), { title: newValue }));
          }
        },
        InputProps: {
          endAdornment: activeError === requiredRefs.title && /* @__PURE__ */ React.createElement("div", { className: sharedClasses.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
        }
      }
    ),
    /* @__PURE__ */ React.createElement(FormHelperText, { className: sharedClasses.helperText }, answers.title.length, "/52")
  ), /* @__PURE__ */ React.createElement(
    AddressInput,
    {
      requiredRef: requiredRefs.address,
      classes: sharedClasses,
      value,
      setValue,
      activeError: activeError === requiredRefs.address,
      answers,
      setAnswers,
      viewJobsAd: true
    }
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.classification,
      fullWidth: true,
      className: `${sharedClasses.formControl} ${sharedClasses.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: sharedClasses.label }, "Classification"),
    /* @__PURE__ */ React.createElement(
      Select,
      {
        classes: { select: sharedClasses.input },
        variant: "outlined",
        value: answers.classification,
        onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { classification: e.target.value })),
        displayEmpty: true,
        renderValue: (value2) => value2 || /* @__PURE__ */ React.createElement("span", { className: sharedClasses.placeholder }, "Select an option"),
        endAdornment: activeError === requiredRefs.classification && /* @__PURE__ */ React.createElement("div", { className: sharedClasses.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))
      },
      jobAdParams.classifications.map((classification) => /* @__PURE__ */ React.createElement(MenuItem, { key: classification[1], value: classification[0] }, classification[0]))
    )
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.workType,
      fullWidth: true,
      className: `${sharedClasses.formControl}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: sharedClasses.label }, "Work type", activeError === requiredRefs.workType && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))),
    /* @__PURE__ */ React.createElement(
      RadioGroup,
      {
        value: answers.workType,
        onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { workType: e.target.value }))
      },
      jobAdParams.worktypes.map((type) => /* @__PURE__ */ React.createElement(
        FormControlLabel,
        {
          key: type[1],
          label: type[0],
          value: type[0],
          control: /* @__PURE__ */ React.createElement(Radio, { color: "secondary" })
        }
      ))
    )
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.workLocation,
      fullWidth: true,
      className: `${sharedClasses.formControl}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: sharedClasses.label }, "Work location", activeError === requiredRefs.workLocation && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))),
    /* @__PURE__ */ React.createElement(
      RadioGroup,
      {
        value: answers.workLocation,
        onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { workLocation: e.target.value }))
      },
      jobAdParams.worklocations.map((location) => /* @__PURE__ */ React.createElement(
        FormControlLabel,
        {
          key: location[1],
          label: location[0],
          value: location[0],
          control: /* @__PURE__ */ React.createElement(Radio, { color: "secondary" })
        }
      ))
    )
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.payType,
      fullWidth: true,
      className: `${sharedClasses.formControl}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { required: true, className: sharedClasses.label }, "Pay details", activeError === requiredRefs.payType && /* @__PURE__ */ React.createElement("div", { className: classes.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))),
    /* @__PURE__ */ React.createElement(
      RadioGroup,
      {
        value: answers.payType,
        onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { payType: e.target.value }))
      },
      jobAdParams.paytypes.map((type) => /* @__PURE__ */ React.createElement(
        FormControlLabel,
        {
          key: type[1],
          label: type[0],
          value: type[0],
          control: /* @__PURE__ */ React.createElement(Radio, { color: "secondary" })
        }
      ))
    )
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      ref: requiredRefs.salary,
      fullWidth: true,
      className: `${sharedClasses.formControl} ${sharedClasses.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: sharedClasses.label }, "Pay Range", /* @__PURE__ */ React.createElement("span", { className: sharedClasses.asterisk }, " *"), activeError === requiredRefs.salary && /* @__PURE__ */ React.createElement("div", { className: sharedClasses.error }, /* @__PURE__ */ React.createElement(ErrorIcon, { color: "error" }), /* @__PURE__ */ React.createElement("span", null, "This field is required"))),
    /* @__PURE__ */ React.createElement("span", { className: sharedClasses.subLabel }, "Enter a pay range to offer candidates"),
    /* @__PURE__ */ React.createElement(Grid, { container: true, classes: { root: sharedClasses.controlledNumberInput } }, /* @__PURE__ */ React.createElement(
      Grid,
      {
        item: true,
        container: true,
        alignContent: "center",
        alignItems: "center",
        justifyContent: "center",
        wrap: "nowrap"
      },
      /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement(Box, { m: 1, ml: 0 }, /* @__PURE__ */ React.createElement(
        TextField,
        {
          error: activeError === requiredRefs.salary,
          className: sharedClasses.textField,
          variant: "outlined",
          onBlur: () => {
            if (answers.payRangeLow > answers.payRangeHigh)
              setAnswers(__spreadProps(__spreadValues({}, answers), { payRangeHigh: answers.payRangeLow }));
          },
          value: Number(answers.payRangeLow).toLocaleString(),
          InputProps: {
            startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, "From")
          },
          onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { payRangeLow: parseSalary(e.target.value) }))
        }
      )), /* @__PURE__ */ React.createElement(Box, { m: 1 }, /* @__PURE__ */ React.createElement(
        TextField,
        {
          error: activeError === requiredRefs.salary,
          className: sharedClasses.textField,
          variant: "outlined",
          value: Number(answers.payRangeHigh).toLocaleString(),
          onBlur: () => {
            if (answers.payRangeHigh < answers.payRangeLow)
              setAnswers(__spreadProps(__spreadValues({}, answers), { payRangeHigh: answers.payRangeLow }));
          },
          InputProps: {
            startAdornment: /* @__PURE__ */ React.createElement(InputAdornment, { position: "start" }, "To")
          },
          onChange: (e) => setAnswers(__spreadProps(__spreadValues({}, answers), { payRangeHigh: parseSalary(e.target.value) }))
        }
      )), /* @__PURE__ */ React.createElement("span", { className: classes.salaryIntervalLabel }, "per ", answers.payType === "Annual salary" ? "Year" : "Hour"))
    ))
  ), /* @__PURE__ */ React.createElement(
    FormControl,
    {
      fullWidth: true,
      className: `${sharedClasses.formControl} ${sharedClasses.medium}`
    },
    /* @__PURE__ */ React.createElement(FormLabel, { focused: false, className: sharedClasses.label }, "Benefits"),
    /* @__PURE__ */ React.createElement(FormGroup, null, /* @__PURE__ */ React.createElement(Grid, { container: true }, jobAdParams.extrabenefits.map((benefit) => {
      var _a;
      return /* @__PURE__ */ React.createElement(Grid, { key: benefit[1], item: true, xs: 6 }, /* @__PURE__ */ React.createElement(
        FormControlLabel,
        {
          label: benefit[0],
          value: benefit[0],
          control: /* @__PURE__ */ React.createElement(
            Checkbox,
            {
              checked: (_a = answers == null ? void 0 : answers.benefits) == null ? void 0 : _a.includes(benefit[0]),
              onChange: (e) => handleMultiCheck(e.target.value, answers.benefits, "benefits")
            }
          )
        }
      ));
    })))
  ));
}
Step1.propTypes = jobAdPropTypes;
export default Step1;
