import React from 'react';
import { Popover } from '@mui/material';

interface AdTypePopoverProps {
  filterAnchorEl: null | HTMLElement;
  setFilterAnchorEl: (el: HTMLElement | null) => void;
  selectedAdType: string;
  handleAdTypeChange: (id: string) => void;
  classes: any;
  adType: string;
}

const ADTYPES = [
  {
    id: 'active',
    name: 'Active'
  },
  {
    id: 'draft',
    name: 'Draft'
  },
  {
    id: 'expired',
    name: 'Expired'
  },
  {
    id: 'archived',
    name: 'Archived'
  }
];

const AdTypePopover: React.FC<AdTypePopoverProps> = ({
  filterAnchorEl,
  setFilterAnchorEl,
  selectedAdType,
  handleAdTypeChange,
  classes,
  adType
}) => {
  return (
    <Popover
      id="filter-menu-popover"
      sx={classes.filterMenu}
      open={Boolean(filterAnchorEl)}
      anchorEl={filterAnchorEl}
      onClose={() => {
        setFilterAnchorEl(null);
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
    >
      {ADTYPES.map(
        (type) =>
          type.id !== selectedAdType && (
            <span
              key={type.name}
              onClick={() => handleAdTypeChange(type.id)}
              id={`${type.name}-select-${adType}-ad-button`}
            >
              {type.name}
            </span>
          )
      )}
    </Popover>
  );
};

export default AdTypePopover;
