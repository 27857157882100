"use strict";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);
const ALLOWED_FORMATS = {
  long: "Do MMMM YYYY[,] h:mma",
  short: "Do MMM YYYY"
};
const useUserTimeZone = (timeString, format = "long") => {
  var _a, _b;
  const userTimeZone = ((_a = document.querySelector('meta[name="user-timezone"]')) == null ? void 0 : _a.getAttribute("value")) || dayjs.tz.guess();
  return dayjs(timeString).tz(userTimeZone).format((_b = ALLOWED_FORMATS[format]) != null ? _b : ALLOWED_FORMATS.short);
};
export default useUserTimeZone;
