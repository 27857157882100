import React from 'react';
import { Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { styles } from '../styles';
import { useQueryClient } from '@tanstack/react-query';
import { IIntegrationsResponse } from '../../types';

export default function TestgridRecord({ formatDate }: { formatDate: (date: string) => string }) {
  const queryClient = useQueryClient();
  const integrationsData = queryClient.getQueryData<IIntegrationsResponse>(['integrations']);

  if (!integrationsData?.test_grid_requests.length) return null;
  return (
    <Stack sx={styles.integrationDetails}>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>TestGrid requested tests</Stack>
      </Stack>
      <Table sx={styles.integrationDetailsTable}>
        <TableHead>
          <TableRow>
            {['Package Name', 'Status', 'Requested at', 'Score'].map((title, index) => (
              <TableCell sx={styles.integrationDetailsTableHeader} key={index}>
                <Stack>{title}</Stack>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {integrationsData?.test_grid_requests.map((request, index) => (
            <TableRow key={index}>
              <TableCell sx={{ verticalAlign: 'top' }}>{request.package_name || '-'}</TableCell>
              <TableCell sx={{ verticalAlign: 'top' }}>{request.status}</TableCell>
              <TableCell sx={{ verticalAlign: 'top' }}>{formatDate(request.created_at)}</TableCell>
              <TableCell sx={{ verticalAlign: 'top' }}>{request.score || '-'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
}
