import { InputLabel, Typography } from '@mui/material';
import {
  DateTimeValidationError,
  DateValidationError,
  DesktopDatePickerProps,
  PickerChangeHandlerContext,
  StaticDatePicker,
  StaticDatePickerProps
} from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker, TimePickerProps } from '@mui/x-date-pickers/TimePicker';
import moment, { Moment } from 'moment';
import React, { CSSProperties, ReactNode, useState } from 'react';
import { IStyle } from '../../../ThemeContext/ThemeObject';
import { sharedClasses } from './sharedClasses';
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

interface IUncontrolledSingleDatePickerProps extends DesktopDatePickerProps<Date> {
  defaultValue: Date | null;
  handleDateChange: (date: Date | null) => void;
  maxDate?: Date;
  minDate?: Date;
  inputStyles?: IStyle | CSSProperties;
  disabled?: boolean;
  innerRef?: React.RefObject<HTMLInputElement> | ((el: HTMLDivElement) => void);
}

interface ISingleDatePickerProps extends Omit<DesktopDatePickerProps<Date>, 'onChange'> {
  value: Date | null;
  onChange: (
    value: Moment | null,
    keyboardInputValue?: PickerChangeHandlerContext<DateValidationError>
  ) => void;
  maxDate?: Date;
  minDate?: Date;
  inputStyles?: IStyle | CSSProperties;
  label?: string | ReactNode;
  showTodayButton?: boolean;
  error?: string;
  required?: boolean;
}

const todayButtonStyling = {
  '& .MuiDialogActions-root': {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 3,
    '& button': sharedClasses.genericButtonSecondary
  },
  '& .MuiDateCalendar-root': {
    height: '325px'
  }
};

interface ISingleDatePickerTimeProps {
  value: Date;
  onChange: (
    value: Date | null,
    context?: PickerChangeHandlerContext<DateTimeValidationError>
  ) => void;
  minDate?: Date;
  inputStyles?: IStyle | CSSProperties;
  pickerStyles?: IStyle | CSSProperties;
}

interface ISingleDateTimePickerProps extends ISingleDatePickerTimeProps {
  label?: string | ReactNode;
  timezone?: string;
  disablePast?: boolean;
  required?: boolean;
  innerRef?: React.RefObject<HTMLInputElement>;
  error?: string;
}

interface IControlledTimePicker extends TimePickerProps<Dayjs> {
  label?: string;
  minutesInterval?: number;
  inputStyles?: IStyle | CSSProperties;
}

interface IControlledStaticTimePicker extends StaticDatePickerProps<Date> {
  value: Date | null;
  onChange: (value: Date | null) => void;
  inputStyles?: IStyle | CSSProperties;
}

export function SingleDatePicker({
  value,
  onChange,
  maxDate,
  minDate,
  inputStyles,
  label,
  showTodayButton,
  error,
  required,
  ...rest
}: ISingleDatePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {label && (
        <InputLabel shrink htmlFor="text-input" sx={sharedClasses.inputLabel}>
          {label}
          {required && <span>*</span>}
        </InputLabel>
      )}
      <DesktopDatePicker
        format="DD/MM/YYYY"
        value={moment(value)}
        maxDate={maxDate}
        minDate={minDate}
        slotProps={{
          popper: {
            sx: { ...sharedClasses.calendar, ...(showTodayButton && todayButtonStyling) },
            placement: 'bottom-end',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [28, 15]
                }
              }
            ]
          },
          textField: { InputProps: { sx: { ...sharedClasses.datePicker, ...inputStyles } } },
          ...(showTodayButton && { actionBar: { actions: ['today'] } })
        }}
        onChange={onChange}
        {...rest}
      />
      <Typography variant="caption" sx={sharedClasses.errorMessage}>
        {error}
      </Typography>
    </LocalizationProvider>
  );
}

export function SingleDateTimePicker({
  value,
  onChange,
  minDate,
  inputStyles,
  pickerStyles,
  label,
  required,
  innerRef,
  error,
  ...rest
}: ISingleDateTimePickerProps) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      {label && (
        <InputLabel shrink htmlFor="text-input" sx={sharedClasses.inputLabel}>
          {label}
          {required && <span>*</span>}
        </InputLabel>
      )}
      <DateTimePicker
        format="DD/MM/YYYY hh:mm a"
        value={moment(value)}
        minDate={minDate}
        timeSteps={{ hours: 1, minutes: 1 }}
        views={['year', 'month', 'day', 'hours', 'minutes']}
        openTo={'day'}
        slotProps={{
          popper: {
            sx: pickerStyles,
            placement: 'bottom-end',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [28, 15]
                }
              }
            ]
          },
          textField: { InputProps: { sx: { ...sharedClasses.datePicker, ...inputStyles } } },
          actionBar: { actions: ['today', 'cancel', 'accept'] }
        }}
        onChange={onChange}
        ref={innerRef}
        {...rest}
      />
      {error && (
        <Typography variant="caption" sx={sharedClasses.errorMessage}>
          {error}
        </Typography>
      )}
    </LocalizationProvider>
  );
}

export function UncontrolledSingleDatePicker({
  defaultValue,
  handleDateChange,
  maxDate,
  minDate,
  inputStyles,
  disabled,
  innerRef,
  ...rest
}: IUncontrolledSingleDatePickerProps) {
  const [date, setDate] = useState<Moment | null>(
    moment(defaultValue).isValid() ? moment(defaultValue) : null
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DesktopDatePicker
        ref={innerRef}
        format="DD/MM/YYYY"
        value={date}
        disabled={disabled ? disabled : false}
        maxDate={maxDate}
        minDate={minDate}
        slotProps={{
          popper: {
            sx: sharedClasses.calendar,
            placement: 'bottom-end',
            modifiers: [
              {
                name: 'offset',
                options: {
                  offset: [28, 15]
                }
              }
            ]
          },
          textField: { InputProps: { sx: { ...inputStyles, ...inputStyles } } }
        }}
        onChange={(date) => {
          setDate(date);
          handleDateChange(date);
        }}
        {...rest}
      />
    </LocalizationProvider>
  );
}

export function ControlledTimePicker({
  value,
  onChange,
  label,
  minutesInterval = 1,
  ...rest
}: IControlledTimePicker) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {label && (
        <InputLabel shrink htmlFor="text-input" sx={sharedClasses.inputLabel}>
          {label}
        </InputLabel>
      )}
      <TimePicker
        value={dayjs(value)}
        slotProps={{
          popper: { sx: { ...sharedClasses.calendar } }
        }}
        onChange={onChange}
        timeSteps={{ minutes: minutesInterval }}
        sx={sharedClasses.materialTimePicker}
        {...rest}
      />
    </LocalizationProvider>
  );
}

export function ControlledStaticDatePicker({
  value,
  onChange,
  sx,
  ...rest
}: IControlledStaticTimePicker) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <StaticDatePicker
        slotProps={{
          calendarHeader: {
            sx: sharedClasses.calendarHeader
          }
        }}
        value={moment(value)}
        onChange={onChange}
        {...rest}
      />
    </LocalizationProvider>
  );
}
