"use strict";
import { theme } from "./ThemeContext/ThemeObject";
export const classes = {
  tableHeader: {
    fontFamily: "Source Sans Pro",
    fontWeight: "bold",
    color: theme.palette.primary.main,
    textTransform: "capitalize",
    paddingLeft: 2,
    paddingBottom: 3
  },
  tableStyling: {
    "& .MuiTableCell-root": {
      fontFamily: "Source Sans Pro",
      borderInline: "none",
      borderTop: "none"
    },
    "& .MuiTable-root": {
      borderCollapse: "collapse"
    }
  }
};
