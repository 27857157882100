import React, { Dispatch, SetStateAction } from 'react';
import { Switch, FormControlLabel } from '@mui/material';
import { classes } from '../styles';

export default function BooleanFieldSwitch({
  fieldValue,
  setFieldValue,
  label,
  isSystemField
}: {
  fieldValue: boolean;
  setFieldValue: Dispatch<SetStateAction<boolean>>;
  label: string;
  isSystemField: boolean;
}) {
  const labelSx = !fieldValue
    ? { ...classes.switchLabels, ...classes.labelActive }
    : classes.switchLabels;

  return (
    <FormControlLabel
      sx={labelSx}
      control={
        <Switch
          sx={fieldValue ? classes.switchActive : classes.switch}
          checked={fieldValue}
          disabled={isSystemField}
          onChange={() => setFieldValue(!fieldValue)}
        />
      }
      label={label}
      labelPlacement="end"
    />
  );
}
