import React from 'react';
import axios from 'axios';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { IJobAdIndeed, IJobAdViewJobs, IActionButtonProps } from './types';
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArchiveIcon from '@mui/icons-material/Archive';

const ActionButton: React.FC<IActionButtonProps> = ({
  ad,
  jobAds,
  setAds,
  adsStatus,
  tableHovered,
  apiKey,
  classes,
  adType,
  setDialogLoading,
  setDialogUrl,
  setDialogOpen,
  setActionsAnchorEl,
  setDialogAdID,
  setDialogTitle,
  setDialogDescription,
  setDialogButtonText,
  setDialogButtonCallback,
  setSelectedAd,
  handleSnack
}) => {
  const urls = adType === 'indeed' ? ad.indeed_urls : ad.viewjobs_urls;
  const adBoard = adType === 'indeed' ? 'Indeed' : 'ViewJobs';

  const handleButtonStyle = (ad: IJobAdIndeed | IJobAdViewJobs) => {
    switch (ad.status) {
      case 'open':
        return getExpireAdButtonStyle(ad);
      case 'new':
        return getContinueDraftButtonStyle();
      default:
        return getCloneAdButtonStyle();
    }
  };

  const getExpireAdButtonStyle = (ad: IJobAdIndeed | IJobAdViewJobs) => {
    return {
      text: 'Expire Ad',
      class: classes.buttonRedLight,
      callback: () => {
        setDialogUrl(`/api/v4/${adType}/jobs/${ad.job_id}/job_ads/${ad.id}/expire`);
        setDialogAdID(ad.id);
        setDialogTitle('Are you sure you want to expire this ad?');
        setDialogDescription(
          adType === 'indeed'
            ? 'The ad will expire within the next 4 hours. To repost this ad later you will be charged for a new ad.'
            : ''
        );
        setDialogButtonText('Expire now');
        setDialogButtonCallback(() => handleExpire);
        setDialogOpen(true);
      }
    };
  };

  const getContinueDraftButtonStyle = () => {
    return {
      text: 'Continue Draft',
      class: classes.buttonGreenLight,
      callback: () => handleEdit(urls.edit)
    };
  };

  const getCloneAdButtonStyle = () => {
    return {
      text: 'Clone Ad',
      class: classes.buttonSecondaryLight,
      callback: () => {
        setDialogUrl(urls.copy);
        setDialogTitle('Are you sure you want to clone this ad?');
        setDialogDescription(`You will have to repost for your ad to be active on ${adBoard}.`);
        setDialogButtonText('Clone ad');
        setDialogButtonCallback(() => handleCopy);
        setDialogOpen(true);
      }
    };
  };

  async function handleEdit(url: string) {
    window.location.href = url;
  }

  async function handleExpire(url: string, id: number) {
    setDialogLoading(true);
    try {
      const { data } = await axios.put(`${url}`, {
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const newAds = Object.assign(jobAds);
      const index = newAds[adsStatus].findIndex(
        (ad: IJobAdIndeed | IJobAdViewJobs) => ad.id === id
      );
      const copyJob = newAds[adsStatus][index];
      newAds.expired && newAds.expired.push(copyJob);
      newAds[adsStatus].splice(index, 1);
      setAds({ ...newAds });
      handleSnack(`${data.title} has been expired`);
    } catch (e) {
      console.log(e);
      handleSnack('Could not expire ad');
    } finally {
      setDialogLoading(false);
      setDialogUrl(null);
      setDialogOpen(false);
    }
  }

  async function handleArchive(url: string, id: number) {
    setDialogLoading(true);
    try {
      const { data } = await axios.put(`${url}`, {
        headers: {
          'X-api-authenticate': apiKey
        }
      });
      const newAds = Object.assign(jobAds);
      const index = newAds[adsStatus].findIndex(
        (ad: IJobAdIndeed | IJobAdViewJobs) => ad.id === id
      );
      const copyJob = newAds[adsStatus][index];
      newAds.archived && newAds.archived.push(copyJob);
      newAds[adsStatus].splice(index, 1);
      setAds({ ...newAds });
      handleSnack(`${data.title} has been archived`);
    } catch (e) {
      console.log(e);
      handleSnack('Could not archive ad');
    } finally {
      setDialogLoading(false);
      setDialogUrl(null);
      setDialogOpen(false);
    }
  }

  async function handleCopy(url: string) {
    setDialogLoading(true);
    try {
      const { data } = await axios.get(`${url}?operation=clone`);
      window.location.href = data.url;
    } catch (e) {
      console.log(e);
      handleSnack('Could not clone ad');
    } finally {
      setDialogLoading(false);
      setDialogUrl(null);
      setDialogOpen(false);
    }
  }

  const showIcons = (ad: IJobAdIndeed | IJobAdViewJobs) => {
    switch (adsStatus) {
      case 'active':
        return renderActiveIcons(ad);
      case 'draft':
        return renderDraftIcon();
      case 'expired':
        return renderExpiredIcon(ad);
      case 'archived':
        return null;
      default:
        return null;
    }
  };

  const renderActiveIcons = (ad: IJobAdIndeed | IJobAdViewJobs) => (
    <>
      <Tooltip title="Edit">
        <IconButton
          sx={classes.icon}
          onClick={() => handleEdit(urls.edit)}
          className={`edit-${adBoard}-ad-button`}
        >
          <EditIcon fontSize="small" sx={classes.iconGrey} />
        </IconButton>
      </Tooltip>
      <IconButton
        sx={classes.icon}
        onClick={(e) => {
          setActionsAnchorEl(e.currentTarget);
          setSelectedAd(ad);
        }}
        className={`${adBoard}-ad-actions-button`}
      >
        <MoreVertIcon sx={classes.iconGrey} />
      </IconButton>
    </>
  );

  const renderDraftIcon = () => (
    <Tooltip title="Edit">
      <IconButton
        sx={classes.icon}
        onClick={() => handleEdit(urls.edit)}
        className={`edit-${adBoard}-ad-button`}
      >
        <EditIcon fontSize="small" sx={classes.iconGrey} />
      </IconButton>
    </Tooltip>
  );

  const renderExpiredIcon = (ad: IJobAdIndeed | IJobAdViewJobs) => (
    <>
      <Tooltip title="Archive">
        <IconButton
          sx={classes.icon}
          onClick={() => {
            setDialogUrl(`/api/v4/${adType}/jobs/${ad.job_id}/job_ads/${ad.id}/archive`);
            setDialogAdID(ad.id);
            setDialogTitle('Are you sure you want to archive this ad?');
            setDialogButtonText('Archive now');
            setDialogButtonCallback(() => handleArchive);
            setDialogOpen(true);
          }}
        >
          <ArchiveIcon sx={classes.iconRed} />
        </IconButton>
      </Tooltip>
    </>
  );

  const buttonStyle = handleButtonStyle(ad);
  return (
    <Box
      sx={{
        ...classes.actionsContainer,
        ...classes.stickyRight,
        ...classes.scrollShadowRight,
        ...(tableHovered && classes.scrollShadowVisible)
      }}
    >
      <Box sx={classes.actionButtonContainer}>
        <Button
          sx={{
            ...classes.columnRefrence,
            ...buttonStyle.class,
            ...classes.columnButtonPadding,
            ...classes.actionButton
          }}
          disableElevation
          size="large"
          onClick={buttonStyle.callback}
        >
          {buttonStyle.text}
        </Button>
      </Box>
      {showIcons(ad)}
    </Box>
  );
};

export default ActionButton;
