import React, { useState, Dispatch } from 'react';
import { Stack } from '@mui/material';
import OldIntegrationsLink from './OldIntegrationsLink';
import ExportButton from './ExportButton';
import OnboardExpressLogo from './Logos/OnboardExpressLogo.png';
import XrefLogo from './Logos/XrefLogo.png';
import MvsLogo from './Logos/MvsLogo.png';
import ReferooLogo from './Logos/ReferooLogo.png';
import FoundULogo from './Logos/FoundULogo.png';
import TestgridLogo from './Logos/TestgridLogo.png';
import HronboardLogo from './Logos/HronboardLogo.png';
import RoublerLogo from './Logos/RoublerLogo.png';
import SparkHireLogo from './Logos/SparkHireLogo.png';
import Fit2WorkLogo from './Logos/Fit2WorkLogo.png';
import AlayaCareLogo from './Logos/AlayaCareLogo.png';
import { ApplicationState, ApplicationAction } from '../types';
import { ModalType } from '../config';
import OnboardExpress from './Modals/OnboardExpress';
import XRef from './Modals/XRef';
import Mvs from './Modals/Mvs';
import Referoo from './Modals/Referoo';
import FoundU from './Modals/FoundU';
import Testgrid from './Modals/Testgrid';
import Hronboard from './Modals/Hronboard';
import Roubler from './Modals/Roubler';
import SparkHire from './Modals/SparkHire';
import Fit2Work from './Modals/Fit2Work';
import AlayaCare from './Modals/AlayaCare';
import { ScoutOnboardLogo } from './Logos/Logos';
import { useQueryClient } from '@tanstack/react-query';
import { IUserPermissions } from '../../Components/sharedTypes';
import { styles } from './styles';
import { IApplication } from '../types';
import moment from 'moment-timezone';
import UnusualActivity from './Modals/UnusualActivity';
import AlreadyExportedModal from './Modals/AlreadyExportedModal';
import XrefRecord from './Records/XrefRecord';
import ReferooRecord from './Records/ReferooRecord';
import MvsRecord from './Records/MvsRecord';
import Fit2WorkRecord from './Records/Fit2WorkRecord';
import TestgridRecord from './Records/TestgridRecord';
import SparkHireRecord from './Records/SparkHireRecord';
import { useGetIntegrationsData } from '../helper';

export default function IntegrationsTab({
  ApplicationState,
  dispatch,
  newIntegrationEnabled
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
  newIntegrationEnabled: boolean;
}) {
  const [alreadyExportedModalOpen, setAlreadyExportedModalOpen] = useState<{
    revolve: boolean;
    referoo: boolean;
    hro: boolean;
    sparkhire: boolean;
  }>({ revolve: false, referoo: false, hro: false, sparkhire: false });
  const { modalsOpen, userTimeZone } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const permissions = queryClient.getQueryData<IUserPermissions>(['permissions']);

  const formatDate = (date: string) =>
    moment
      .tz(
        date,
        application?.job?.time_zone ||
          userTimeZone ||
          Intl.DateTimeFormat().resolvedOptions().timeZone
      )
      .format('ddd, D MMM YYYY, hh:mma z');

  const { integrationsData } = useGetIntegrationsData({ applicationId: application?.id, dispatch });

  if (!application) return null;

  return (
    <Stack sx={{ rowGap: 5 }}>
      <Stack sx={{ flexDirection: 'row', columnGap: 4, flexWrap: 'wrap', rowGap: 4 }}>
        {permissions?.Integrations?.['Export To Revolve Onboard'] && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={styles.integrationCardImageContainer}
              component="img"
              src={OnboardExpressLogo}
            />
            <ExportButton
              onClick={() =>
                application.revolve_record_id
                  ? setAlreadyExportedModalOpen({ ...alreadyExportedModalOpen, revolve: true })
                  : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ONBOARD_EXPRESS })
              }
            />
          </Stack>
        )}
        {permissions?.Integrations?.['Export To SCOUT Onboard'] && (
          <Stack sx={styles.integrationCard}>
            <Stack sx={styles.integrationCardImageContainer}>
              <ScoutOnboardLogo />
            </Stack>
            <ExportButton
              onClick={() =>
                dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.EXPORT_TO_ONBOARD })
              }
            />
          </Stack>
        )}
        {permissions?.Integrations?.['Xref (Export)'] && application?.integrations?.xref && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, width: '4rem' }}
              component="img"
              src={XrefLogo}
            />
            <ExportButton
              onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.XREF })}
            />
          </Stack>
        )}
        {newIntegrationEnabled &&
          permissions?.Integrations?.['My Verification Service (MVS)'] &&
          application?.integrations?.mvs && (
            <Stack sx={styles.integrationCard}>
              <Stack
                sx={{ ...styles.integrationCardImageContainer, width: '4rem' }}
                component="img"
                src={MvsLogo}
              />
              <ExportButton
                onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.MVS })}
              />
            </Stack>
          )}
        {permissions?.Integrations?.Referoo && application?.integrations?.referoo && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, width: '5.5rem' }}
              component="img"
              src={ReferooLogo}
            />
            <ExportButton
              onClick={() => {
                if (integrationsData?.referoo_login_url)
                  window.open(integrationsData?.referoo_login_url);
                else {
                  integrationsData?.referoo_record
                    ? setAlreadyExportedModalOpen({ ...alreadyExportedModalOpen, referoo: true })
                    : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.REFEROO });
                }
              }}
            />
          </Stack>
        )}
        {newIntegrationEnabled &&
          permissions?.Integrations?.FoundU &&
          application?.integrations?.foundu && (
            <Stack sx={styles.integrationCard}>
              <Stack
                sx={{ ...styles.integrationCardImageContainer, width: '3.5rem' }}
                component="img"
                src={FoundULogo}
              />
              <ExportButton
                onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.FOUNDU })}
              />
            </Stack>
          )}
        {permissions?.Integrations?.TestGrid && application?.integrations?.test_grid && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, padding: '8px 0px', width: '5rem' }}
              component="img"
              src={TestgridLogo}
            />
            <ExportButton
              onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.TESTGRID })}
            />
          </Stack>
        )}
        {newIntegrationEnabled && permissions?.Integrations?.['Export To HR Onboard'] && (
          <Stack sx={styles.integrationCard}>
            <Stack
              sx={{ ...styles.integrationCardImageContainer, padding: '8px 0px', width: '7rem' }}
              component="img"
              src={HronboardLogo}
            />
            <ExportButton
              onClick={() =>
                integrationsData?.hro_record_exported
                  ? setAlreadyExportedModalOpen({ ...alreadyExportedModalOpen, hro: true })
                  : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.HRONBOARD })
              }
            />
          </Stack>
        )}
        {newIntegrationEnabled &&
          permissions?.Integrations?.['Roubler Integration'] &&
          application?.integrations?.roubler && (
            <Stack sx={styles.integrationCard}>
              <Stack
                sx={{ ...styles.integrationCardImageContainer, padding: '8px 0px', width: '6rem' }}
                component="img"
                src={RoublerLogo}
              />
              <ExportButton
                onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ROUBLER })}
              />
            </Stack>
          )}
        {newIntegrationEnabled &&
          permissions?.Integrations?.['Export To Sparkhire'] &&
          application?.integrations?.sparkhire && (
            <Stack sx={styles.integrationCard}>
              <Stack
                sx={{ ...styles.integrationCardImageContainer, padding: '12px 0px', width: '7rem' }}
                component="img"
                src={SparkHireLogo}
              />
              <ExportButton
                onClick={() =>
                  integrationsData?.sparkhire_record
                    ? setAlreadyExportedModalOpen({ ...alreadyExportedModalOpen, sparkhire: true })
                    : dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.SPARKHIRE })
                }
              />
            </Stack>
          )}
        {newIntegrationEnabled &&
          permissions?.Integrations?.Fit2Work &&
          application?.integrations?.fit2_work && (
            <Stack sx={styles.integrationCard}>
              <Stack
                sx={{ ...styles.integrationCardImageContainer, padding: '12px 0px', width: '6rem' }}
                component="img"
                src={Fit2WorkLogo}
              />
              <ExportButton
                onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.FIT2WORK })}
              />
            </Stack>
          )}
        {newIntegrationEnabled &&
          permissions?.Integrations?.['AlayaCare Integration'] &&
          application?.integrations.alayacare && (
            <Stack sx={styles.integrationCard}>
              <Stack
                sx={{ ...styles.integrationCardImageContainer, padding: '8px 0px', width: '6rem' }}
                component="img"
                src={AlayaCareLogo}
              />
              <ExportButton
                onClick={() => dispatch({ type: 'SET_MODALS_OPEN', payload: ModalType.ALAYACARE })}
              />
            </Stack>
          )}
      </Stack>
      {permissions?.Integrations?.Referoo &&
        application?.integrations?.referoo &&
        integrationsData?.referoo_record && (
          <ReferooRecord dispatch={dispatch} formatDate={formatDate} />
        )}
      {permissions?.Integrations?.['Xref (View)'] && !!integrationsData?.xref_records.length && (
        <XrefRecord dispatch={dispatch} formatDate={formatDate} />
      )}
      {newIntegrationEnabled &&
        permissions?.Integrations?.['My Verification Service (MVS)'] &&
        application?.integrations?.mvs &&
        !!integrationsData?.mvs_requests.length && <MvsRecord formatDate={formatDate} />}
      {newIntegrationEnabled &&
        permissions?.Integrations?.Fit2Work &&
        application?.integrations?.fit2_work &&
        !!integrationsData?.fit2_work_records.length && (
          <Fit2WorkRecord dispatch={dispatch} formatDate={formatDate} />
        )}
      {newIntegrationEnabled &&
        permissions?.Integrations?.['Export To Sparkhire'] &&
        application?.integrations?.sparkhire && <SparkHireRecord />}
      {permissions?.Integrations?.TestGrid && application?.integrations?.test_grid && (
        <TestgridRecord formatDate={formatDate} />
      )}
      <OldIntegrationsLink />
      {modalsOpen === ModalType.ONBOARD_EXPRESS && (
        <OnboardExpress ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.XREF && (
        <XRef ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.MVS && (
        <Mvs ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.REFEROO && (
        <Referoo ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.FOUNDU && (
        <FoundU ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.TESTGRID && (
        <Testgrid ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.HRONBOARD && (
        <Hronboard ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.ROUBLER && (
        <Roubler ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.SPARKHIRE && (
        <SparkHire ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      {modalsOpen === ModalType.FIT2WORK && (
        <Fit2Work ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
      <AlayaCare ApplicationState={ApplicationState} dispatch={dispatch} />
      <AlreadyExportedModal
        alreadyExportedModalOpen={alreadyExportedModalOpen}
        onClose={() =>
          setAlreadyExportedModalOpen({
            revolve: false,
            referoo: false,
            hro: false,
            sparkhire: false
          })
        }
        dispatch={dispatch}
      />
      {modalsOpen === ModalType.UNUSUAL_ACTIVITY && (
        <UnusualActivity ApplicationState={ApplicationState} dispatch={dispatch} />
      )}
    </Stack>
  );
}
