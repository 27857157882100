import React, { Dispatch, FC, useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { sharedClasses } from '../Components/CustomUIElements/sharedClasses';
import { classes } from '../ApprovalForms/NewApprovalPublicLink/styles';
import { DebouncedFormTextField } from '../Components/CustomUIElements/FormTextField';
import {
  Box,
  FormControlLabel,
  Switch,
  Modal,
  Button,
  CircularProgress,
  TextField,
  Autocomplete
} from '@mui/material';
import { classes as switchStyles } from '../Jobs/Dropdowns/useStyles';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  SmartFormFieldsAction,
  SmartFormFieldsState,
  SmartFormsAction,
  SmartFormsState
} from './reducer';
import { styles } from './styles';
import { IEntity, IForm, ModalType } from './types';
import Api from './API';

interface SmartFormModal {
  dispatch: Dispatch<SmartFormsAction> | Dispatch<SmartFormFieldsAction>;
  limit?: number;
  form?: IForm;
  entityId: number;
  smartFormState: SmartFormsState | SmartFormFieldsState;
  refetchForms: () => void;
  modalType: ModalType;
}

const SmartFormModal: FC<SmartFormModal> = ({
  dispatch,
  limit,
  form,
  entityId,
  smartFormState,
  refetchForms,
  modalType
}) => {
  const [enabled, setEnabled] = useState<boolean>(
    form?.enabled || smartFormState.selectedForm?.enabled || modalType === ModalType.CREATE
  );
  const [name, setName] = useState<string>(form?.name || smartFormState.selectedForm?.title || '');
  const [selectedEntity, setSelectedEntity] = useState<number>(() =>
    modalType === ModalType.CLONE ? smartFormState.selectedForm?.entity_id : entityId
  );
  const [selectedForm, setSelectedForm] = useState<number | null>(null);

  const modalState = useMemo(() => {
    const closeModal = () => {
      dispatch({
        type: 'SET_MODAL_OPEN',
        payload: null
      });
      dispatch({ type: 'SET_SELECTED_FORM', payload: null });
    };

    switch (modalType) {
      case ModalType.UPDATE:
        return {
          heading: 'Settings',
          actionButtonLabel: 'Confirm',
          showModal: (smartFormState as SmartFormFieldsState).modalsOpen?.smartFormSettings,
          handleClose: () =>
            dispatch({
              type: 'SET_MODALS_OPEN',
              payload: { ...smartFormState.modalsOpen, smartFormSettings: false }
            })
        };
      case ModalType.CREATE:
        return {
          heading: 'New smart form',
          actionButtonLabel: 'Create',
          showModal: (smartFormState as SmartFormsState).showSmartFormModal === ModalType.CREATE,
          handleClose: closeModal
        };
      case ModalType.CLONE:
        return {
          heading: 'Clone smart form',
          actionButtonLabel: 'Clone',
          showModal: (smartFormState as SmartFormsState).showSmartFormModal === ModalType.CLONE,
          handleClose: closeModal
        };
      case ModalType.CLONE_WITHOUT_FORM:
        return {
          heading: 'Clone smart form',
          actionButtonLabel: 'Clone',
          showModal:
            (smartFormState as SmartFormsState).showSmartFormModal === ModalType.CLONE_WITHOUT_FORM,
          handleClose: closeModal
        };
    }
  }, [modalType, smartFormState, dispatch]);

  const { actionButtonLabel, handleClose, heading, showModal } = modalState;

  const { data: entities, isFetching: fetchingEntities } = useQuery({
    queryKey: ['entities'],
    queryFn: async () => {
      const { res } = await Api.getTargetEntities();
      return res.entities;
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error loading entities, ${error}`,
          state: 'error'
        }
      }),
    initialData: []
  });

  const { data: forms, isFetching: fetchingForms } = useQuery({
    queryKey: ['all forms'],
    queryFn: async () => {
      const { res } = await Api.getSmartForms({ limit: limit, override_limit: true });
      return res.smart_forms;
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error loading smart forms, ${error}`,
          state: 'error'
        }
      }),
    initialData: []
  });

  const { mutate: createUpdateSmartForm, isLoading: isCreating } = useMutation({
    mutationFn: async (): Promise<void> => {
      const body = {
        name: name,
        entity_id: selectedEntity,
        enabled: enabled
      };

      if (modalType === ModalType.CLONE || modalType === ModalType.CLONE_WITHOUT_FORM) {
        return await Api.cloneForm(form?.id || smartFormState?.selectedForm?.id || selectedForm, {
          form: body
        });
      }

      if (!form) {
        return await Api.postSmartForm({ form: body });
      }

      if (modalType === ModalType.UPDATE) {
        await Api.updateSmartForm(form.id, { form: body });
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error ${modalType.substring(
            0,
            modalType.length - 1
          )}ing the smart form, ${error}`,
          state: 'error'
        }
      }),
    onSuccess: (data) => {
      if (modalType !== 'update') {
        window.location.href = data.res.redirect_url;
      }
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `Smart form was successfully ${
            modalType === ModalType.CLONE_WITHOUT_FORM ? 'clone' : modalType
          }d`,
          state: 'success'
        }
      });
      refetchForms();
    },
    onSettled: async () => form && handleClose()
  });

  return (
    <>
      {showModal && (
        <Modal open={showModal} onClose={handleClose} aria-labelledby="smart-form-modal">
          <Box
            sx={{
              ...classes.actionsModal,
              maxHeight: modalType === ModalType.CLONE_WITHOUT_FORM ? '555px' : '425px',
              width: '697px'
            }}
          >
            <Box sx={{ padding: '12px 0px 30px 0px' }}>
              <Box id="smart-form-modal-title" sx={classes.modalHeader}>
                {heading}
              </Box>
            </Box>
            <Box sx={{ marginTop: '1.75rem' }}>
              <Box sx={{ ...classes.modalFormLine }}>
                {modalType === ModalType.CLONE_WITHOUT_FORM && (
                  <Autocomplete
                    filterSelectedOptions
                    disablePortal
                    autoHighlight
                    includeInputInList
                    options={forms}
                    getOptionLabel={(option) => {
                      if (typeof option === 'string') {
                        return option;
                      }
                      return option.name ?? '';
                    }}
                    renderOption={(props, option, index) => {
                      const key = `listItem-${index}-${option.id}`;
                      return (
                        <li {...props} key={key}>
                          {option?.name}
                        </li>
                      );
                    }}
                    loading={fetchingForms}
                    loadingText={fetchingForms ? 'Loading forms...' : 'No Smart Forms'}
                    value={forms.find((form: IForm) => form.id === selectedForm) || null}
                    onChange={(_event, newValue) => {
                      if (newValue) {
                        setSelectedForm(newValue.id);
                        setName(newValue.name);
                      } else {
                        setSelectedForm(0);
                      }
                    }}
                    sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginBottom: '30px' }}
                    ListboxProps={{
                      style: {
                        fontFamily: 'Source Sans Pro, sans-serif',
                        color: '#333333',
                        maxHeight: '200px'
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select a smart form"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>
                              {fetchingForms ? (
                                <CircularProgress color="inherit" size={20} />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          )
                        }}
                      />
                    )}
                  />
                )}
                <Autocomplete
                  filterSelectedOptions
                  disablePortal
                  autoHighlight
                  includeInputInList
                  options={entities}
                  getOptionLabel={(option) => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    return option.name ?? '';
                  }}
                  renderOption={(props, option, index) => {
                    const key = `listItem-${index}-${option.id}`;
                    return (
                      <li {...props} key={key}>
                        {option?.name}
                      </li>
                    );
                  }}
                  loading={fetchingEntities}
                  loadingText={fetchingEntities ? 'Loading entities...' : 'No Entities'}
                  value={entities.find((entity: IEntity) => entity.id === selectedEntity) || null}
                  onChange={(_event, newValue) => {
                    if (newValue) {
                      setSelectedEntity(newValue.id);
                    } else {
                      setSelectedEntity(0);
                    }
                  }}
                  sx={{ ...sharedClasses.formAutocomplete, width: '100%' }}
                  ListboxProps={{
                    style: {
                      fontFamily: 'Source Sans Pro, sans-serif',
                      color: '#333333',
                      maxHeight: '200px'
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={
                        modalType === ModalType.CLONE_WITHOUT_FORM ? 'Entity' : 'Select an entity'
                      }
                      InputLabelProps={{ shrink: true }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {fetchingEntities ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        )
                      }}
                    />
                  )}
                />
              </Box>
              <Box
                sx={{
                  ...classes.modalFormLine,
                  marginBottom: '40px !important'
                }}
              >
                <DebouncedFormTextField
                  onDebouncedChange={(value: string) => {
                    setName(value);
                  }}
                  fullWidth
                  label="Name"
                  defaultValue={name}
                  required
                  styles={styles.textField}
                />
              </Box>

              <FormControlLabel
                sx={{
                  ...styles.defaultSwitch,
                  '& .MuiFormControlLabel-label': enabled
                    ? styles.defaultSwitch['& .MuiFormControlLabel-label']
                    : {
                        ...styles.defaultSwitch['& .MuiFormControlLabel-label'],
                        ...switchStyles.labelActive['& .MuiFormControlLabel-label']
                      }
                }}
                control={
                  <Switch
                    sx={enabled ? switchStyles.switchActive : switchStyles.switch}
                    checked={enabled}
                    onChange={() => setEnabled(!enabled)}
                  />
                }
                label="Enabled"
                labelPlacement="end"
              />
            </Box>
            <CloseIcon
              id="smart-form-modal-close-button"
              onClick={handleClose}
              sx={classes.closeIcon}
            />
            <Box sx={classes.modalActions}>
              <Button
                id="smart-form-modal-cancel-button"
                disableElevation
                sx={{ ...classes.buttonRedLight }}
                variant="text"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                sx={{ ...classes.modalEditButton, minWidth: 90 }}
                disabled={!name.length || !selectedEntity || isCreating}
                onClick={() => createUpdateSmartForm()}
              >
                {isCreating ? <CircularProgress size={20} color="inherit" /> : actionButtonLabel}
              </Button>
            </Box>
          </Box>
        </Modal>
      )}
    </>
  );
};

export default SmartFormModal;
