import React, { useEffect, useReducer, useRef, useState } from 'react';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';
import StyledSnackbar from '../NewUI/Components/CustomUIElements/StyledSnackbar';
import {
  Box,
  Button,
  CircularProgress,
  Link,
  Popover,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import {
  CalendarMonth as CalendarMonthIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
  Close as CloseIcon,
  Download as DownloadIcon,
  Sort as SortIcon,
  ViewList as ViewListIcon,
  Info as InfoIcon
} from '@mui/icons-material';
import { withScoutTheme } from '../ThemeContext/ThemeContext';
import CreateEvent from './CreateEvent/CreateEvent';
import Settings from './Settings';
import { sharedClasses } from '../NewUI/Components/CustomUIElements/sharedClasses';
import { InitialScoutCalendarState, ScoutCalendarReducer } from './reducer';
import { classes } from './styles';
import { IEvent } from './types';
import { DateCalendar, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import ViewEvent from './ViewEvent';
import { useQuery } from '@tanstack/react-query';
import CreateButton from '../NewUI/Components/Buttons/CreateButton';
import ListView from './ListView';
import { formatUserPermissions } from '../NewUI/Components/Utilities/formatUserPermissions';
import { getPermissions } from '../shared/permissionHelpers';
import EventCreated from './EventCreated';
import { getPlatform } from '../NewUI/utils/device-detect';
import { IScoutCalendarProps } from './index';
import moment, { Moment } from 'moment-timezone';
import { useEvents } from '../NewUI/hooks';

moment.locale('es-es', {
  week: {
    dow: 1 //Monday is the first day of the week.
  }
});

const localizer = momentLocalizer(moment);

const URL_PARAMS = new URL(window.location.href);

function ScoutCalendar({ currentUser, token, newApplicationEnabled }: IScoutCalendarProps) {
  const userTimezone = currentUser.time_zone ?? moment.tz.guess();
  moment.tz.setDefault(userTimezone);
  const { isMobile } = getPlatform();
  const [ScoutCalendarState, dispatch] = useReducer(
    ScoutCalendarReducer,
    InitialScoutCalendarState
  );
  const { weekStart, createEventObject, permissions } = ScoutCalendarState;
  const root = useRef(null);
  const [viewDate, setViewDate] = useState<Date>(new Date());
  const [showList, setShowList] = useState(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState<HTMLDivElement | null>(null);
  const [calendarAnchorEl, setCalendarAnchorEl] = useState<HTMLDivElement | null>(null);
  const [eventCreated, setEventCreated] = useState<boolean>(false);
  const [calendarView, setCalendarView] = useState<boolean>(!isMobile ? true : false);
  const { data: events, isLoading: loadingEvents } = useEvents({
    weekStart: weekStart,
    onError: (message: string) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `There was an error getting events, ${message}`, state: 'error' }
      })
  });

  function initialData() {
    const URL = window.location.pathname.split('/');
    URL[URL.length - 2] === 'show' && setViewDate(moment(URL[URL.length - 1]).toDate());
    URL[URL.length - 1] === 'show' && handleSelectSlot();
    URL_PARAMS.search === '?new' && handleSelectSlot();
    if (URL_PARAMS.search === '?new_multiple') {
      handleSelectSlot();
      dispatch({ type: 'CREATE_MULTIPLE_EVENTS', payload: true });
    }
  }

  useQuery({
    queryKey: ['permissions'],
    queryFn: async () => {
      const res = await getPermissions();
      dispatch({ type: 'SET_PERMISSIONS', payload: formatUserPermissions(res) });
      return res;
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting events, ${error}`,
          state: 'error'
        }
      });
    }
  });

  useEffect(() => {
    initialData();
  }, []);

  useEffect(() => {
    return () => {
      moment.tz.setDefault(); // reset to browser TZ on unmount
    };
  }, []);

  useEffect(() => getWeekStart(), [viewDate]);

  function getWeekStart() {
    const today = moment(viewDate).isoWeekday();
    const startDay =
      today === 1 ? moment(viewDate).toDate() : moment(viewDate).isoWeekday(1).toDate();
    dispatch({ type: 'SET_WEEK_START', payload: startDay });
  }

  const getMonthsName = () => {
    if (moment(weekStart).format('MMMM') !== moment(weekStart).add(6, 'd').format('MMMM')) {
      return `${moment(weekStart).format('MMM')} - ${moment(weekStart)
        .add(6, 'd')
        .format('MMM')} ${moment(weekStart).add(6, 'd').format('YYYY')}`;
    } else {
      return moment(weekStart).format('MMMM YYYY');
    }
  };

  function handleSelectEvent(event: IEvent, withoutModal?: boolean) {
    dispatch({
      type: 'SET_CREATE_EVENT_OBJECT',
      payload: {
        ...createEventObject,
        eventName: event.subject,
        eventDescription: event.note || '',
        maxCandidates: String(event.max_candidates),
        recruiters: event.attendees
          .filter((a) => a.type === 'User')
          .map((a) => ({
            id: a.id,
            name: a.name,
            checked: false,
            attendeeId: a.attendee_id,
            state: a.state
          })),
        eventType: event.event_type,
        duration: String(event.duration_in_minutes),
        eventDate: moment(event.begins_at, 'YYYY-MM-DD HH:mm').toDate(),
        eventId: event.id,
        candidates: event.attendees.filter((a) => a.type === 'Candidate'),
        dates: [moment(event.begins_at, 'YYYY-MM-DD HH:mm').toDate()],
        owner: event.owner
      }
    });
    !withoutModal && dispatch({ type: 'SET_SHOW_VIEW_EVENT_MODAL', payload: true });
  }

  function handleSelectSlot(slot?: { slots: Date[] }) {
    slot
      ? dispatch({
          type: 'SET_CREATE_EVENT_OBJECT',
          payload: { ...createEventObject, eventDate: slot.slots[0], dates: [slot.slots[0]] }
        })
      : dispatch({
          type: 'SET_CREATE_EVENT_OBJECT',
          payload: { ...createEventObject, eventDate: new Date() }
        });
    dispatch({ type: 'SET_SHOW_CREATE_EVENT_MODAL', payload: true });
  }

  function CustomEvent({ event }: { event: IEvent }) {
    const eventLength = moment
      .duration(moment(event.end).diff(moment(event.start, 'YYYY-MM-DD HH:mm').toDate()))
      .asMinutes();
    const sessionLength = event.duration_in_minutes;

    if (!eventLength) return <span />;

    const attendees = event.attendees.filter((attendee) => attendee.type !== 'User');

    return (
      <Box
        className={`
          event-container
          ${eventLength <= 10 ? 'small-event' : ''}
          ${sessionLength <= 20 ? 'small-session' : ''}
          ${'blue'}
        `}
      >
        <Box className="event-bg">
          <Box className={`event-details ${eventLength <= 30 ? 'truncate' : ''}`}>
            <Box
              className="event-title"
              sx={{ fontSize: '11px', fontWeight: 'bold', color: '#333333' }}
            >
              {event.subject}
            </Box>
          </Box>
          <Box className="session-container">
            <Box
              key={event.id}
              className={`session-inner ${!(sessionLength < 10) ? 'hoverable' : ''}`}
              style={{
                height: `${(event.duration_in_minutes / eventLength) * 100}%`
              }}
            >
              <Box
                id="event-card"
                className="session-length"
                onClick={() => handleSelectEvent(event)}
              >
                <Box
                  className={`session-element ${
                    attendees.filter((attendee) => attendee.state === 'accepted').length >=
                    event.max_candidates
                      ? 'booked'
                      : ''
                  }`}
                >
                  <span
                    className="session-candidate"
                    style={{ fontSize: eventLength <= 10 ? '11px' : '12px' }}
                  >
                    {attendees.length > 0
                      ? attendees.length === 1
                        ? `${attendees[0].name}`
                        : `${attendees.length} candidates`
                      : 'No bookings'}
                  </span>
                  {sessionLength >= 20 && (
                    <span className="session-time">{moment(event.begins_at).format('HH:mma')}</span>
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  const handleCreateEvent = (multiple: boolean) => {
    multiple && dispatch({ type: 'CREATE_MULTIPLE_EVENTS', payload: true });
    setActionsAnchorEl(null);
    handleSelectSlot();
    URL_PARAMS.search = multiple ? '?new_multiple' : '?new';
    window.history.pushState(null, '', URL_PARAMS.href);
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box ref={root} className="chronos-calendar">
        <Box sx={sharedClasses.pageHeader}>
          <Typography variant="h1">Events</Typography>
          {(permissions?.['Events']?.['Create / Edit Events'] ||
            permissions?.['Events']?.['Create Multiple Events']) && (
            <CreateButton
              id="create-event-main-button"
              ariaLabel="Add new events"
              onClick={(e) => {
                setActionsAnchorEl(e.currentTarget);
              }}
              isOpen={Boolean(actionsAnchorEl)}
            />
          )}
          <Popover
            open={Boolean(actionsAnchorEl)}
            anchorEl={actionsAnchorEl}
            onClose={() => {
              setActionsAnchorEl(null);
            }}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            sx={{ '& .MuiPaper-root': { marginTop: '12px', borderRadius: '6px' } }}
          >
            <Stack sx={classes.addNewEventsPopover}>
              {permissions?.['Events']?.['Create / Edit Events'] && (
                <Stack onClick={() => handleCreateEvent(false)} id="create-an-event-button">
                  Create An Event
                </Stack>
              )}
              {permissions?.['Events']?.['Create Multiple Events'] && (
                <Stack onClick={() => handleCreateEvent(true)} id="create-multiple-events-button">
                  Create Multiple Events
                </Stack>
              )}
            </Stack>
          </Popover>
        </Box>
        <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
          <Stack sx={{ width: '150px', flexDirection: 'row' }}>
            <Button
              id="today-button"
              sx={sharedClasses.genericButtonSecondary}
              onClick={() => setViewDate(new Date())}
            >
              Today
            </Button>
          </Stack>
          <Stack>
            <Box
              id="select-month"
              sx={classes.calendarHeader}
              onClick={(e) => {
                setCalendarAnchorEl(e.currentTarget);
              }}
            >
              {getMonthsName()}
            </Box>
            <Popover
              open={Boolean(calendarAnchorEl)}
              anchorEl={calendarAnchorEl}
              onClose={() => {
                setCalendarAnchorEl(null);
              }}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              transformOrigin={{ vertical: 'top', horizontal: 'center' }}
              sx={{ '& .MuiPaper-root': { marginTop: 1 } }}
            >
              <Stack sx={{ padding: 1 }}>
                <CloseIcon
                  sx={sharedClasses.closeIcon}
                  onClick={() => {
                    setCalendarAnchorEl(null);
                  }}
                />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateCalendar
                    views={['month', 'year']}
                    openTo="month"
                    sx={classes.dateCalendar}
                    onChange={(val: Moment | null) => val && setViewDate(val.toDate())}
                  />
                </LocalizationProvider>
              </Stack>
            </Popover>
          </Stack>
          <Stack sx={classes.viewAndSettingsContainer}>
            {!isMobile && (
              <Tooltip placement="top" title={calendarView ? 'List view' : 'Calendar view'} arrow>
                <Button
                  variant="outlined"
                  id="calendar-list-view-button"
                  sx={classes.viewAndSettingsButton}
                  onClick={() => setCalendarView(!calendarView)}
                >
                  {calendarView ? (
                    <ViewListIcon sx={{ color: '#084D6D' }} fontSize="small" />
                  ) : (
                    <CalendarMonthIcon sx={{ color: '#084D6D' }} fontSize="small" />
                  )}
                </Button>
              </Tooltip>
            )}
            <Tooltip placement="top" title="Download iCal" arrow>
              <Button
                variant="outlined"
                id="download-ical-button"
                sx={classes.viewAndSettingsButton}
                onClick={() => window.open(`${window.location.origin}/ical.ics?token=${token}`)}
              >
                <DownloadIcon sx={{ color: '#084D6D' }} fontSize="small" />
              </Button>
            </Tooltip>
            <Tooltip placement="top" title="Filter events" arrow>
              <Button
                variant="outlined"
                id="filter-events-button"
                sx={classes.viewAndSettingsButton}
                onClick={() => setShowList(!showList)}
              >
                <SortIcon sx={{ color: '#084D6D' }} fontSize="small" />
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack sx={classes.weekPeriodContainer}>
          <ChevronLeftIcon
            id="previous-week-button"
            sx={classes.calendarArrows}
            onClick={() => setViewDate(moment(viewDate).subtract(1, 'w').toDate())}
          />
          <Box sx={{ fontSize: '14px' }}>
            {moment(weekStart).format('DD MMMM')} -{' '}
            {moment(weekStart).add(6, 'd').format('DD MMMM')}
          </Box>
          <ChevronRightIcon
            id="next-week-button"
            sx={classes.calendarArrows}
            onClick={() => setViewDate(moment(viewDate).add(1, 'w').toDate())}
          />
        </Stack>
        <Stack sx={classes.timezoneInfo}>
          <InfoIcon fontSize="small" />
          Your Calendar displays in{' '}
          <b>
            GMT ({moment.tz(new Date(), userTimezone).format('Z')}) {userTimezone}
          </b>{' '}
          time.
          <Link href={`${window.location.origin}/admin/settings`} target="_blank" underline="hover">
            Edit time zone
          </Link>
        </Stack>
        {loadingEvents || !events ? (
          <Stack sx={{ paddingTop: 20, alignItems: 'center' }}>
            <CircularProgress size={45} sx={{ color: '#004E70' }} />
          </Stack>
        ) : (
          <>
            {calendarView ? (
              <Calendar
                events={events}
                date={viewDate}
                localizer={localizer}
                scrollToTime={moment(new Date()).subtract(90, 'm').toDate()}
                defaultView={Views.WEEK}
                step={30}
                timeslots={1}
                selectable={true}
                onSelectSlot={(e) =>
                  permissions?.['Events']?.['Create / Edit Events'] && handleSelectSlot(e)
                }
                onSelecting={() => false}
                onNavigate={() => false}
                components={{
                  event: CustomEvent
                }}
                formats={{
                  dayFormat: 'dddd DD/MM'
                }}
              />
            ) : (
              <ListView
                events={events}
                handleSelectEvent={handleSelectEvent}
                ScoutCalendarState={ScoutCalendarState}
                dispatch={dispatch}
              />
            )}
          </>
        )}
      </Box>
      {showList && events && (
        <Settings
          showList={showList}
          setShowList={setShowList}
          events={events}
          handleSelectEvent={handleSelectEvent}
        />
      )}
      {ScoutCalendarState.showCreateEventModal && events && (
        <CreateEvent
          events={events}
          ScoutCalendarState={ScoutCalendarState}
          dispatch={dispatch}
          setEventCreated={setEventCreated}
          userTimezone={userTimezone}
        />
      )}
      {ScoutCalendarState.showViewEventModal && events && (
        <ViewEvent
          events={events}
          ScoutCalendarState={ScoutCalendarState}
          dispatch={dispatch}
          currentUserId={currentUser.id}
          userTimezone={userTimezone}
          newApplicationEnabled={newApplicationEnabled}
        />
      )}
      <EventCreated
        open={eventCreated}
        onClose={() => {
          setEventCreated(false);
          dispatch({ type: 'RESET_STATE' });
          URL_PARAMS.search = '';
          window.history.pushState(null, '', URL_PARAMS.href);
        }}
        ScoutCalendarState={ScoutCalendarState}
        userTimezone={userTimezone}
      />
      <StyledSnackbar
        message={ScoutCalendarState.snackbar.message}
        state={ScoutCalendarState.snackbar.state}
        setSnackbarState={() =>
          dispatch({ type: 'SET_SNACKBAR', payload: { message: '', state: 'success' } })
        }
      />
    </Box>
  );
}

export default withScoutTheme(ScoutCalendar);
