import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import ScoutCalendar from './ScoutCalendar';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

export interface IScoutCalendarProps {
  currentUser: { id: number; time_zone: string | null };
  token: string;
  newApplicationEnabled: boolean;
}

export default function ScoutCalendarPage({
  currentUser,
  token,
  newApplicationEnabled
}: IScoutCalendarProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <ScoutCalendar {...{ currentUser, token, newApplicationEnabled }} />
    </QueryClientProvider>
  );
}
