"use strict";
import RecruitApiClient from "../../RecruitApiClient";
const BASE_URL = "/api/v4";
class Api {
  constructor() {
    this.getJobs = (params) => RecruitApiClient.get(`${BASE_URL}/jobs`, params);
    this.getJob = (jobId) => RecruitApiClient.get(`${BASE_URL}/jobs/${jobId}`);
    this.getFilters = (url, params) => RecruitApiClient.get(`${BASE_URL}/jobs/${url}`, params);
    this.getUISettings = (params) => RecruitApiClient.get(`${BASE_URL}/ui_preferences`, params, {
      cache: "no-cache"
    });
    this.postJobNote = (jobId, params) => RecruitApiClient.post(`${BASE_URL}/jobs/${jobId}/job_notes`, params);
    this.postUISettings = (params) => RecruitApiClient.post(`${BASE_URL}/ui_preferences`, params);
    this.putChangedState = (link) => RecruitApiClient.put(link);
    this.putChangedStatus = (jobId, statusID) => RecruitApiClient.put(`${BASE_URL}/jobs/${jobId}/status/${statusID}`);
    this.putPinJob = (jobId) => RecruitApiClient.put(`${BASE_URL}/jobs/${jobId}/pin`);
    this.putUnpinJob = (jobId) => RecruitApiClient.put(`${BASE_URL}/jobs/${jobId}/unpin`);
    this.putArchiveJobs = (params) => RecruitApiClient.put(`${BASE_URL}/jobs/archive`, params);
    this.putUnrchiveJobs = (params) => RecruitApiClient.put(`${BASE_URL}/jobs/unarchive`, params);
    this.getCSVExport = (params) => RecruitApiClient.get(`${BASE_URL}/jobs/export`, params);
    this.referenceValidation = (params) => RecruitApiClient.get(`${BASE_URL}/jobs/validating_reference`, params);
    this.referencePrefix = (params) => RecruitApiClient.get(`${BASE_URL}/jobs/auto_job_reference_setting`, params);
    this.importResources = (jobId, params, queryParams) => RecruitApiClient.post(`${BASE_URL}/jobs/${jobId}/import_resources${queryParams}`, params);
    this.cloneJobsOptions = (params) => RecruitApiClient.get(`${BASE_URL}/jobs/jobs_for_clone`, params);
    this.createJob = (params) => RecruitApiClient.post(`${BASE_URL}/jobs/`, params);
    this.getEntities = () => RecruitApiClient.get(`/api/v4/entities`);
  }
}
export default new Api();
