import React, { Dispatch, useRef, useState } from 'react';
import StyledModal from '../../../NewUI/Components/GenericModal/StyledModal';
import ModalFooterButtons from '../../../NewUI/Components/GenericModal/ModalFooterButtons';
import { useQuery } from '@tanstack/react-query';
import { sharedClasses } from '../../../NewUI/Components/CustomUIElements/sharedClasses';
import VirtualizeGrouped, {
  formatOptions
} from '../../../NewUI/Components/CustomUIElements/VirtualizedGroupedAutocomplete';
import { Box, Checkbox, FormControlLabel } from '@mui/material';
import { FormTextField } from '../../../NewUI/Components/CustomUIElements/FormTextField';
import HtmlEditor from '../../../NewUI/Components/Utilities/HtmlEditor';
import AttachmentSelectorAndPreview from '../../../NewUI/Components/CustomUIElements/AttachmentSelectorAndPreview';
import { EngageAction } from '../../reducer';
import { FileWithPath } from 'react-dropzone';
import callAPI from '../../../NewUI/Components/Utilities/callAPI';
import { handleAPIRequest } from '../../../NewUI/Components/Utilities/handleAPIRequest';
import { stringToSeconds } from '../../../NewUI/Components/Utilities/stringToSeconds';
import { ISnackbarInput } from '../../../NewUI/Components/CustomUIElements/StyledSnackbar';
import DelaySelectWrapper from '../../../NewUI/EditJob/Automation/shared/DelaySelectWrapper';

const EmailCandidate = ({
  selectedCandidateIds,
  selectedPoolIds,
  dispatch,
  isOpen,
  setSnackbarState,
  openedFromTableActions = false,
  showTotalSelectedCandidates = true
}: {
  selectedCandidateIds?: number[];
  selectedPoolIds?: number[];
  dispatch: Dispatch<EngageAction>;
  isOpen: boolean;
  setSnackbarState: (snackbarState: ISnackbarInput) => void;
  openedFromTableActions?: boolean;
  showTotalSelectedCandidates?: boolean;
}) => {
  const [isSendingEmails, setIsSendingEmails] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [excludeApplicants, setExcludeApplicants] = useState(false);
  const [skipScoutTalentClients, setSkipScoutTalentClients] = useState(true);
  const [templateError, setTemplateError] = useState(false);
  const [delay, setDelay] = useState<string>('None');
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [attachments, setAttachments] = useState<FileWithPath[]>([]);
  const [templateAttachments, setTemplateAttachments] = useState([]);
  const templateSelectionRef = useRef<HTMLDivElement>(null);

  const emailTemplates = useQuery({
    queryKey: ['emailTemplates'],
    queryFn: async () => {
      const { email_templates, placeholders } = await callAPI(
        '/api/v4/searches/email_templates?group_templates=true'
      );
      return { email_templates: formatOptions(email_templates), placeholders };
    },
    onError: (error) => {
      setSnackbarState({
        message:
          error?.errors || error?.message || 'There was an error getting the email templates',
        state: 'error'
      });
    }
  });

  const handleSendEmails = () => {
    if (!selectedTemplate) {
      setTemplateError(true);
      templateSelectionRef?.current?.scrollIntoView({ behavior: 'smooth' });
      return;
    }
    const data = {
      email_batch: {
        ...(selectedCandidateIds && { candidate_ids: selectedCandidateIds }),
        ...(selectedPoolIds && { candidate_pools_ids: selectedPoolIds }),
        email_template_id: selectedTemplate.split(' -')[0],
        exclude_applicants: excludeApplicants,
        delay: stringToSeconds(delay),
        only_new: false,
        subject: subject,
        body: body,
        ...(attachments.length > 0 && { new_email_attachments: attachments }),
        filters: {
          rating: ['0', '100'],
          excludeApplicantsWithoutRating: false
        }
      }
    };
    handleAPIRequest({
      url: selectedCandidateIds
        ? '/api/v4/searches/bulk_email'
        : '/api/v4/candidate_pools/bulk_email',
      method: 'POST',
      body: data,
      isFormData: true,
      setLoaderState: setIsSendingEmails,
      onSuccess: () => {
        setIsSendingEmails(false);
        handleClose();
        setSnackbarState({
          message: 'Emails sent successfully',
          state: 'success'
        });
      },
      setSnackbarState: setSnackbarState,
      successMessage: { show: true, message: 'Emails sent successfully' },
      onFailure: () => {
        setIsSendingEmails(false);
      }
    });
  };

  const handleClose = () => {
    openedFromTableActions
      ? dispatch({ type: 'CLOSE_ACTION_ITEM', payload: 'email' })
      : dispatch({ type: 'SET_ACTION_MODAL_OPEN', payload: { name: 'email', isOpen: false } });
  };

  const handleSelectTemplate = (value: string) => {
    if (!value) {
      setSelectedTemplate(null);
      setBody('');
      setSubject('');
      setTemplateAttachments([]);
      return;
    }
    setBody(LOADING_TEMPLATE_HTML_STRING);
    const templateId = value.split(' -')[0];
    setSelectedTemplate(value);
    handleAPIRequest({
      url: `/api/v4/searches/email_templates/${templateId}`,
      method: 'GET',
      onSuccess: (response) => {
        setBody(response?.body);
        setSubject(response?.subject);
        setTemplateAttachments(response?.email_attachments);
        setTemplateError(false);
      },
      onFailure: () => {
        setBody('');
        setSubject('');
        setSelectedTemplate(null);
      },
      setSnackbarState: setSnackbarState,
      successMessage: { show: false, message: '' }
    });
  };

  return (
    <StyledModal
      isOpen={isOpen}
      disableEnforceFocus
      handleClose={handleClose}
      label="Email Candidates"
      modalTitle="Email Candidates"
      styleOverrides={{
        maxWidth: '900px',
        maxHeight: '1100px'
      }}
      subHeaderStyling={false}
    >
      <Box sx={sharedClasses.mainModalContent}>
        <Box role="form" id="email-candidates-form" aria-label="Email Candidates form">
          <Box sx={sharedClasses.actionModalFormLine} ref={templateSelectionRef}>
            <VirtualizeGrouped
              value={selectedTemplate}
              options={emailTemplates.data?.email_templates || []}
              onChange={handleSelectTemplate}
              styles={{
                ...sharedClasses.formAutocomplete,
                width: '600px',
                marginTop: '20px'
              }}
              label="Template"
              loading={emailTemplates.isLoading}
              loadingText="Loading template list..."
              error={templateError ? 'Please select a template' : undefined}
            />
          </Box>
          <Box sx={sharedClasses.actionModalFormLine}>
            <FormControlLabel
              control={
                <Checkbox
                  sx={sharedClasses.formCheckbox}
                  onChange={(e) => setExcludeApplicants(e.target.checked)}
                  value={excludeApplicants}
                />
              }
              label="Exclude applicants who have received this email template"
              sx={sharedClasses.checkboxLabel}
            />
          </Box>
          {selectedPoolIds && (
            <Box sx={sharedClasses.actionModalFormLine}>
              <FormControlLabel
                control={
                  <Checkbox
                    sx={sharedClasses.formCheckbox}
                    onChange={(e) => setSkipScoutTalentClients(e.target.checked)}
                    value={skipScoutTalentClients}
                    checked={skipScoutTalentClients}
                  />
                }
                label="Skip Scout Talent Clients"
                sx={sharedClasses.checkboxLabel}
              />
            </Box>
          )}
          <DelaySelectWrapper delay={delay} setDelay={setDelay} />
          <Box sx={sharedClasses.actionModalFormLine}>
            <FormTextField
              id="email-subject"
              label="Subject"
              value={subject}
              styles={{ width: '600px' }}
              onChange={(e) => setSubject(e.target.value)}
            />
          </Box>
          <Box sx={sharedClasses.modalSection}>
            <Box sx={sharedClasses.formLabel}>Body</Box>
            <HtmlEditor body={body} onBlur={setBody} />
            <Box sx={sharedClasses.placeholderText}>
              Available placeholders are: {emailTemplates.data?.placeholders}
            </Box>
          </Box>
          <AttachmentSelectorAndPreview
            attachments={attachments}
            setAttachments={setAttachments}
            templateAttachments={templateAttachments}
            selectedPoolIds={selectedPoolIds}
          />
          {showTotalSelectedCandidates && (
            <Box
              sx={{
                ...sharedClasses.modalSection,
                ...sharedClasses.sectionHeader
              }}
            >
              {selectedCandidateIds?.length || selectedPoolIds?.length} candidate
              {(selectedCandidateIds?.length || selectedPoolIds?.length || 0) > 1 ? 's' : ''}{' '}
              selected
            </Box>
          )}
        </Box>
      </Box>
      <ModalFooterButtons
        primaryButtonText="Send"
        primaryButtonCallback={handleSendEmails}
        primaryButtonID="send-candidate-emails"
        secondaryButtonText="Cancel"
        secondaryButtonCallback={handleClose}
        secondaryButtonID="cancel-candidate-emails"
        isLoading={isSendingEmails}
      />
    </StyledModal>
  );
};

const LOADING_TEMPLATE_HTML_STRING =
  '<p style="text-align: center;"><span style="font-size: 14pt;"><strong>Loading template...</strong></span></p>';

export default EmailCandidate;
