import React, { Dispatch, useState } from 'react';
import {
  Stack,
  Box,
  Button,
  Link,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer
} from '@mui/material';
import { Diversity3 } from '@mui/icons-material';
import StyledModal from '../Components/GenericModal/StyledModal';
import { useMutation } from '@tanstack/react-query';
import Api from './API';
import { styles } from './styles';
import { sharedClasses } from '../Components/CustomUIElements/sharedClasses';
import { CandidateAction, IDuplicatedCandidate } from './types';
import { classes as tableStyles } from '../../TableStyles';
import GenericDialog from '../Components/Modals/GenericDialog';
import { FormTextField } from '../Components/CustomUIElements/FormTextField';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import advanced from 'dayjs/plugin/advancedFormat';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced);

const BASE_URL = window.location.origin;

const headerStyle = (name: string) => {
  switch (name) {
    case 'ID':
      return {
        minWidth: '90px'
      };
    case 'Name':
      return { minWidth: '102px' };
    case 'Email':
      return { minWidth: '120px' };
    case 'Address':
      return { minWidth: '165px' };
    case 'Phone':
      return { minWidth: '93px' };
    case 'Created':
      return { minWidth: '123px' };
    default:
      return { minWidth: '90px' };
  }
};

export default function MergeCandidates({
  isOpen,
  handleClose,
  candidateId,
  candidates,
  dispatch
}: {
  isOpen: boolean;
  handleClose: () => void;
  candidateId: number;
  candidates?: IDuplicatedCandidate[];
  dispatch: Dispatch<CandidateAction>;
}) {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [confirmMerge, setConfirmMerge] = useState<string>('');

  const { mutate: mergeCandidates, isLoading: mergingCandidates } = useMutation({
    mutationFn: async () => {
      const { res } = await Api.mergeCandidates(candidateId);
      return res;
    },
    onSuccess: (res) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.success, state: 'success' }
      });
      handleClose();
      window.location.href = `${BASE_URL}${res.url}`;
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in merging candidates, ${error}`,
          state: 'error'
        }
      });
    }
  });

  const dialogDescription = (
    <Stack sx={{ color: '#000000', rowGap: 2 }}>
      <Box>
        This will <b>permanently</b> merge the candidates and all associated resources will belong
        to newest candidate
      </Box>
      <Stack sx={{ rowGap: 1, alignItems: 'center' }}>
        <Box sx={{ color: '#939393', fontSize: '14px', '& b': { color: '#000000' } }}>
          Type the word <b>MERGE</b> in the text field below to
          <Box>confirm the merge</Box>
        </Box>
        <FormTextField value={confirmMerge} onChange={(e) => setConfirmMerge(e.target.value)} />
      </Stack>
    </Stack>
  );

  return (
    <StyledModal
      isOpen={isOpen}
      label="Duplicated candidates modal"
      handleClose={handleClose}
      styleOverrides={{ ...styles.modalContainer, width: '775px' }}
      closeIconId="close-duplicated-candidates-modal-button"
    >
      <Stack sx={styles.modalContent}>
        <Stack sx={styles.modalTitle}>Duplicated candidates</Stack>
        <Stack flexDirection="row">
          <Button
            sx={sharedClasses.genericButtonSecondary}
            id="merge-candidate-button"
            startIcon={<Diversity3 />}
            onClick={() => setIsDialogOpen(true)}
          >
            Merge
          </Button>
        </Stack>
        <Stack>
          <Table stickyHeader aria-label="sticky table" sx={tableStyles.tableStyling}>
            <TableHead>
              <TableRow>
                {['ID', 'Name', 'Email', 'Address', 'Phone', 'Created'].map((title, index) => (
                  <TableCell sx={{ ...tableStyles.tableHeader, ...headerStyle(title) }} key={index}>
                    <Stack>{title}</Stack>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
          </Table>
          <TableContainer sx={{ maxHeight: 350 }}>
            <Table sx={tableStyles.tableStyling}>
              <TableBody>
                {candidates?.map((c) => (
                  <TableRow key={c.id} sx={{ td: { overflowWrap: 'anywhere', minWidth: '90px' } }}>
                    <TableCell>
                      <Link
                        id="candidate-id-duplicated-link"
                        href={`${BASE_URL}/admin/candidates/${c.id}`}
                        sx={{ color: 'black !important' }}
                        target="_blank"
                      >
                        {c.id}
                      </Link>
                    </TableCell>
                    <TableCell>{c.name}</TableCell>
                    <TableCell>{c.email}</TableCell>
                    <TableCell>
                      {c.address1.filter((a) => a).join(' ')}{' '}
                      {c.address2.filter((a) => a).join(' ')}
                    </TableCell>
                    <TableCell>{c.phone}</TableCell>
                    <TableCell>{dayjs(c.created_at).format('llll z')}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      </Stack>
      <GenericDialog
        isDialogOpen={isDialogOpen}
        setDialogOpen={setIsDialogOpen}
        title="Are you sure you want to merge these candidates?"
        description={dialogDescription}
        buttonCallback={() => mergeCandidates()}
        callbackLoading={mergingCandidates}
        buttonText="Merge"
        url=""
        disablePrimaryButton={confirmMerge !== 'MERGE'}
        primaryButtonId="confirm-merge-candidate-button"
        secondaryButtonId="cancel-merge-candidate-button"
      />
    </StyledModal>
  );
}
