import React, { Dispatch, useState } from 'react';
import { Avatar, Box, Button, Collapse, Skeleton, Stack } from '@mui/material';
import {
  EditNote as EditNoteIcon,
  Email as EmailIcon,
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
  Monitor as MonitorIcon,
  Person as PersonIcon
} from '@mui/icons-material';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from './API';
import { CandidateAction, IActivity } from './types';
import { sharedClasses } from '../Components/CustomUIElements/sharedClasses';
import dayjs from 'dayjs';
import dompurify from 'dompurify';
import AddRecord from './AddRecord';
import { styles } from './styles';
import { getStatusColor } from '../../NewEngage/helper';
import { ApplicationAction, IApplication } from '../Application/types';
import ApplicationApi from '../Application/API';

interface ActivitiesTabProps {
  candidateId?: number;
  dispatch: Dispatch<CandidateAction> | Dispatch<ApplicationAction>;
}
const toTitleCase = (sentence: string) => {
  return sentence
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

const ActivityIcon = ({ activity }: { activity: IActivity }) => {
  if (
    (activity.verb.includes('sent') || activity.verb.includes('enqueued')) &&
    !activity.verb.includes('sms')
  ) {
    return (
      <Avatar sx={{ background: '#B89FFF', width: 20, height: 20 }}>
        <EmailIcon sx={{ fontSize: '10px' }} />
      </Avatar>
    );
  } else if (activity.actor?.system) {
    return (
      <Avatar sx={{ background: '#666666', width: 20, height: 20 }}>
        <MonitorIcon sx={{ fontSize: '12px' }} />
      </Avatar>
    );
  } else {
    return (
      <Avatar sx={{ background: '#6892FF', width: 20, height: 20 }}>
        <PersonIcon sx={{ fontSize: '14px' }} />
      </Avatar>
    );
  }
};

export default function ActivitiesTab({ candidateId, dispatch }: ActivitiesTabProps) {
  const [addRecord, setAddRecord] = useState<boolean>(false);
  const [showMore, setShowMore] = useState<number[]>([]);

  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);

  const { data: activities, isLoading: loadingActivities } = useQuery({
    queryKey: ['activities'],
    queryFn: async () => {
      if (candidateId) {
        const { res } = await Api.getCandidateActivities(candidateId);
        return res;
      } else if (application) {
        const { res } = await ApplicationApi.getApplicationActivities(
          application?.job.id,
          application?.id
        );
        return res;
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting ${candidateId ? 'candidate' : 'application'} activities, ${error}`,
          state: 'error'
        }
      })
  });

  const { mutate: addCandidateNote, isLoading: addingCandidateNote } = useMutation({
    mutationFn: async ({ subject, body }: { subject: string; body: string }) => {
      if (candidateId) {
        const { res } = await Api.addCandidateNote(candidateId, { subject: subject, body: body });
        return res;
      }
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries(['activities']);
      dispatch({ type: 'SET_SNACKBAR', payload: { message: res.success, state: 'success' } });
      setAddRecord(false);
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in adding permanent record, ${error}`,
          state: 'error'
        }
      });
    }
  });

  return (
    <Stack>
      {candidateId && (
        <Stack sx={{ flexDirection: 'row', padding: '16px 0px', columnGap: 2 }}>
          <Button
            id="add-record-button"
            sx={sharedClasses.genericButtonSecondary}
            startIcon={<EditNoteIcon />}
            onClick={() => setAddRecord(true)}
          >
            Add record
          </Button>
        </Stack>
      )}
      {loadingActivities ? (
        [...Array(5)].map((_, index) => (
          <Skeleton
            key={index}
            sx={{
              height: '60px',
              borderRadius: '6px'
            }}
            animation="wave"
          />
        ))
      ) : (
        <Stack sx={{ rowGap: 2 }}>
          {activities?.activities.map((activity: IActivity, index: number) => {
            const isSms = activity.verb.includes('sent_sms');
            const previousActivityIsOnDifferentDate =
              index === 0 ||
              dayjs(activity.timestamp).format('L') !==
                dayjs(activities.activities[index - 1].timestamp).format('L');
            const activitiesDateHeader = () => {
              if (dayjs(activity.timestamp).format('L') === dayjs().format('L')) {
                return 'Today';
              } else if (
                dayjs(activity.timestamp).format('L') === dayjs().subtract(1, 'day').format('L')
              ) {
                return 'Yesterday';
              } else {
                return dayjs(activity.timestamp).format('DD MMM YYYY');
              }
            };
            return (
              <Stack key={activity.id}>
                {previousActivityIsOnDifferentDate && (
                  <Stack sx={{ color: '#666666', fontWeight: 600, padding: '8px 0px' }}>
                    {activitiesDateHeader()}
                  </Stack>
                )}
                <Stack key={activity.id} sx={{ flexDirection: 'row', columnGap: 2 }}>
                  <ActivityIcon activity={activity} />
                  <Stack sx={{ flexGrow: 1 }}>
                    <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
                      <Box
                        dangerouslySetInnerHTML={{
                          __html:
                            dompurify.sanitize(`<b>${activity.actor?.name || ''}</b>`) +
                            ' ' +
                            activity.activity_html
                              .replace('Added note', 'Added permanent record') // needed to rename the existing note on candidates to permanent record
                              .replace('with no template', '')
                        }}
                        sx={{ color: '#333333' }}
                      />
                      <Stack sx={styles.timestamp}>{dayjs(activity.timestamp).format('LT')}</Stack>
                    </Stack>
                    {activity.email_status && (
                      <Stack
                        sx={{
                          ...styles.emailStatus,
                          color: getStatusColor(toTitleCase(activity.email_status))[0],
                          background: `${getStatusColor(toTitleCase(activity.email_status))[1]}`
                        }}
                      >
                        {activity.email_status}
                      </Stack>
                    )}
                    {!activity.verb.includes('Updated the note') &&
                      (activity.verb.includes('sent') ||
                        activity.verb.includes('enqueued') ||
                        activity.verb.includes('note') ||
                        activity.verb.includes('permanent record')) && (
                        <Stack
                          sx={{
                            flexDirection: 'row',
                            color: '#939393',
                            width: 'fit-content',
                            cursor: 'pointer'
                          }}
                          onClick={() => {
                            if (showMore.includes(activity.id)) {
                              setShowMore(showMore.filter((s) => s !== activity.id));
                            } else {
                              setShowMore([...showMore, activity.id]);
                            }
                          }}
                        >
                          <Stack sx={{ fontSize: '12px' }}>Show</Stack>
                          {showMore.includes(activity.id) ? (
                            <ExpandLessIcon sx={{ fontSize: '1rem' }} />
                          ) : (
                            <ExpandMoreIcon sx={{ fontSize: '1rem' }} />
                          )}
                        </Stack>
                      )}
                  </Stack>
                </Stack>
                <Collapse
                  in={showMore.includes(activity.id)}
                  sx={{ padding: '8px 0px', paddingLeft: 4 }}
                >
                  {activity.verb.includes('note') ||
                  activity.verb.includes('permanent record') ||
                  activity.verb.includes('SMS') ? (
                    <Stack sx={styles.showNoteContainer}>
                      {activity.indirect_object.subject && (
                        <Stack sx={{ fontWeight: 'bold', color: '#333333' }}>
                          {activity.indirect_object.subject}
                        </Stack>
                      )}
                      <Stack sx={{ color: '#333333' }}>{activity.indirect_object.body}</Stack>
                    </Stack>
                  ) : (
                    <Stack sx={styles.showNoteContainer}>
                      {!isSms && (
                        <Stack sx={{ fontWeight: 'bold', color: '#333333' }}>
                          {activity.indirect_object.subject}
                        </Stack>
                      )}
                      <Box
                        dangerouslySetInnerHTML={{
                          __html: dompurify.sanitize(`${activity.indirect_object.body || ''}`)
                        }}
                        sx={{ color: '#333333', overflowY: 'auto' }}
                      />
                    </Stack>
                  )}
                </Collapse>
              </Stack>
            );
          })}
        </Stack>
      )}
      {candidateId && addRecord && (
        <AddRecord
          isOpen={addRecord}
          handleClose={() => setAddRecord(false)}
          addRecord={addCandidateNote}
          addingRecord={addingCandidateNote}
        />
      )}
    </Stack>
  );
}
