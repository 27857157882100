import React, { useState } from 'react';
import { classes } from './useStyles';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import IosShareIcon from '@mui/icons-material/IosShare';
import CircularProgress from '@mui/material/CircularProgress';
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import Tooltip from '@mui/material/Tooltip';
import Api from '../API';
import { IExportCsvProps, IOptionType } from '../types';
import moment from 'moment';

export default function ExportCSV({
  filters,
  order,
  orderBy,
  archived,
  sortableColumns,
  search,
  jobStatusFeature
}: IExportCsvProps) {
  const [isLoading, setIsLoading] = useState(0);
  const [isError, setIsError] = useState('');

  const activeColumns = {};
  sortableColumns.forEach((column) => {
    activeColumns[column.id] = column.id === 'status' && !jobStatusFeature ? false : column.enabled;
  });

  const handleClick = async () => {
    setIsLoading(1);
    try {
      const data = await Api.getCSVExport(
        {
          'filter[archived]': archived,
          sort_by: orderBy,
          sort_direction: order,
          'filter[location]': filters.location.map((location: IOptionType) => location.name),
          'filter[state]': filters.state.map((state: IOptionType) => state.value),
          'filter[entity_ids]': filters.entity.map((entity: IOptionType) => entity.id),
          'filter[start_published_at]':
            filters.published[0] && moment(filters.published[0]).format('YYYY-MM-DD'),
          'filter[end_published_at]':
            filters.published[1] && moment(filters.published[1]).format('YYYY-MM-DD'),
          'filter[start_expires_at]':
            filters.expiry[0] && moment(filters.expiry[0]).format('YYYY-MM-DD'),
          'filter[end_expires_at]':
            filters.expiry[1] && moment(filters.expiry[1]).format('YYYY-MM-DD'),
          activeColumns: JSON.stringify(activeColumns),
          search: search
        },
      );
      setIsLoading(2);
      setTimeout(() => {
        setIsLoading(0);
      }, 6000);
    } catch (error) {
      console.log(error);
      setIsError('An error occured while exporting the CSV');
      setIsLoading(3);
    }
  };

  const showIcons = () => {
    switch (isLoading) {
      case 0:
        return <IosShareIcon />;
      case 1:
        return <CircularProgress size={24} />;
      case 2:
        return <DoneIcon />;
      case 3:
        return <ErrorIcon />;
      default:
        return <IosShareIcon />;
    }
  };

  return (
    <Tooltip title="Export CSV" placement="top" arrow>
      <Box>
        <Tooltip
          open={isLoading === 2}
          placement="top"
          title={isError || 'A CSV download link has been sent to your email'}
          arrow
        >
          <IconButton
            id={`export-jobs-csv-${archived ? 'archived' : 'current'}-button`}
            sx={classes.iconButton}
            onClick={handleClick}
          >
            {showIcons()}
          </IconButton>
        </Tooltip>
      </Box>
    </Tooltip>
  );
}
