import React, { useState, useRef, Dispatch } from 'react';
import { Autocomplete, Stack, TextField } from '@mui/material';
import StyledModal from '../../../Components/GenericModal/StyledModal';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../API';
import { ApplicationAction, ApplicationState, IApplication } from '../../types';
import { ModalType } from '../../config';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../../Components/GenericModal/ModalFooterButtons';
import { styles } from '../styles';
import { FormTextField } from '../../../Components/CustomUIElements/FormTextField';
import { TCandidate } from '../../../Candidates/types';
import { validateEmail } from '../../../Candidates/EditCandidate';
import { scrollToElement } from '../../../utils/scroll-to-element';

export default function Hronboard({
  ApplicationState,
  dispatch
}: {
  ApplicationState: ApplicationState;
  dispatch: Dispatch<ApplicationAction>;
}) {
  const { modalsOpen } = ApplicationState;
  const queryClient = useQueryClient();
  const application = queryClient.getQueryData<IApplication>(['application']);
  const [title, setTitle] = useState<string>('');
  const [candidate, setCandidate] = useState<TCandidate | null>(application?.candidate || null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [hroUser, setHroUser] = useState<string | null>(null);
  const [company, setCompany] = useState<string | null>(null);
  const [location, setLocation] = useState<string | null>(null);
  const [approvalNumber, setApprovalNumber] = useState<string>('');
  const [jobReferenceNumber, setJobReferenceNumber] = useState<string>(
    application?.job?.reference || ''
  );
  const [positionTitle, setPositionTitle] = useState<string>(application?.job?.title || '');
  const [glCode, setGlCode] = useState<string>('');
  const [state, setState] = useState<string | null>(null);

  const fieldRefs = {
    location: useRef<HTMLInputElement>(null),
    firstName: useRef<HTMLInputElement>(null),
    lastName: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    phone: useRef<HTMLInputElement>(null)
  };

  const { data: HronboardData, isLoading: loadingHronboardData } = useQuery({
    queryKey: ['HR Onboard data'],
    queryFn: async () => {
      if (application) {
        const { res } = await Api.getHronboardData(application.id);
        return res;
      }
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting HR Onboard data, ${error}`,
          state: 'error'
        }
      })
  });

  const { mutate: hronboardExport, isLoading: exportingHronboard } = useMutation({
    mutationFn: async () => {
      if (application && candidate) {
        const { res } = await Api.hronboardExport(application.id, {
          position_title: positionTitle,
          candidate_title: candidate.title,
          candidate_first_name: candidate.firstname,
          candidate_last_name: candidate.lastname,
          candidate_email: candidate.email,
          candidate_mobile_no: candidate.phone1,
          candidate_phone_no: candidate.phone2,
          candidate_addr_street1: candidate?.address_details?.street1 || '',
          candidate_addr_street2: candidate?.address_details?.street2 || '',
          candidate_addr_suburb: candidate?.address_details?.suburb || '',
          candidate_addr_state: state,
          candidate_addr_postcode: candidate?.address_details?.postcode || '',
          hro_user: hroUser,
          company: company,
          location: location,
          requisition_id: approvalNumber,
          job_id: application.job.id,
          gl_code: glCode
        });
        return res;
      }
    },
    onSuccess: (res: { message: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.message, state: 'success' }
      });
      handleClose();
    },
    onError: (error: { message: string }) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in exporting to HR Onboard, ${error.message}`,
          state: 'error'
        }
      });
    }
  });

  const validateInputs = () => {
    if (!candidate) return false;
    setErrors({});
    const inputErrors: Record<string, string> = {};
    if (!location) inputErrors.location = 'Please select a location';
    if (!candidate.firstname.trim()) inputErrors.firstName = 'First name can not be empty';
    if (!candidate.lastname.trim()) inputErrors.lastName = 'Last name can not be empty';
    if (!validateEmail(candidate.email)) inputErrors.email = 'Please enter a proper email address';
    if (!candidate.phone1.trim()) inputErrors.phone = 'Please enter a mobile number';
    setErrors(inputErrors);
    if (inputErrors.location) {
      scrollToElement(fieldRefs.location);
    } else if (inputErrors.firstName) {
      scrollToElement(fieldRefs.firstName);
    } else if (inputErrors.lastName) {
      scrollToElement(fieldRefs.lastName);
    } else if (inputErrors.email) {
      scrollToElement(fieldRefs.email);
    } else if (inputErrors.phone) {
      scrollToElement(fieldRefs.phone);
    }
    return !Object.keys(inputErrors).length;
  };

  const handleClose = () => dispatch({ type: 'SET_MODALS_OPEN', payload: null });
  if (!candidate) return null;

  return (
    <StyledModal
      isOpen={modalsOpen === ModalType.HRONBOARD}
      label="HROnboard export modal"
      handleClose={handleClose}
      styleOverrides={styles.modalStyleOverrides}
    >
      <Stack sx={styles.modalContainer}>
        <Stack sx={styles.modalTitle}>Export to HROnboard</Stack>
        <Stack sx={{ overflow: 'auto', rowGap: 2 }}>
          <Stack
            sx={{
              fontWeight: 'bold',
              color: '#666666',
              fontSize: '18px'
            }}
          >
            HR Onboarding
          </Stack>
          <Autocomplete
            disablePortal
            options={HronboardData?.hro_users || []}
            value={hroUser}
            sx={{ ...sharedClasses.formAutocomplete, width: '48%', marginTop: 3, flexGrow: 1 }}
            loading={loadingHronboardData}
            loadingText="Loading users..."
            ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="HRO user"
                placeholder="Please select"
                InputLabelProps={{ shrink: true }}
                sx={styles.autoCompleteTextfield}
              />
            )}
            onChange={(event, value) => setHroUser(value)}
          />
          <Autocomplete
            disablePortal
            options={HronboardData?.companies || []}
            value={company}
            sx={{ ...sharedClasses.formAutocomplete, width: '48%', marginTop: 3, flexGrow: 1 }}
            loading={loadingHronboardData}
            loadingText="Loading companies..."
            ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Company"
                placeholder="Please select"
                InputLabelProps={{ shrink: true }}
                sx={styles.autoCompleteTextfield}
              />
            )}
            onChange={(event, value) => setCompany(value)}
          />
          <Autocomplete
            disablePortal
            options={HronboardData?.locations || []}
            value={location}
            sx={{ ...sharedClasses.formAutocomplete, width: '48%', marginTop: 3, flexGrow: 1 }}
            loading={loadingHronboardData}
            loadingText="Loading locations..."
            ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Location"
                required
                placeholder="Please select"
                InputLabelProps={{ shrink: true }}
                sx={styles.autoCompleteTextfield}
                helperText={errors.location}
                ref={fieldRefs.location}
              />
            )}
            onChange={(event, value) => setLocation(value)}
          />
          <Stack
            sx={{
              fontWeight: 'bold',
              color: '#666666',
              fontSize: '18px'
            }}
          >
            Migrate system fields from
          </Stack>
          <FormTextField
            label="Approval number"
            value={approvalNumber}
            styles={{ width: '48%' }}
            onChange={(e) => setApprovalNumber(e.target.value)}
          />
          <FormTextField
            label="Job reference number"
            value={jobReferenceNumber}
            styles={{ width: '48%' }}
            onChange={(e) => setJobReferenceNumber(e.target.value)}
          />
          <Stack
            sx={{
              fontWeight: 'bold',
              color: '#666666',
              fontSize: '18px'
            }}
          >
            Job information
          </Stack>
          <FormTextField
            label="Position title"
            value={positionTitle}
            styles={{ width: '48%' }}
            onChange={(e) => setPositionTitle(e.target.value)}
          />
          <FormTextField
            label="GL code"
            value={glCode}
            styles={{ width: '48%' }}
            onChange={(e) => setGlCode(e.target.value)}
          />
          <Stack
            sx={{
              fontWeight: 'bold',
              color: '#666666',
              fontSize: '18px'
            }}
          >
            Candidate information
          </Stack>
          <FormTextField
            label="Title"
            value={title}
            styles={{ width: '48%' }}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Stack sx={styles.modalDoubleColumn}>
            <FormTextField
              label="First name"
              value={candidate.firstname || ''}
              required
              fullWidth
              onChange={(e) => setCandidate({ ...candidate, firstname: e.target.value })}
              error={errors.firstName}
              innerRef={fieldRefs.firstName}
            />
            <FormTextField
              label="Last name"
              value={candidate.lastname || ''}
              required
              fullWidth
              onChange={(e) => setCandidate({ ...candidate, lastname: e.target.value })}
              error={errors.lastName}
              innerRef={fieldRefs.lastName}
            />
          </Stack>
          <FormTextField
            label="Email"
            value={candidate.email}
            styles={{ width: '48%' }}
            required
            onChange={(e) => setCandidate({ ...candidate, email: e.target.value })}
            error={errors.email}
            innerRef={fieldRefs.email}
          />
          <Stack sx={styles.modalDoubleColumn}>
            <FormTextField
              label={'Phone'}
              value={candidate.phone1}
              onChange={(e) => setCandidate({ ...candidate, phone1: e.target.value })}
              required
              fullWidth
              error={errors.phone}
              innerRef={fieldRefs.phone}
            />
            <FormTextField
              label={'Mobile'}
              value={candidate.phone2}
              onChange={(e) => setCandidate({ ...candidate, phone2: e.target.value })}
              fullWidth
            />
          </Stack>
          <Stack sx={styles.modalDoubleColumn}>
            <FormTextField
              label="Street address"
              value={candidate?.address_details?.street1 || ''}
              fullWidth
              onChange={(e) =>
                setCandidate({
                  ...candidate,
                  address_details: { ...candidate.address_details, street1: e.target.value }
                })
              }
            />
            <FormTextField
              label="Street address cont'd"
              value={candidate?.address_details?.street2 || ''}
              fullWidth
              onChange={(e) =>
                setCandidate({
                  ...candidate,
                  address_details: { ...candidate.address_details, street2: e.target.value }
                })
              }
            />
          </Stack>
          <Stack sx={{ ...styles.modalDoubleColumn, marginRight: '2px' }}>
            <FormTextField
              label="Suburb/Town"
              value={candidate?.address_details?.suburb || ''}
              fullWidth
              onChange={(e) =>
                setCandidate({
                  ...candidate,
                  address_details: { ...candidate.address_details, suburb: e.target.value }
                })
              }
            />
            <Autocomplete
              disablePortal
              options={HronboardData?.states || []}
              value={state}
              sx={{ ...sharedClasses.formAutocomplete, width: '100%', marginTop: 3 }}
              loading={loadingHronboardData}
              loadingText="Loading states..."
              ListboxProps={{ style: sharedClasses.autoCompleteListStyles }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="State, region or province"
                  placeholder="Please select"
                  InputLabelProps={{ shrink: true }}
                  sx={styles.autoCompleteTextfield}
                />
              )}
              onChange={(event, value) => setState(value)}
            />
          </Stack>
          <FormTextField
            label="Postcode"
            value={candidate?.address_details?.postcode || ''}
            styles={{ width: '48%' }}
            onChange={(e) =>
              setCandidate({
                ...candidate,
                address_details: { ...candidate.address_details, postcode: e.target.value }
              })
            }
          />
        </Stack>
        <ModalFooterButtons
          primaryButtonText="Export"
          primaryButtonCallback={() => validateInputs() && hronboardExport()}
          isLoading={exportingHronboard}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          primaryButtonMinWidth="95px"
        />
      </Stack>
    </StyledModal>
  );
}
