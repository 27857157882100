import React, { useEffect, useMemo, useState } from 'react';
import { FormControl, FormLabel, TextField, Grid, Typography, IconButton } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import throttle from 'lodash/throttle';
import parse from 'autosuggest-highlight/parse';

interface AddressInputProps {
  requiredRef: React.Ref<any>;
  classes: any;
  value: any;
  setValue: (value: any) => void;
  activeError: boolean;
  answers: any;
  setAnswers: (answers: any) => void;
  viewJobsAd?: boolean;
}

function loadScript(src, position, id) {
  if (!position) {
    return;
  }

  const script = document.createElement('script');
  script.setAttribute('async', '');
  script.setAttribute('id', id);
  script.src = src;
  position.appendChild(script);
}

const autocompleteService = { current: null };
const placesService = { current: null };

const COUNTRY_ABBR: Record<string, string> = {
  Australia: 'au',
  Canada: 'ca',
  'New Zealand': 'nz'
  // Add more countries as necessary
};

const AddressInput: React.FC<AddressInputProps> = ({
  requiredRef,
  classes,
  value,
  setValue,
  activeError,
  answers,
  setAnswers,
  viewJobsAd
}) => {
  const [inputValue, setInputValue] = React.useState(
    [answers.address, answers.city, answers.state].filter((cur) => cur).join(', ')
  );
  const [options, setOptions] = useState([]);
  const loaded = React.useRef(false);

  if (typeof window !== 'undefined' && !loaded.current) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        'https://maps.googleapis.com/maps/api/js?key=AIzaSyAF83260uv1i9sjdtsQkK-skJ8fgGCe9_4&libraries=places',
        document.querySelector('head'),
        'google-maps'
      );
    }

    loaded.current = true;
  }

  const fetch = useMemo(
    () =>
      throttle(
        (request, callback) => {
          autocompleteService.current.getPlacePredictions(request, callback);
        },
        1000,
        { trailing: true, leading: false }
      ),
    []
  );

  useEffect(() => {
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }

    if (viewJobsAd && window.google && !placesService.current) {
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement('div')
      );
    }

    if (!autocompleteService.current) return undefined;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch(
      {
        input: inputValue,
        componentRestrictions: {
          country: COUNTRY_ABBR[answers.country] || 'au'
        },
        token: new window.google.maps.places.AutocompleteSessionToken(),
        types: ['address']
      },
      (results) => {
        if (active) {
          let newOptions = results ? [...(value ? [value] : []), ...results] : [value];
          setOptions(newOptions);
        }
      }
    );

    if (value && value.terms) {
      const [country, state, city, ...address] = value.terms.reverse();
      if (viewJobsAd) {
        if (inputValue !== answers.address) {
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            address: inputValue
          }));
        }
      } else {
        setAnswers((prevAnswers) => ({
          ...prevAnswers,
          city: city.value,
          state: state.value,
          address: address
            .reverse()
            .map((cur) => cur.value)
            .join(' ')
        }));
      }
    }

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch, answers.country]);

  function removeCountryFromAddress(formattedAddress: string) {
    const addressComponents = formattedAddress.split(',');

    if (addressComponents.length < 2) {
      return formattedAddress;
    }

    const addressWithoutCountry = addressComponents
      .slice(0, -1)
      .map((component) => component.trim());

    return addressWithoutCountry.join(', ');
  }

  const handlePlaceSelect = (newValue) => {
    if (newValue && placesService.current) {
      const request = {
        placeId: newValue.place_id,
        fields: ['formatted_address', 'place_id']
      };

      placesService.current.getDetails(request, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          const formattedAddress = removeCountryFromAddress(place.formatted_address);
          setValue(newValue);
          setInputValue(formattedAddress);
        } else {
          console.error('Error fetching place details:', status);
        }
      });
    }
  };

  useEffect(() => {
    if (!inputValue && inputValue !== answers.address) {
      setAnswers({
        ...answers,
        city: '',
        state: '',
        address: ''
      });
    }
  }, [inputValue, setAnswers, answers]);

  return (
    <FormControl
      ref={requiredRef}
      fullWidth={true}
      className={`${classes.formControl} ${classes.medium}`}
    >
      <FormLabel required={true} className={classes.label}>
        {viewJobsAd ? 'Job address' : 'Enter a street address'}
      </FormLabel>
      {!viewJobsAd && (
        <span className={classes.subLabel}>
          We won’t share your street address. We use your location to find candidates in your area.
        </span>
      )}
      <Autocomplete
        forcePopupIcon={false}
        fullWidth={true}
        getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
        classes={{ root: classes.autoComplete, popper: classes.autoCompletePopper }}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        onChange={(event, newValue) => {
          setOptions(newValue ? [newValue, ...options] : options);
          viewJobsAd ? handlePlaceSelect(newValue) : setValue(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={activeError}
            value={value}
            placeholder="Select an address"
            variant="outlined"
            fullWidth
            InputProps={{
              ...params.InputProps,
              classes: {
                root: classes.autoCompleteRoot
              },
              endAdornment: (
                <React.Fragment>
                  {activeError && (
                    <div className={classes.error}>
                      <ErrorIcon color="error" />
                      <span>This field is required</span>
                    </div>
                  )}
                  {inputValue && (
                    <IconButton
                      className={classes.autoCompleteClose}
                      onClick={() => {
                        setAnswers({ ...answers, address: '', city: '', state: '' });
                        setValue(null);
                        setInputValue('');
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                </React.Fragment>
              )
            }}
          />
        )}
        renderOption={(option) => {
          if (!option.structured_formatting) return null;
          const matches = option.structured_formatting.main_text_matched_substrings;
          const parts = parse(
            option.structured_formatting.main_text,
            matches.map((match) => [match.offset, match.offset + match.length])
          );

          return (
            <Grid container alignItems="center">
              <Grid item>
                <LocationOnIcon className={classes.icon} />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          );
        }}
      />
    </FormControl>
  );
};

export default AddressInput;
