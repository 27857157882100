import React from 'react';
import { Link, Stack, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { styles } from '../styles';
import { useQueryClient } from '@tanstack/react-query';
import { IIntegrationsResponse } from '../../types';

export default function SparkHireRecord() {
  const queryClient = useQueryClient();
  const integrationsData = queryClient.getQueryData<IIntegrationsResponse>(['integrations']);

  if (!integrationsData?.sparkhire_record) return null;
  return (
    <Stack sx={styles.integrationDetails}>
      <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
        <Stack sx={{ fontSize: '16px', fontWeight: 600 }}>Spark Hire Interview</Stack>
      </Stack>
      <Table sx={styles.integrationDetailsTable}>
        <TableHead>
          <TableRow>
            {['Status', 'Share link'].map((title, index) => (
              <TableCell sx={styles.integrationDetailsTableHeader} key={index}>
                <Stack>{title}</Stack>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>{integrationsData.sparkhire_record.status}</TableCell>
            <TableCell>
              {integrationsData?.sparkhire_record?.share_link ? (
                <Link
                  href={integrationsData.sparkhire_record.share_link}
                  target="_blank"
                  style={{
                    color: 'inherit',
                    width: 'fit-content',
                    textDecoration: 'underline'
                  }}
                >
                  Watch Here
                </Link>
              ) : (
                'Candidate needs to complete the interview first'
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Stack>
  );
}
