import React, { Dispatch, SetStateAction, useState } from 'react';
import StyledModal from '../../NewUI/Components/GenericModal/StyledModal';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  InputAdornment,
  OutlinedInput,
  Stack,
  TextField
} from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import Api from '../API';
import { IAttendee, ScoutCalendarAction, ScoutCalendarState } from '../types';
import { sharedClasses } from '../../NewUI/Components/CustomUIElements/sharedClasses';
import ModalFooterButtons from '../../NewUI/Components/GenericModal/ModalFooterButtons';
import SelectField from '../../NewUI/Components/CustomUIElements/SelectField';
import { FormTextField } from '../../NewUI/Components/CustomUIElements/FormTextField';
import { DELAY_OPTIONS } from '../../NewUI/constants';
import HtmlEditor from '../../NewUI/Components/Utilities/HtmlEditor';
import AttachmentSelectorAndPreview from '../../NewUI/Components/CustomUIElements/AttachmentSelectorAndPreview';
import { FileWithPath } from 'react-dropzone';
import { classes } from './styles';
import { classes as emailStyle } from '../../NewUI/Job/Applications/styles';
import VirtualizeGrouped, {
  formatOptions
} from '../../NewUI/Components/CustomUIElements/VirtualizedGroupedAutocomplete';
import { stringToSeconds } from '../../NewUI/Components/Utilities/stringToSeconds';
import { serialize } from 'object-to-formdata';
import { MultilineFormTextField } from '../../NewUI/Components/CustomUIElements/FormTextField';
import { ISmsTemplate } from '../../NewUI/Job/Applications/Modals/SMSCandidates';
import { IApplicationStatus } from '../../NewUI/Job/types';
import SelectedCandidatesTable from './SelectedCandidatesTable';
import API from '../API';

export default function ActionModal({
  ScoutCalendarState,
  dispatch,
  action,
  handleClose,
  selectedCandidates,
  setSelectedCandidates
}: {
  ScoutCalendarState: ScoutCalendarState;
  dispatch: Dispatch<ScoutCalendarAction>;
  action: string;
  handleClose: () => void;
  selectedCandidates: IAttendee[];
  setSelectedCandidates: Dispatch<SetStateAction<number[]>>;
}) {
  const [excludeApplicants, setExcludeApplicants] = useState<boolean>(false);
  const [delay, setDelay] = useState<string>('None');
  const [subject, setSubject] = useState<string>('');
  const [body, setBody] = useState<string>('');
  const [placeholders, setPlaceholders] = useState<string>('');
  const [attachments, setAttachments] = useState<FileWithPath[]>([]);
  const [templateAttachments, setTemplateAttachments] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [smsTemplate, setSmsTemplate] = useState<ISmsTemplate | null>(null);
  const [smsMessage, setSmsMessage] = useState<string>('');
  const [smsTemplateName, setSmsTemplateName] = useState<string>('');
  const [smsTemplateError, setSmsTemplateError] = useState<string>('');
  const [selectedStatus, setSelectedStatus] = useState<IApplicationStatus | null>(null);

  const { createEventObject } = ScoutCalendarState;
  const queryClient = useQueryClient();

  const { data: emailTemplates, isLoading: loadingEmailTemplates } = useQuery({
    queryKey: ['email templates'],
    queryFn: async () => {
      const {
        res: { email_templates, placeholders }
      } = await Api.getEmailTemplates();
      placeholders && setPlaceholders(placeholders);
      return formatOptions(email_templates);
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting email templates, ${error}`,
          state: 'error'
        }
      });
    },
    enabled: action === 'Email'
  });

  useQuery({
    queryKey: [selectedTemplate],
    queryFn: async () => {
      if (!selectedTemplate) return;
      const { res } = await Api.getEmailTemplate(Number(selectedTemplate.split(' -')[0]));
      return res;
    },
    onSuccess: (emailTemplate) => {
      setSubject(emailTemplate.subject);
      setBody(emailTemplate.body);
      setTemplateAttachments(emailTemplate.email_attachments);
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting template data, ${error}`,
          state: 'error'
        }
      }),
    enabled: !!selectedTemplate
  });

  const { mutate: sendEmails, isLoading: sendingEmails } = useMutation({
    mutationFn: async () => {
      const data = {
        email_batch: {
          applications_ids: selectedCandidates.map((c) => c.application_id),
          email_template_id: Number(selectedTemplate?.split(' -')[0]),
          subject,
          body,
          delay: stringToSeconds(delay),
          only_new: excludeApplicants,
          ...(attachments.length > 0 && { new_email_attachments: attachments })
        }
      };
      const { res } = await API.sendEmailBatchesToCandidates(createEventObject.eventId, data);
      return res;
    },
    onSuccess: () => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `Candidates emailed successfully`,
          state: 'success'
        }
      });
      handleClose();
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error in sending email to the candidates', ${error}`,
          state: 'error'
        }
      })
  });

  const { data: smsTemplates, isFetching: fetchingSmsTemplates } = useQuery({
    queryKey: ['sms templates'],
    queryFn: async () => {
      const {
        res: { sms_templates }
      } = await Api.getSmsTemplates();
      return sms_templates as ISmsTemplate[];
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `${error}`, state: 'error' }
      }),
    initialData: [],
    enabled: action === 'SMS'
  });

  const { mutate: saveTemplate, isLoading: savingTemplate } = useMutation({
    mutationFn: async () => {
      const { res } = await Api.saveSmsTemplate(smsTemplateName, smsMessage);
      return res;
    },
    onError: (error) =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: `${error}`, state: 'error' }
      }),
    onSuccess: (res) => {
      queryClient.setQueryData(['sms templates'], [...smsTemplates, res]);
      setSmsTemplate(res);
      setSmsTemplateName('');
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: 'Template saved',
          state: 'success'
        }
      });
    }
  });

  const handleSaveTemplate = () => {
    if (smsTemplates?.find((template) => template.title === smsTemplateName)) {
      setSmsTemplateError('Template name already exists');
      return;
    }
    saveTemplate();
  };

  const { mutate: sendSms, isLoading: sendingSms } = useMutation({
    mutationFn: async () => {
      const { res } = await Api.smsCandidates(
        createEventObject.eventId,
        selectedCandidates.map((c) => c.application_id),
        smsMessage
      );
      if (res.success !== 0) {
        dispatch({
          type: 'SET_SNACKBAR',
          payload: {
            message: 'Candidates SMS message sent successfully',
            state: 'success'
          }
        });
        handleClose();
      } else {
        dispatch({
          type: 'SET_SNACKBAR',
          payload: {
            message: 'There was an error in sending SMS to the candidates',
            state: 'error'
          }
        });
      }
      return res;
    }
  });

  const { data: statuses, isLoading: loadingStatuses } = useQuery({
    queryKey: ['application statuses'],
    queryFn: async () => {
      const {
        res: { event_application_statuses }
      } = await Api.getEventApplicationStatuses(createEventObject.eventId);
      return event_application_statuses;
    },
    onError: (error) => {
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: `There was an error getting application statuses, ${error}`,
          state: 'error'
        }
      });
    },
    enabled: action === 'Change status'
  });

  const { mutate: changeStatus, isLoading: changingStatus } = useMutation({
    mutationFn: async () => {
      if (!selectedStatus) return;
      const { res } = await Api.changeApplicationStatuses(
        createEventObject.eventId,
        selectedStatus.id,
        selectedCandidates.map((c) => c.application_id)
      );
      return res;
    },
    onError: () =>
      dispatch({
        type: 'SET_SNACKBAR',
        payload: {
          message: 'There was an error in changing status of the candidates',
          state: 'error'
        }
      }),
    onSuccess: (res) => {
      if (!res) return;
      dispatch({
        type: 'SET_SNACKBAR',
        payload: { message: res.success, state: 'success' }
      });
      handleClose();
    }
  });

  const validateInputs = () => {
    if (action === 'Email') {
      return selectedTemplate && subject.trim() && body.trim();
    } else if (action === 'SMS') {
      return smsMessage.trim();
    } else {
      return selectedStatus;
    }
  };

  const buttonCallback = () => {
    if (validateInputs()) {
      if (action === 'Email') {
        return sendEmails;
      } else if (action === 'SMS') {
        return sendSms;
      } else {
        return changeStatus;
      }
    }
  };

  const candidates = selectedStatus
    ? selectedCandidates.map((s) => ({ ...s, status_details: selectedStatus }))
    : selectedCandidates;

  return (
    <StyledModal
      isOpen={!!action}
      label={`${action} event modal`}
      handleClose={handleClose}
      styleOverrides={{
        maxHeight: '700px',
        width: '800px',
        padding: '40px'
      }}
    >
      <Stack sx={{ width: '100%', height: '100%', rowGap: 2 }}>
        <Stack sx={{ fontWeight: 'bold', color: '#084D6D', fontSize: '25px' }}>
          {action} {action !== 'Change status' && 'candidates'}
        </Stack>
        <Stack sx={classes.actionModalContent}>
          {action === 'Email' && (
            <>
              <VirtualizeGrouped
                value={selectedTemplate}
                options={emailTemplates || []}
                onChange={(value) => {
                  !value && setBody('');
                  setSelectedTemplate(value);
                }}
                styles={{
                  ...sharedClasses.formAutocomplete,
                  width: '600px',
                  marginTop: '20px'
                }}
                label="Template"
                loading={loadingEmailTemplates}
                loadingText="Loading template list..."
                required
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={sharedClasses.formCheckbox}
                    checked={excludeApplicants}
                    onClick={() => setExcludeApplicants(!excludeApplicants)}
                    disableRipple
                  />
                }
                label="Exclude applicants who have received this email template"
                sx={sharedClasses.checkboxLabel}
              />
              <Stack sx={{ color: '#666666', fontWeight: 'bold' }}>Email details</Stack>
              <SelectField
                label="Delay"
                options={DELAY_OPTIONS}
                value={delay}
                onChange={setDelay}
                styles={{ width: '40%' }}
              />
              <FormTextField
                label="Subject"
                value={subject}
                styles={{ width: '40%', input: { margin: '3px !important' } }}
                onChange={(e) => setSubject(e.target.value)}
                required
              />
              <Stack sx={{ paddingBottom: 2 }}>
                <Stack sx={emailStyle.formLabel}>Body</Stack>
                <HtmlEditor body={body} onChange={setBody} />
                <Stack sx={emailStyle.placeholderText}>
                  Available placeholders are: {placeholders}
                </Stack>
              </Stack>
              <AttachmentSelectorAndPreview
                attachments={attachments}
                setAttachments={setAttachments}
                templateAttachments={templateAttachments}
                styleOverrides={{ margin: 'unset' }}
              />
            </>
          )}
          {action === 'SMS' && (
            <>
              <Stack sx={{ color: '#939393', fontWeight: 'bold', paddingBottom: 3 }}>Message</Stack>
              <Autocomplete
                disablePortal
                id="add-to-event-sms-template-select"
                options={smsTemplates}
                getOptionLabel={(option) => option.title}
                value={smsTemplate}
                sx={{ ...sharedClasses.formAutocomplete, width: '100%' }}
                loading={fetchingSmsTemplates}
                loadingText="Loading SMS templates..."
                ListboxProps={{ style: emailStyle.addToEventListboxProps }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Choose SMS template"
                    InputLabelProps={{ shrink: true }}
                    sx={{
                      '& .MuiInputBase-root': { margin: 'unset', marginTop: 1 }
                    }}
                  />
                )}
                onChange={(event, value) => {
                  if (value) {
                    setSmsMessage(value.body);
                  } else {
                    setSmsMessage('');
                  }
                  setSmsTemplate(value);
                }}
              />
              <Stack>
                <MultilineFormTextField
                  label="Content"
                  value={smsMessage}
                  onChange={(e) => setSmsMessage(e.target.value)}
                  maxLength={160}
                  required
                />
                <Stack sx={emailStyle.addToEventCharacterCount}>{smsMessage.length} / 160</Stack>
                <Collapse in={!!smsMessage.trim() && smsMessage !== smsTemplate?.body}>
                  <Stack
                    sx={{
                      flexDirection: 'row',
                      alignItems: 'baseline',
                      columnGap: 5
                    }}
                  >
                    <FormTextField
                      id="new-sms-template-title"
                      label="Template title"
                      value={smsTemplateName}
                      styles={classes.actionModalTemplateTitle}
                      onChange={(e) => {
                        setSmsTemplateName(e.target.value);
                        if (smsTemplateError) setSmsTemplateError('');
                      }}
                    />
                    <Box sx={{ transform: 'translateY(-1.5px)' }}>
                      <Button
                        id="save-sms-template"
                        type="submit"
                        sx={emailStyle.saveSMSTemplateButton}
                        onClick={handleSaveTemplate}
                      >
                        {savingTemplate ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          'Save template'
                        )}
                      </Button>
                    </Box>
                    {smsTemplateError && (
                      <Box sx={emailStyle.saveSMSTemplateError}>{smsTemplateError}</Box>
                    )}
                  </Stack>
                </Collapse>
              </Stack>
            </>
          )}
          {action === 'Change status' && (
            <Autocomplete
              options={statuses || []}
              getOptionLabel={(option: IApplicationStatus) => option.name}
              onChange={(e, value) => setSelectedStatus(value)}
              loading={loadingStatuses}
              value={selectedStatus}
              sx={{
                ...sharedClasses.formAutocomplete,
                width: '44%',
                paddingTop: 3
              }}
              ListboxProps={{ style: emailStyle.addToEventListboxProps }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Change to status"
                  InputLabelProps={{ shrink: true }}
                />
              )}
            />
          )}
          <Stack sx={{ color: '#666666', fontWeight: 'bold' }}>Candidates</Stack>
          <OutlinedInput
            sx={emailStyle.candidateSearch}
            placeholder={'Search candidates'}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            startAdornment={
              <InputAdornment sx={{ padding: '0px 0px 0px 10px' }} position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <Box
            sx={{
              ...emailStyle.selectedApplicantsTableHeader,
              margin: 'unset'
            }}
          >
            {selectedCandidates.length} candidates selected
          </Box>
          <SelectedCandidatesTable
            candidates={candidates}
            selectedCandidates={selectedCandidates}
            setSelectedCandidates={setSelectedCandidates}
            searchTerm={searchTerm}
            action={action}
          />
        </Stack>
        <ModalFooterButtons
          primaryButtonText={action === 'Change status' ? 'Confirm' : 'Send'}
          primaryButtonCallback={buttonCallback()}
          primaryButtonMinWidth={action === 'Change status' ? '95px' : '75px'}
          primaryButtonDisabled={!validateInputs()}
          secondaryButtonText="Cancel"
          secondaryButtonCallback={handleClose}
          isLoading={sendingEmails || sendingSms || changingStatus}
          primaryButtonID={`${
            action === 'Change status'
              ? `confirm-${action.toLowerCase().replace(' ', '-')}`
              : `send-${action.toLowerCase()}`
          }-from-event-button`}
          secondaryButtonID={`cancel-${action.toLowerCase().replace(' ', '-')}-from-event-button`}
        />
      </Stack>
    </StyledModal>
  );
}
