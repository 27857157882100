var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
class Tableau {
  constructor(serverUrl) {
    this.serverUrl = serverUrl;
  }
  run() {
    return __async(this, null, function* () {
      const response = yield this._fetchAuthenticationUrl();
      return response.ticket;
    });
  }
  _fetchAuthenticationUrl() {
    return __async(this, null, function* () {
      const response = yield fetch(`/admin/tableau/authentication`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        credentials: "same-origin"
      });
      if (response.ok) return response.json();
      return response.json().then((error) => {
        error.code = response.status;
        throw error;
      });
    });
  }
}
export default new Tableau();
