import React, { useState, useRef } from 'react';
import { classes } from '../styles';
import { Box, Snackbar, Button, Tooltip, IconButton } from '@mui/material';
import { IJobAdViewjobsProps, IJobAdViewJobs } from '../types';
import moment from 'moment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import ActionButton from '../ActionButton';

export default function AdTable({ ads, jobAds, setAds, adsStatus, apiKey }: IJobAdViewjobsProps) {
  const [isSnackOpen, setIsSnackOpen] = useState(false);
  const [snackMessage, setSnackMessage] = useState('');
  const [tableHovered, setTableHovered] = useState(false);
  const [dialogUrl, setDialogUrl] = useState('');
  const [dialogAdID, setDialogAdID] = useState(0);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [dialogButtonText, setDialogButtonText] = useState('');
  const [dialogButtonCallback, setDialogButtonCallback] = useState(null);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [actionsAnchorEl, setActionsAnchorEl] = useState(null);
  const [selectedAd, setSelectedAd] = useState<IJobAdViewJobs | null>(null);

  const actionCellRef = useRef(null);
  const jobCellRef = useRef(null);

  function handleSnack(message: string) {
    setIsSnackOpen(true);
    setSnackMessage(message);
  }

  return (
    <Box>
      <Box sx={{ position: 'relative' }}>
        <Box sx={classes.tableContainer}>
          <Box sx={classes.table}>
            <Box sx={{ ...classes.row, ...classes.rowHeader }}>
              <Box
                component="span"
                ref={jobCellRef}
                sx={{
                  ...classes.title,
                  minWidth: '182px',
                  ...classes.sticky,
                  ...classes.scrollShadowLeft,
                  ...(tableHovered && classes.scrollShadowVisible),
                  left: 0
                }}
              >
                Job
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Creation Date
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Created By
              </Box>
              <Box component="span" sx={{ minWidth: '120px' }}>
                Expiry
              </Box>
              <Box component="span" sx={{ ...classes.center, minWidth: '130px' }}>
                Active on ViewJobs
              </Box>
              <Box
                component="span"
                sx={{
                  ...classes.stickyRight,
                  ...classes.scrollShadowRight,
                  ...(tableHovered && classes.scrollShadowVisible)
                }}
                ref={actionCellRef}
              ></Box>
            </Box>
            {ads && ads.length > 0 ? (
              ads.map((ad: IJobAdViewJobs) => (
                <Box key={ad.id} sx={classes.row}>
                  <Box
                    sx={{
                      ...classes.title,
                      minWidth: '182px',
                      ...classes.sticky,
                      ...classes.scrollShadowLeft,
                      ...(tableHovered && classes.scrollShadowVisible)
                    }}
                  >
                    {ad.title}
                  </Box>
                  <Box>{moment(ad.created_at).format('D MMM YYYY')}</Box>
                  <Box>{ad.created_by.name}</Box>
                  <Box>
                    {ad.expired_at ? moment(ad.expired_at).format('D MMM YYYY') : 'No expiry'}
                  </Box>
                  <Box sx={classes.center}>
                    {ad.status === 'open' ? (
                      <CheckCircleIcon sx={classes.iconGreen} />
                    ) : (
                      <CancelIcon sx={classes.iconRed} />
                    )}
                  </Box>
                  <ActionButton
                    ad={ad}
                    jobAds={jobAds}
                    setAds={setAds}
                    adsStatus={adsStatus}
                    tableHovered={tableHovered}
                    apiKey={apiKey}
                    classes={classes}
                    adType={'viewjobs'}
                    setDialogLoading={setDialogLoading}
                    setDialogUrl={setDialogUrl}
                    setDialogOpen={setDialogOpen}
                    setActionsAnchorEl={setActionsAnchorEl}
                    setDialogAdID={setDialogAdID}
                    setDialogTitle={setDialogTitle}
                    setDialogDescription={setDialogDescription}
                    setDialogButtonText={setDialogButtonText}
                    setDialogButtonCallback={setDialogButtonCallback}
                    setSelectedAd={setSelectedAd}
                    handleSnack={handleSnack}
                  />
                </Box>
              ))
            ) : (
              <Box sx={classes.row}>
                <Box sx={classes.title}>No ads to display</Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Snackbar open={isSnackOpen} onClose={() => setIsSnackOpen(false)} message={snackMessage} />
    </Box>
  );
}
