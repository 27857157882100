import React, { useState } from 'react';
import { Box, Button, Modal, Stack } from '@mui/material';
import { Close as CloseIcon, NavigateBefore as NavigateBeforeIcon } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { ControlledStaticDatePicker } from '../../../Components/CustomUIElements/SingleDatePicker';
import SelectedApplicants from '../SelectedApplicants';
import { IAddToEventModalProps } from '../../types';
import { classes } from '../styles';
import { useQuery, useMutation } from '@tanstack/react-query';
import { IEvent } from '../../types';
import API from '../../API';
import dayjs from 'dayjs';
import { IError } from '../../../SmartForms/types';
import { sharedClasses } from '../../../Components/CustomUIElements/sharedClasses';
import VirtualizeGrouped from '../../../Components/CustomUIElements/VirtualizedGroupedAutocomplete';
import SmsTemplateComponent from '../../../Components/CustomUIElements/SmsTemplateComponent';
import HtmlEditor from '../../../Components/Utilities/HtmlEditor';
import NotificationOptionSwitch from '../../../Application/Modals/Components/NotificationOptionsSwitch';
import { usePlaceholders } from '../../../hooks/usePlaceholders';
import { useEmailTemplatesOptions } from '../../../hooks/useEmailTemplatesOptions';
import { ISmsTemplate } from './SMSCandidates';

export default function AddToEvent({
  apiKey,
  jobId,
  setSnackbar,
  modalsOpen,
  setModalsOpen,
  globalSelected,
  handleDeselectCandidate,
  filters,
  smsTemplatePermissions
}: IAddToEventModalProps) {
  const [selectedDay, setSelectedDay] = useState<Date | null>(new Date());
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<number>(0);
  const [step, setStep] = useState<number>(1);
  const [notification, setNotification] = useState<string>('email');
  const [smsMessage, setSmsMessage] = useState<string>('');
  const [smsTemplate, setSmsTemplate] = useState<ISmsTemplate | null>(null);
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState<string | null>('');
  const [body, setBody] = useState<string>('');

  const { data: events, isLoading: loadingEvents } = useQuery({
    queryKey: ['events'],
    queryFn: async () => {
      const { events } = await API.getEvents(new Date(), { 'X-api-authenticate': apiKey });
      return events as IEvent[];
    },
    onError: (error: IError) => setSnackbar({ message: error.errors.join(', '), state: 'error' })
  });

  const { data: placeholders } = usePlaceholders({
    type: 'event',
    apiKey,
    onError: (message: string) => setSnackbar({ message, state: 'error' })
  });

  const { data: emailTemplates, isLoading: loadingEmailTemplates } = useEmailTemplatesOptions({
    apiKey,
    onError: (message: string) => setSnackbar({ message, state: 'error' })
  });

  useQuery({
    queryKey: [selectedEmailTemplate],
    queryFn: async () => {
      if (!selectedEmailTemplate) return;
      const res = await API.getEmailTemplate(
        { 'X-api-authenticate': apiKey },
        Number(selectedEmailTemplate.split(' -')[0])
      );
      return res;
    },
    onSuccess: (emailTemplate) => setBody(emailTemplate.body),
    onError: (error: IError) => setSnackbar({ message: error.errors.join(', '), state: 'error' }),
    enabled: !!selectedEmailTemplate
  });

  const eventCandidates = events
    ?.find((e) => e.id === selectedTimeSlot)
    ?.attendees.filter((a) => a.type === 'Candidate')
    .map((c) => c.application_id);

  const bookableCandidates = globalSelected
    .map((s) => s.id)
    .filter((c) => !eventCandidates?.includes(c));

  const { mutate: sendEmail, isLoading: sendingEmail } = useMutation({
    mutationFn: async () => {
      if (!selectedEmailTemplate) return;
      const res = await API.sendEmail(
        { 'X-api-authenticate': apiKey },
        jobId,
        selectedTimeSlot,
        Number(selectedEmailTemplate.split(' -')[0]),
        body,
        bookableCandidates
      );
      return res;
    },
    onSuccess: () => {
      setSnackbar({
        message: 'Add to event email notification has been sent successfully',
        state: 'success'
      });
      setModalsOpen((prev) => ({ ...prev, addToEvent: false }));
    },
    onError: (error: { success: boolean; message: string }) =>
      setSnackbar({ message: error.message, state: 'error' })
  });

  const { mutate: sendSms, isLoading: sendingSms } = useMutation({
    mutationFn: async () => {
      const res = await API.addToEventSms(
        jobId,
        selectedTimeSlot,
        bookableCandidates,
        smsMessage,
        smsTemplate?.id
      );
      if (res.success !== 0) {
        setSnackbar({
          message: 'Add to event SMS notification has been sent successfully',
          state: 'success'
        });
        setModalsOpen((prev) => ({ ...prev, addToEvent: false }));
      } else {
        setSnackbar({ message: 'There is an error in sending SMS notification', state: 'error' });
      }
      return res;
    }
  });

  const selectedDayEvents = events?.filter(
    (e) => e.date === dayjs(selectedDay).format('YYYY-MM-DD')
  );

  const shouldDisableDate = (date: Date) => {
    if (!events) return true;
    return !events
      .map((e) => dayjs(e.begins_at).format('YYYY-MM-DD'))
      .includes(dayjs(date).format('YYYY-MM-DD'));
  };

  const buttonName = step === 1 ? 'Next' : 'Send';

  const disableButton = () => {
    if (step === 1) {
      return !selectedTimeSlot || !bookableCandidates.length;
    } else {
      return notification === 'email' ? !body : !smsMessage.trim();
    }
  };

  const handleSubmit = () => {
    if (step === 1) {
      setStep(2);
    } else {
      notification === 'email' ? sendEmail() : sendSms();
    }
  };

  return (
    <Modal
      open={modalsOpen.addToEvent}
      onClose={() => setModalsOpen((prev) => ({ ...prev, addToEvent: false }))}
      aria-labelledby="add-to-event-modal"
    >
      <Box sx={classes.actionsModalReducedPadding}>
        <CloseIcon
          onClick={() => setModalsOpen((prev) => ({ ...prev, addToEvent: false }))}
          sx={classes.closeIcon}
        />
        <Box id="add-to-event-title" sx={{ ...classes.actionModalHeader, paddingBottom: 3 }}>
          Add to Event
        </Box>
        {step === 1 ? (
          <>
            <Stack sx={{ flexDirection: 'row', justifyContent: 'space-between' }}>
              <Box sx={classes.calendar}>
                <ControlledStaticDatePicker
                  displayStaticWrapperAs="desktop"
                  views={['day']}
                  value={selectedDay}
                  disablePast
                  onChange={(value) => setSelectedDay(value)}
                  shouldDisableDate={shouldDisableDate}
                />
              </Box>
              <Box sx={classes.dailyTimeslots}>
                <Box sx={{ ...classes.dailyTimeslotsHeader, marginTop: 2, marginBottom: '20px' }}>
                  Choose available timeslot
                </Box>
                {loadingEvents ? (
                  <Box sx={classes.noTimeslots}>
                    <CircularProgress size={32} />
                  </Box>
                ) : (
                  <Stack sx={classes.addToEventTimeslotsContainer}>
                    {selectedDayEvents?.map((e) => {
                      const candidates = e.attendees.filter((a) => a.type === 'Candidate');
                      const fullyBooked = candidates.length === e.max_candidates;
                      return (
                        <Stack
                          key={e.id}
                          sx={{
                            ...classes.addToEventTimeslot,
                            border: `1px solid ${
                              e.id === selectedTimeSlot ? '#5BC4C0' : '#DDDDDD'
                            }`,
                            color: `${fullyBooked ? '#939393' : '#666666'}`,
                            background: `${fullyBooked ? '#EAEEF0' : 'inherit'}`,
                            cursor: `${fullyBooked ? 'unset' : 'pointer'}`
                          }}
                          onClick={() => {
                            if (fullyBooked) return;
                            if (globalSelected.length > e.max_candidates - candidates.length) {
                              setSnackbar({
                                message:
                                  'Candidate count exceeds timeslot capacity. please align candidate numbers with available capacity',
                                state: 'warning'
                              });
                            } else {
                              setSelectedTimeSlot(e.id);
                            }
                          }}
                        >
                          <Stack sx={{ rowGap: '4px' }}>
                            <Stack sx={{ fontSize: '14px' }}>
                              {dayjs(e.begins_at).format('LT')}
                            </Stack>
                            <Stack sx={{ fontSize: '12px' }}>{e.subject}</Stack>
                          </Stack>
                          <Stack sx={{ color: `${fullyBooked ? '#939393' : '#084D6D'}` }}>
                            {candidates.length}/{e.max_candidates} BOOKED
                          </Stack>
                        </Stack>
                      );
                    })}
                  </Stack>
                )}
              </Box>
            </Stack>
            <Box
              sx={{
                ...classes.modalContent,
                margin: 'unset',
                '&::-webkit-scrollbar': {
                  display: 'none'
                }
              }}
              id="add-to-event-modal-content"
              aria-label="Add to Event Modal Content"
            >
              <Box sx={{ marginLeft: '15px' }}>
                <h3>Candidates</h3>
                <SelectedApplicants
                  globalSelected={globalSelected}
                  handleDeselectCandidate={handleDeselectCandidate}
                  closeModalCallback={() =>
                    setModalsOpen((prev) => ({ ...prev, addToEvent: false }))
                  }
                  filters={filters}
                  eventCandidates={eventCandidates}
                />
              </Box>
            </Box>
          </>
        ) : (
          <Stack sx={classes.addToEventSecondStepContainer}>
            <NotificationOptionSwitch
              setNotification={setNotification}
              notification={notification}
            />
            {notification === 'email' ? (
              <Stack sx={{ paddingTop: 4, rowGap: 3 }}>
                <VirtualizeGrouped
                  options={emailTemplates || []}
                  value={selectedEmailTemplate}
                  onChange={(value) => {
                    !value && setBody('');
                    setSelectedEmailTemplate(value);
                  }}
                  styles={{
                    ...sharedClasses.formAutocomplete,
                    marginRight: '1px',
                    '&.MuiAutocomplete-root': {
                      '& .MuiOutlinedInput-root': {
                        paddingRight: '16px !important'
                      }
                    }
                  }}
                  label="Choose email template"
                  loading={loadingEmailTemplates}
                  loadingText="Loading email templates..."
                />
                <Stack sx={{ marginLeft: '3px', rowGap: 0.5 }}>
                  <Stack sx={{ color: '#838383' }}>Body</Stack>
                  <HtmlEditor body={body} onBlur={setBody} maxHeight={400} />
                  <Stack sx={classes.addToEventEmailPlaceholder}>
                    Available placeholders are: {placeholders}
                  </Stack>
                </Stack>
              </Stack>
            ) : (
              <SmsTemplateComponent
                smsTemplatePermissions={smsTemplatePermissions}
                setSnackbar={setSnackbar}
                smsMessage={smsMessage}
                setSmsMessage={setSmsMessage}
                smsTemplate={smsTemplate}
                setSmsTemplate={setSmsTemplate}
              />
            )}
          </Stack>
        )}
        <Box sx={{ ...classes.modalFooter, columnGap: 1, paddingTop: 2 }}>
          <Button
            id="close-add-to-event-modal-button"
            sx={classes.modalCancelButton}
            onClick={() => setModalsOpen((prev) => ({ ...prev, addToEvent: false }))}
          >
            Cancel
          </Button>
          {step !== 1 && (
            <Button
              onClick={() => setStep(1)}
              sx={{ ...sharedClasses.genericButtonSecondary, minWidth: 'unset', padding: 1 }}
            >
              <NavigateBeforeIcon />
            </Button>
          )}
          <Button
            id="add-to-event-submit-button"
            type="submit"
            sx={{
              ...sharedClasses.genericButton,
              '&.Mui-disabled ': { background: '#DDDDDD', color: '#939393' }
            }}
            onClick={handleSubmit}
            disabled={disableButton()}
          >
            {sendingSms || sendingEmail ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              buttonName
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
