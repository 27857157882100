import React from 'react';
import { Button } from '@mui/material';
import SortIcon from '@mui/icons-material/Sort';

interface FilterButtonProps {
  selectedAdType: string;
  setFilterAnchorEl: (el: HTMLElement | null) => void;
  classes: any;
}

const FilterButton: React.FC<FilterButtonProps> = ({
  selectedAdType,
  setFilterAnchorEl,
  classes
}) => {
  return (
    <Button
      sx={classes.filterButton}
      startIcon={<SortIcon sx={{ marginRight: '8px' }} />}
      onClick={(e) => setFilterAnchorEl(e.currentTarget)}
    >
      {selectedAdType + ' Ads'}
    </Button>
  );
};

export default FilterButton;
